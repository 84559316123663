.modal {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  background: rgba($charcoal-dark, 0.9);
  align-items: center;

  &--higher {
    z-index: 15;
  }

  &--overlay {
    background: none;
    left: unset;
  }
  &.row--center {
    @media (orientation: landscape) and (max-width: 48rem) {
      justify-content: flex-start;
    }
  }

  &__tooltip {
    .modal__dialog {
      max-width: 80vw;

      @include breakpoint(tablet) {
        max-width: 45vw;
      }
    }
  }

  &__dialog {
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: rem-calc(32);
    background: $white;
    border-radius: 13px;

    &.widget {
      max-width: 100%;
      width: 90%;
    }

    .round-button:not(.button--sm-shadow) {
      width: 2rem;
      height: 2rem;

      img {
        height: auto;
      }

      &.button--modal__back img {
        padding: 0.2rem;
      }
    }

    &--overlay {
      padding: 0;
    }

    &--small {
      text-align: center;
    }

    &--form {
      max-width: rem-calc(400);

      .button {
        width: 100%;
      }
    }

    &--minimised {
      margin-top: 80vh;
      overflow: hidden;
      transition: all 0.75s ease-in-out;
      @include breakpoint(tablet) {
        margin-top: 85vh;
      }
      @include breakpoint(laptop) {
        margin-top: 90vh;
      }
    }
    &--maximised {
      margin-top: 0;
      overflow-y: auto;
      background-color: $charcoal-dark;
      transition: all 0.75s ease-in-out;
    }
    @include breakpoint(tablet) {
      max-width: rem-calc(968);

      &.widget {
        width: 35rem;

        &__patient-card {
          width: 45rem;
        }
      }

      .round-button:not(.button--sm-shadow) {
        width: 2rem;
        height: 2rem;
      }
    }

    @include breakpoint(laptop) {
      &.widget__patient-card {
        width: 60rem;
      }
    }
  }
  &--small {
    &.modal__dialog {
      max-width: 90vw;
      @include breakpoint(tablet) {
        max-width: 50ch;
      }
    }
  }
  &__title {
    padding: rem-calc(0 0 32);
    margin: 0;
    text-align: center;
    display: block;
    font-family: $global-font-family;
    font-size: rem-calc(24);
  }

  &--info {
    max-width: 90%;
    max-height: 75vh;

    @include breakpoint(tablet) {
      max-width: 70ch;
      max-height: 80vh;
    }

    @include breakpoint(laptop) {
      max-width: 80ch;
    }
  }

  &__body {
    height: calc(100% - 10ch);
    overflow-y: auto;

    @media (orientation: landscape) {
      height: calc(100% - 3ch);
    }

    @include breakpoint(tablet) {
      height: calc(100% - 5ch);
    }
  }

  &--active {
    display: flex;
  }

  &--open {
    display: flex;
  }

  &--treatment {
    flex-direction: column;
  }

  &__blog--image {
    max-height: 100vh;
    overflow: hidden;
    padding: 3rem;

    img {
      max-height: calc(100vh - 6rem);
    }
  }

  .photos {
    .slick-list {
      height: 0;
      padding-bottom: 62.5%;

      .slick-track .slick-slide img {
        width: 100%;
      }

      .slick-dots {
        bottom: rem-calc(-46);
      }
    }
  }

  @include breakpoint(tablet) {
    .photos {
      padding: 3rem 0;

      .slick-dots {
        bottom: rem-calc(-46);
      }
    }
  }

  &--handover {
    z-index: 15;
  }

  &__body--handover {
    max-width: min(90vw, rem-calc(500));
  }

  &--gray {
    background-color: $brand-white;
  }

  &--box {
    padding: 1rem;
  }
}
