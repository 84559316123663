.clinic-signup-widget {
  background: $charcoal-dark;
  padding: rem-calc(20 16);
  align-items: center;
  color: $white;
  width: 100%;
  margin: 0 auto;
  span {
    display: block;
    font-size: 0.75rem;
    font-weight: 600;
  }
  button {
    font-size: 0.6rem;
    @include breakpoint(tablet) {
      font-size: inherit;
    }
  }

  .button-group .button:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(tablet) {
    border-radius: 0;
    span {
      font-size: 1.25rem;
    }
  }
}
.sticky-top {
  top: 0;
  z-index: 2;
  position: sticky;
  @include breakpoint(tablet) {
    margin-top: 0;
  }
  .column {
    align-self: center;
  }
}

.horizontal-signup {
  display: inline-block;
  align-self: center;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .field {
      width: 100%;
      margin-right: 1%;
      margin-bottom: 0;
      flex: 1 auto;

      input {
        background-color: $charcoal-dark;
        color: $white;
      }

      button {
        padding: 0.9rem 1.25rem;
        font-size: rem-calc(12);
      }
    }

    @include breakpoint(laptop) {
      flex-direction: row;

      .field:last-child {
        width: 60%;
        margin-right: 0;
      }
    }
  }
}
