.widget {
  footer {
    width: 100%;
    display: inline-grid;
    grid-template-rows: [row] auto [row];
    grid-gap: 0.5em;
    margin-top: 1em;

    @include breakpoint(tablet) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
      padding: 2rem;
      &.consultation-only {
        grid-template-columns: 6fr 3fr;
      }
    }
    .last-item {
      grid-column: 1 / span 2;
      margin-top: 0.5rem;
    }
  }
}
