.retry-payment-grid {
  @include breakpoint(tablet) {
    & > :first-of-type {
      padding-right: 0.5rem;
    }
    & > :last-of-type {
      padding-left: 0.5rem;
    }
  }
}

.take-or-waive-payment {
  @include breakpoint(tablet) {
    &--gap {
      &:nth-of-type(3),
      &:nth-of-type(2) {
        padding-right: 0.5rem;
      }

      &:last-of-type,
      &:nth-of-type(4) {
        padding-left: 0.5rem;
      }
    }
  }
}
