.link {
  display: inline-block;
  font-size: rem-calc(14);
  text-decoration: none;
  color: $charcoal-dark;
  line-height: 1;

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: rem-calc(8);
    width: rem-calc(16);
    height: rem-calc(16);
    background: url('/static/img/icons/arrow-right.svg') center / cover
      no-repeat;
  }

  &--white {
    color: $white;

    &::after {
      background: url('/static/img/icons/white/arrow-right.svg') center / cover
        no-repeat;
    }
  }

  &--unstyled {
    &::before,
    &::after {
      content: none;
    }
  }
}
