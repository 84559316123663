#main_content {
  .slick-slide {
    &:focus,
    &:active {
      *,
      & > * {
        outline: none;
      }
    }

    img {
      margin: 0 auto;
    }
  }

  .testimonial {
    margin-bottom: 1rem;
  }

  .dark-slider-dots {
    .slick-dots {
      button::before {
        background: $charcoal-highlight;
        opacity: 1;
        border-radius: 100%;
        width: 0.5rem;
        height: 0.5rem;
        font-size: 0;
      }

      .slick-active {
        button::before {
          background: $charcoal-dark;
          border-color: $charcoal-dark;
          opacity: 1;
          border-radius: 100%;
          width: 0.5rem;
          height: 0.5rem;
          font-size: 0;
          display: block;
        }
      }
    }
  }

  .small-top-dots {
    .slick-dots {
      width: 50%;
      top: rem-calc(-46px);
      height: rem-calc(20px);
      text-align: right;
      right: rem-calc(10px);

      li {
        width: rem-calc(10px);
        height: rem-calc(10px);
        padding: 0;

        button {
          width: rem-calc(10px);
          height: rem-calc(10px);
          bottom: rem-calc(-3px);
        }

        &.slick-active button:before {
          background-color: $golden-medium;
          border-color: $golden-medium;
        }
      }

      @include breakpoint(desktop) {
        width: 100%;
        top: inherit;
        text-align: center;
        right: 0;
        bottom: rem-calc(-40px);

        li {
          width: rem-calc(20px);
          height: rem-calc(20px);

          button {
            width: rem-calc(20px);
            height: rem-calc(20px);
            bottom: rem-calc(-9px);
          }
        }
      }
    }
  }

  .slick-dots {
    li {
      margin: 0 3px;
    }

    li button::before {
      background: $light-gray;
      opacity: 1;
      border-radius: 100%;
      width: 0.5rem;
      height: 0.5rem;
      font-size: 0;
      transition: all 0.2s ease;
    }

    .slick-active {
      button {
        position: absolute;
        bottom: -9px;

        &::before {
          background: $light-gray;
          opacity: 1;
          border-radius: 100%;
          width: 0.5rem;
          height: 0.5rem;
          font-size: 0;
          display: block;
          border: 5px solid $light-gray;
          transform: scale(1.25);
          transition: all 0.2s ease;
        }
      }
    }
  }
}
