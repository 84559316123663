.overlay-background {
  position: fixed;
  z-index: 10;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  background: rgba($charcoal-dark, 0.9);
  align-items: center;
  display: flex;
}

.spinner-wrapper {
  background-color: $white;
  border-radius: 13px;
  margin: 0 auto;
  width: 8rem;
  height: 8rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
