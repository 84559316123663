.signature-pad {
  background: $light-gray;
  border-radius: 13px;
  padding: rem-calc(32 32 0 32);

  &__pad {
    position: relative;
    height: rem-calc(240);

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    padding: rem-calc(16);
    display: block;
    border-top: 1px solid $charcoal-medium;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    text-align: center;
  }
}
