.accordion--forms {
  @include breakpoint(tablet) {
    .accordion__header {
      padding-left: 0;

      span {
        padding-left: 1rem;
      }
    }
  }

  &.accordion--signed {
    .accordion__header {
      background-color: $green-highlight;
    }

    .accordion__body--default {
      border-color: $green-highlight;
    }
  }

  &.accordion--notsubmitted {
    .accordion__body--default {
      border-color: $charcoal-highlight;
    }
  }
}
