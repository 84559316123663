.before-and-after {
  @media (min-width: $grid-break-point-tablet) {
    margin-top: rem-calc(100);
  }
}

.hero-photo {
  margin-bottom: 2rem;
  .image-uploader {
    width: 100%;
    padding-top: 62.5%;
    height: 0px;
    position: relative;
  }
}

.builder-photos {
  padding-bottom: rem-calc(32);
  margin-bottom: 2rem;

  @include breakpoint(tablet) {
    margin-bottom: 0;
    padding-bottom: rem-calc(16);
    ~ div {
      margin-bottom: rem-calc(16);
      height: 10rem;
    }
  }
  .image-uploader {
    width: 100%;
    padding-top: 62.5%;
    height: 0px;
    position: relative;
  }
}

.builder-section {
  .medium {
    font-family: $global-font-family;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  h3 {
    font-size: 100%;
  }
}
