.social-link {
  &__modal {
    max-width: 18rem;
    text-transform: initial;
    letter-spacing: normal;
    text-align: center;
    color: $charcoal-dark;

    .round-button img {
      height: 1rem;
    }
    @include breakpoint(tablet) {
      max-width: 23rem;
    }
    @include breakpoint(laptop) {
      max-width: 25rem;
    }
  }

  &__input {
    input {
      cursor: pointer;
      background: url('/static/img/icons/charcoal-dark/clipboard.svg')
        calc(100% - 0.5rem) 50% / auto rem-calc(18px) no-repeat;
      padding-right: 1.75rem;
      &:hover,
      &:focus {
        border: 1px solid $green;
        background: url('/static/img/icons/green/clipboard.svg')
          calc(100% - 0.5rem) 50% / auto rem-calc(18px) no-repeat;
      }
      &:focus {
        @extend .shadow;
      }
    }
  }
}
