.datepicker {
  position: relative;
  bottom: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  max-width: 42rem;
  background: $white;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);
  visibility: hidden;
  opacity: 0;
  border: 1px solid $charcoal-medium;
  border-radius: 13px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem-calc(16 8);
  }

  &__pickeddate {
    font-size: rem-calc(12);
    span {
      color: $golden-highlight;
      font-weight: 500;
      font-size: rem-calc(12);
    }
  }

  &__controls {
    button {
      display: inline-block;
      background: none;
      border: none;
      outline: none;
      -webkit-appearance: none;
      vertical-align: middle;
      padding: 0.2rem;
      img {
        width: rem-calc(16);
        vertical-align: middle;

        &.reset {
          width: rem-calc(8);
        }
      }
    }
  }

  &__dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    background: $brand-white;
    padding: rem-calc(8);
  }

  &__dayletter {
    display: block;
    color: $brand-charcoal-light;
    font-weight: 500;
  }

  &__day {
    display: inline-block;

    span {
      display: block;
      color: $brand-charcoal-light;
      font-weight: 500;
      font-size: rem-calc(12);
    }
  }

  &__date {
    font-weight: 800;
    display: inline-block;
    padding: rem-calc(8);
    border-radius: 100%;
    width: 1.75rem;
    font-size: rem-calc(10);
    -webkit-appearance: none;
    width: 1.75rem;
    height: 1.75rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.4s all;
    background: none;
    color: $charcoal-dark;
    text-decoration: underline;
    text-underline-offset: 0.1rem;

    &--active {
      color: $white;
      background: $brand-gold;
      text-decoration: none;
    }

    &__unavailable {
      font-weight: 400;
      color: $charcoal-medium;
      text-decoration: none;
    }

    @include breakpoint(tablet) {
      &__date {
        font-size: rem-calc(14);
      }
    }
  }

  &__slots {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: rem-calc(4);
    padding: 0.5rem 0.25rem;
    font-size: rem-calc(12);
    @include breakpoint(tablet) {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: rem-calc(6);
      padding: rem-calc(16 8);
      font-size: 0.75rem;
    }
    @include breakpoint(laptop) {
      grid-template-columns: 1fr 1fr 1fr;
      max-height: 9rem;
      overflow-y: auto;
    }
  }

  &__noslots {
    padding: rem-calc(16 8);
    text-align: center;
  }

  &__slot {
    background: $white;
    color: $brand-gold;
    font-weight: 500;
    text-align: center;
    padding: rem-calc(8);
    display: inline-block;
    border-radius: 999px;
    margin-bottom: rem-calc(8);
    -webkit-appearance: none;
    border: 1px solid $brand-gold;
    outline: none;
    cursor: pointer;
    transition: 0.4s all;

    &:hover {
      background: $brand-gold-dark;
      color: $white;
    }

    &--active {
      background: $brand-gold;
      color: $white;
    }
  }

  &--open {
    opacity: 1;
    visibility: visible;
  }

  &--date {
    .datepicker__dates {
      background: $white;
    }
  }
}
