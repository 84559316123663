.widget__treatment {
  padding: 1rem 0;
  width: 100%;
  &--section {
    display: flex;
    border-radius: 13px;
    margin-bottom: 0.5rem;
    flex-direction: column;

    @include breakpoint(tablet) {
      background-color: $golden-pattern;
      padding: 0.5rem;
    }
    @include breakpoint(laptop) {
      align-items: center;
      flex-direction: row;
      padding: 0.5rem;
    }

    .round-button {
      margin-left: auto;
      width: 2rem;
      height: 2rem;

      img {
        width: 1rem;
        height: 1rem;
      }
    }

    .accordion__body.open {
      background-color: $golden-pattern;
    }

    .field__select select {
      background: transparent;
      border-color: $golden-dark;
      @include breakpoint(laptop) {
        border-color: $light-gray;
      }
    }
  }

  &--note {
    padding: 0 1rem;
    .accordion__header {
      border-color: $light-gray;
      background-color: $white;
      span {
        color: $charcoal-dark;
        font-size: rem-calc(14);
        text-transform: capitalize;
        @include breakpoint(tablet) {
          padding-left: 0;
        }
      }
      &::after {
        background-image: url('/static/img/icons/charcoal-highlight/arrow-down.svg');
        top: 1.25rem;
      }
    }
    .accordion__body--content {
      padding: 2rem 1rem;
      border-top: 1px solid $light-gray;
      label {
        @extend .sr-only;
      }
    }
  }

  &--list {
    li {
      border-bottom: 1px solid $medium-gray;
      padding: 0.5rem;
      display: flex;

      strong {
        flex: 1;
      }
    }
  }

  &--title {
    font-weight: 700;
    font-size: 1rem;
    color: $charcoal-highlight;
  }

  &--suggestions {
    padding-right: 1rem;
    max-height: rem-calc(250);
  }

  &--category {
    display: flex;
    flex-wrap: wrap;
  }

  .button--utility {
    box-shadow: none;
  }
}
