.history {
  padding: 1.25rem;
  border-radius: 13px;

  &--default {
    border: 1px solid $brand-white-dark;
  }

  &--pending {
    border: 1px solid $rose-medium;
  }

  &--completed {
    border: 1px solid $charcoal-dark;
  }

  &--confirmed {
    border: 1px solid $green-middle;
  }

  &--cancelled {
    border: 1px solid $brand-white-dark;
  }

  &__grid {
    display: grid;
    gap: 0.75rem;
    grid-template:
      [row1-start] 'booking' auto [row1-end]
      [row2-start] 'clinic' auto [row2-end]
      [row3-start] 'treatments' 10rem [row3-end]
      [row4-start] 'price' 5rem [row4-end]
      [row5-start] 'action' auto [row5-end];
  }
  &__item {
    ul {
      color: $charcoal-dark;
    }
    &--treatment {
      overflow-y: auto;
    }
    &--accent {
      border: 1px solid var(--brand-white);
      padding: 0.75rem;
      border-radius: 13px;
      background: var(--brand-white);
    }
    &--price {
      border: 1px solid $brand-white-dark;
      padding: 0.75rem;
      border-radius: 13px;
      h5 {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid $brand-white-dark;
      }
    }
  }
}
