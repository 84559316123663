@mixin glowdayButton {
  position: relative;
  z-index: 0;
  display: inline-block;
  font-size: rem-calc(16);
  padding: rem-calc(16 32);
  line-height: rem-calc(18);
  -webkit-appearance: none;
  text-transform: uppercase;
  background-color: $brand-gold;
  border: none;
  color: $white;
  border-radius: 10px;
  font-weight: 600;
  font-family: $global-font-family;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;

  &:active {
    &::before {
      background: $golden-dark;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: $golden-medium;
    background: $golden-light;

    &::before {
      background: $golden-light;
    }
  }

  &:focus {
    outline: none;
  }

  // Sizing

  &--extra-small {
    font-size: rem-calc(8);
    padding: rem-calc(8);
  }

  &--small {
    font-size: rem-calc(12);
    padding: rem-calc(12);
  }

  &--fullwidth {
    width: 100%;
  }

  &--no-padding {
    padding: 0;
    border-radius: 0;
  }

  &--fullgrid {
    grid-column: span 2;
  }

  &--step {
    line-height: inherit;
  }
  &--payment {
    background-color: $green-middle;
    color: $green-highlight;

    &::before {
      background: $green;
    }

    &:hover,
    &:disabled,
    &:focus {
      &::before {
        transform: skew(-26deg) translateX(-10%);
      }
    }

    &:active {
      box-shadow: 1px 1px 5px 2px rgba($green-highlight, 0.5);

      &::before {
        background: $green-middle;
      }
    }
  }

  // Options

  &--flat {
    max-height: 6ch;
    &::before {
      content: none;
    }
  }

  &--rose {
    background-color: $rose-medium;
    color: $white;
    border: 1px solid $rose-medium;

    &:hover,
    &:focus {
      background-color: $rose-dark;
      border-color: $rose-dark;
    }

    &:disabled {
      color: $rose-medium;
      background-color: darken($rose-pattern, 10%);
      border-color: darken($rose-pattern, 10%);
    }
  }

  &--ivory {
    background-color: $ivory-medium;
    border: 1px solid $ivory-medium;
    color: $white;

    &:hover,
    &:focus {
      background-color: $ivory-dark;
      border-color: $ivory-dark;
    }

    &:disabled {
      color: lighten($ivory-dark, 40%);
      background-color: lighten($ivory-medium, 25%);
      border-color: lighten($ivory-medium, 25%);
    }
  }

  &--gold {
    background-color: $brand-gold;
    border: 1px solid $brand-gold;
    color: $white;

    &:hover,
    &:focus {
      background-color: $brand-gold-dark;
      border-color: $brand-gold-dark;
    }

    &:disabled {
      color: lighten($brand-gold-dark, 20%);
      background-color: lighten($brand-gold-light, 25%);
      border-color: lighten($brand-gold-light, 25%);
    }
  }

  &--outline {
    background: none;
    border: 1px solid $brand-charcoal-light;
    color: $charcoal-dark;
    &.button--green {
      background: none;
      border-color: $green-highlight;
    }

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      background: $light-gray;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: $golden-light;
      background-color: $light-gray;
    }

    &--no--padding {
      padding: 0;
      border: none;
      color: $white;
      background: $white;
      border-radius: 0;

      &:hover,
      &::before,
      &::after,
      &:focus {
        background: $white;
        color: $white;
      }
    }

    &--no--border {
      border: none;
      color: $white;
      background: $white;

      &:hover,
      &::before,
      &::after,
      &:focus {
        background: $white;
        color: $white;
      }
    }

    &--white {
      border: 1px solid $white;
      color: $white;
      background-color: transparent;

      &::before {
        content: none;
      }

      &:hover,
      &:focus {
        background: darken($charcoal-medium, 50%);
      }
    }
  }

  &--green {
    background-color: $green;
    color: $green-highlight;
    border: 1px solid $green;

    &.round-button {
      border: 3px solid $white;

      &:focus {
        outline: none;
      }
    }

    .round-image {
      width: 2rem;
      height: 2rem;
    }

    &:hover,
    &:focus {
      background: $green-middle;
      border-color: $green-middle;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      color: lighten($green-highlight, 30%);
      background-color: lighten($green, 30%);
      border-color: lighten($green, 30%);
    }
  }

  &--pill {
    background: none;
    border: 1px solid $brand-gold-dark;
    color: $brand-gold-dark;
    padding: rem-calc(5 10);
    font-size: rem-calc(10);
    border-radius: rem-calc(18.5);
    min-width: 4rem;

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      background: $light-gray;
    }

    &:disabled,
    &.button--disabled {
      border-color: $golden-light;
      cursor: not-allowed;
      background-color: $light-gray;
    }
  }

  &--utility {
    background: $white;
    box-shadow: 0 5px 15px 0 rgba(43, 64, 78, 0.1),
      0 1px 10px 0 rgba(0, 0, 0, 0.05);

    color: $charcoal-dark;

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      background: $light-gray;
    }

    &.button--small {
      font-size: rem-calc(10);
      padding: rem-calc(8 12);
    }
  }

  &--sm-shadow {
    box-shadow: 0 3px 5px 0 rgba(43, 64, 78, 0.1),
      0 0px 15px 0 rgba(0, 0, 0, 0.05);
  }

  &--small-icon {
    display: block;
    position: absolute;
    top: rem-calc(0);
    right: rem-calc(0);
    z-index: 12;
    -webkit-appearance: none;

    &.round-button {
      width: rem-calc(32);
      height: rem-calc(32);
    }

    .round-image {
      width: 1rem;
    }

    &:focus {
      outline: 1px dotted $white;
      outline-offset: 4px;
    }
  }

  &--arrow {
    line-height: 1.4;

    &::after {
      content: '';
      display: inline-block;
      width: rem-calc(12);
      height: rem-calc(12);
      margin-left: rem-calc(32);
      background: url('/static/img/icons/arrow-right.svg') center / contain;
      vertical-align: middle;
    }
  }

  &--modal__close {
    display: block;
    position: absolute;
    top: rem-calc(8);
    right: rem-calc(8);

    &.round-button {
      width: rem-calc(32);
      height: rem-calc(32);
    }

    z-index: 15;
    -webkit-appearance: none;
    background: $charcoal-dark;

    @include breakpoint(tablet) {
      top: rem-calc(16);
      right: rem-calc(16);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:focus {
      outline: 1px dotted $golden-light;
      outline-offset: rem-calc(4);
      transition: none;
    }
  }

  &--modal__back {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: rem-calc(32);
    height: rem-calc(32);
    z-index: 9999;
    -webkit-appearance: none;
    background: $charcoal-dark;

    @include breakpoint(tablet) {
      top: rem-calc(16);
      left: rem-calc(16);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:focus {
      outline: 1px dotted $golden-light;
      outline-offset: rem-calc(4);
      transition: none;
    }
  }
  &--modal__minimise {
    display: block;
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    width: rem-calc(32);
    height: rem-calc(32);
    z-index: 9999;
    -webkit-appearance: none;
    background: $charcoal-dark;

    @include breakpoint(tablet) {
      top: rem-calc(16);
      top: 1rem;
      right: 4rem;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:focus {
      outline: 1px dotted $golden-light;
      outline-offset: rem-calc(4);
      transition: none;
    }
  }

  &--link {
    background-color: transparent;
    color: $brand-gold;
    border: none;
    transition: none;
    padding: inherit;
    &:after,
    &:before {
      content: none;
    }
    &:disabled {
      background-color: inherit;
      color: inherit;
    }
    &:hover {
      background-color: transparent;
    }
  }

  &--text {
    text-decoration: underline;
    padding-inline: 0;
    font-size: 0.75rem;
  }
  &--medium {
    font-size: 1rem;
  }
}
.button {
  @include glowdayButton;
}

.round-button {
  width: rem-calc(48);
  height: rem-calc(48);
  border-radius: 100%;
  padding: rem-calc(4);
  display: block;
  border: none;
  cursor: pointer;

  img {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
  }

  &.button--small {
    width: rem-calc(32);
    height: rem-calc(32);
    padding: 0.25rem 0.5rem;

    img {
      height: auto;
    }
  }

  &:focus {
    outline: 1px dotted $golden-light;
    outline-offset: rem-calc(4);
  }
}

.button-group {
  .button {
    margin-right: rem-calc(16);
    margin-bottom: rem-calc(16);
    width: 100%;

    @include breakpoint(tablet) {
      margin-bottom: 0;
      width: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a.button {
    vertical-align: middle;
  }

  &--right {
    @include breakpoint(tablet) {
      text-align: right;
    }
  }

  &--center {
    @include breakpoint(tablet) {
      text-align: center;
    }
  }
}

.store-icon {
  width: 9rem;
  object-fit: cover;
  height: auto;
  aspect-ratio: 3/1;
}

.breakout-button,
.breakout-button::before {
  cursor: pointer;
}

.breakout-button::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breakout-button {
  position: static;
}

.breakout-button:hover {
  background: $ivory-medium;
}
