.patient-navigation {
  .record-navigation__item {
    a {
      color: $green-highlight;
    }
  }

  &__image {
    margin-bottom: 0.25rem;
    height: 1rem;
    width: 1rem;

    @include breakpoint(tablet) {
      height: 2rem;
      width: 2rem;
    }
  }
}

.record-navigation {
  grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
}
@include breakpoint(tablet) {
  .record-navigation {
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
  }
}

@include breakpoint(laptop) {
  .record-navigation {
    grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));
  }
}
