details.client {
  &__form {
    summary {
      min-height: 4rem;

      &::after {
        top: initial;
      }
    }

    &--info {
      background-color: $charcoal-medium;

      & > summary::after {
        top: 1rem;
      }

      .testimonial__form {
        background-color: $white;
        max-height: 20rem;
        overflow-y: auto;
      }
    }
  }
}
