.images-grid {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 4rem;
    img {
      max-height: 100%;
    }
  }
}
