.steps {
  list-style: none;
  padding: 0;
  display: table;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
  counter-reset: steps;
  font-weight: 400;
  border-spacing: 0.75rem;

  a {
    color: $green;
    font-weight: 400;
  }
  &--mobile {
    padding: 0.75rem 0;
    border-top: 1px solid $charcoal-pattern;
    border-bottom: 1px solid $charcoal-pattern;
  }

  @include breakpoint(tablet) {
    &--container {
      background: $light-gray;
      height: 7rem;
      margin: 2rem auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .steps {
      margin: 0;
      li:after {
        content: none;
      }
    }
  }

  li {
    display: table-cell;
    list-style-type: none;
    margin: rem-calc(8 8 8 0);
    position: relative;

    span {
      display: none;
    }

    @include breakpoint(tablet) {
      display: inline-block;
      width: auto;
      height: auto;
      font-size: rem-calc(11);
      margin-right: rem-calc(10);
      span {
        display: inline-block;
      }
    }
    @include breakpoint(desktop) {
      font-size: rem-calc(18);
      margin-right: rem-calc(20);
    }

    &::after {
      content: '';
      height: rem-calc(1);
      position: absolute;
      display: block;
      background: $charcoal-medium;
      width: 100%;
      top: 50%;
      left: 50%;
      z-index: -1;
      @include breakpoint(tablet) {
        content: none;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    &.icon {
      padding: rem-calc(6);
      border-radius: 13px;
      border: solid 1px $charcoal-highlight;

      @include breakpoint(tablet) {
        border: none;
      }

      span {
        margin-right: rem-calc(4);
      }

      &:after {
        content: none;
      }

      a:before {
        content: '';
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;

        @include breakpoint(tablet) {
          margin-right: rem-calc(6);
        }
      }

      &--complete {
        background-color: $green;
        border: solid 1px $green-highlight;

        @include breakpoint(tablet) {
          border: none;
        }

        a:before {
          background-color: $green-highlight;
        }
      }

      &--incomplete {
        background-color: $charcoal-pattern;

        @include breakpoint(tablet) {
          background-color: $charcoal-medium;
        }

        a:before {
          background-color: $charcoal-dark;
        }
      }

      &--forms {
        a:before {
          mask-image: url(/static/img/icons/forms.svg);
        }
      }

      &--preferences {
        a:before {
          mask-image: url(/static/img/icons/settings.svg);
        }
      }

      &--past {
        a:before {
          mask-image: url(/static/img/icons/list.svg);
        }
      }

      &--upcoming {
        a:before {
          mask-image: url(/static/img/icons/treatment.svg);
        }
      }
    }

    &.complete {
      a:before {
        content: '';
        background: $green url('/static/img/icons/approve.svg') center / contain
          no-repeat;
        color: $green-highlight;
        position: relative;
        z-index: 1;
      }
    }

    &.active {
      a:before {
        background-color: $white;
        color: $green;
        border: 1px solid $green;
        position: relative;
        z-index: 1;
      }
    }

    a {
      display: inline-block;
      color: $charcoal-dark;
      text-decoration: none;
      vertical-align: middle;

      &::before {
        counter-increment: steps;
        content: counter(steps);
        display: inline-block;
        border-radius: 50%;
        color: $charcoal-dark;
        background: $charcoal-medium;
        width: rem-calc(32);
        height: rem-calc(32);
        line-height: rem-calc(32);
        font-size: rem-calc(14);
        vertical-align: middle;
        @include breakpoint(laptop) {
          margin-right: rem-calc(16);
        }
        @include breakpoint(tablet) {
          margin-right: rem-calc(8);
          width: rem-calc(25);
          height: rem-calc(25);
          line-height: rem-calc(25);
        }
      }

      &:hover,
      &:focus {
        color: $charcoal-dark;
        text-decoration-color: $charcoal-dark;
        text-decoration: underline;
      }
    }
  }
}
