.price-item {
  display: flex;
  flex-direction: column;
  span {
    color: $charcoal-highlight;
    margin-bottom: 0.25rem;
  }
  strong {
    font-size: rem-calc(24);
  }
}
