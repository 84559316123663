.hint {
  display: block;
  position: relative;
  cursor: pointer;

  &__text {
    text-decoration: underline dotted;
    font-weight: 600;

    &--gold {
      color: $golden-dark;
    }

    &--black {
      color: $charcoal-dark;
    }

    &--bronze {
      color: $bronze-dark;
    }
  }

  &__content {
    position: absolute;
    left: 50%;
    min-width: 200px;
    transform: translate(-50%, 5px);
    white-space: pre-wrap;
    padding: 0.25rem 0.75rem;
    border-radius: 13px;
    background: $charcoal-dark;
    color: $charcoal-light;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $charcoal-dark transparent;
    }

    &[data-hidden='true'] {
      display: none;
    }

    &[data-hidden='false'] {
      display: block;
    }

    ul {
      color: $charcoal-light;
      padding: 0 0.5rem 0 1rem;
      margin: 0.5rem 0;
      font-size: rem-calc(14);
    }
  }
}
