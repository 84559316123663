.avatar {
  width: 4.75rem;
  height: 4.75rem;
  border: 4px solid $brand-white;
  border-radius: 100%;
  transition: ease-in 200ms;
  @supports (aspect-ratio: 1/1) {
    aspect-ratio: 1/1;
  }
}

a:hover {
  .avatar {
    box-shadow: 1px 4px 10px 0 rgba($charcoal-dark, 0.3);
    border: 6px solid $brand-gold;
  }
}
