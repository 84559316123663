.record-appointment {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0.75rem;
  gap: rem-calc(8);
  align-items: center;
  font-size: rem-calc(10);
  background-color: $light-gray;
  border-radius: 13px;
  padding: rem-calc(4 8);

  @include breakpoint(tablet) {
    padding: rem-calc(8 12);
    margin-bottom: 1rem;
  }

  &--within-accordion {
    width: 100%;
    margin: 0 1rem 1rem 1rem;

    &:last-of-type {
      margin: 0 1rem;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &--extra-padding {
    padding: 0.75rem;
  }

  &--no-gap {
    gap: 0;
  }

  &--flex-wrap {
    flex-wrap: wrap;
  }
  &--progress {
    background-color: $border-gray;
  }

  &--completed {
    background-color: $green;
  }

  .uppercase {
    font-size: rem-calc(10);
  }

  &__cell {
    &--margin-bottom {
      margin-bottom: 0.5rem;
    }
  }

  &__icon {
    margin-right: 0.5rem;
  }

  &__link {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    background: $white;
    padding: 0.25rem;
    display: flex;
    margin-left: auto;
    border: none;

    &--active {
      background: $green;
    }

    &--signed {
      background-color: $charcoal-dark;
    }

    &.round-button {
      width: 1.5rem;
      height: 1.5rem;
    }

    &--extra-padding {
      padding: 0.5rem;
    }

    img {
      margin: auto;
      height: auto;
    }
  }
}
