.testimonials {
  background-color: $charcoal-dark;

  p {
    color: $white;
    font-size: 1.5rem;
  }

  .testimonial {
    &__author {
      font-size: rem-calc(14);
    }
  }
}

.for-clinic-interest {
  margin-left: auto;
  margin-right: auto;

  .register-interest {
    background-color: $light-gray;
    border-radius: 13px;
    padding: 2rem 0;
    .horizontal-signup input {
      background-color: $white;
      color: $charcoal-dark;
    }
  }
}
