.treatment__action {
  display: flex;
  flex-wrap: wrap;

  // We get the two last elements that have siblings and add margins on them
  & > * + * {
    margin: 1rem 0 0 0;

    @include breakpoint(tablet) {
      margin: 0 0 0 0.5rem;
    }
  }

  @include breakpoint(tablet) {
    flex-wrap: nowrap;
  }
}
