.content-divider {
  margin: rem-calc(96 0);
  display: block;
  height: rem-calc(1);
  background: $charcoal-medium;
  border: none;

  &__small {
    margin: rem-calc(48 0);
  }

  &__extra-small {
    margin: rem-calc(24 0);
    background-color: $light-gray;
  }
  &__super-small {
    margin: rem-calc(14px 0);
    background-color: $light-gray;
  }

  &.margin-top-0 {
    margin-top: 0;
  }
  &.margin-bottom-0 {
    margin-bottom: 0;
  }

  &.margin-top-2 {
    margin-top: 2rem;
  }
  &.margin-bottom-2 {
    margin-bottom: 2rem;
  }

  &__light-gray {
    background-color: $light-gray;
    margin-left: -1rem;
    margin-right: -1rem;
    @include breakpoint(tablet) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
