.treatment-grid {
  position: relative;
  z-index: 0;
  @include breakpoint(tablet) {
    position: static;
    z-index: inherit;
  }
  &__title {
    display: none;
  }
  &__section {
    display: none;
    &--active {
      display: block;
    }
    .treatment-grid__title {
      display: block;
    }
  }
}
