.alert {
  background-color: $rose-highlight;
  padding: 1rem;

  &.sticky-top {
    z-index: 3;
  }

  p {
    color: $white;
    font-weight: 600;
    font-size: rem-calc(18);

    a {
      color: $white;
    }
  }

  &__processing {
    background-color: $golden-dark;
  }

  &__upgrade {
    @supports not (grid-column-gap: 1rem) {
      position: fixed;
      left: 0;
      right: 0;
      max-width: 100%;

      &.sticky-top {
        top: rem-calc(57);
        z-index: 10;
      }

      &.alert {
        padding: 0.5rem;
      }

      p {
        margin-bottom: 0;
      }
    }
    @supports (grid-column-gap: 1rem) {
      display: none;
    }
  }
}
