.dashboard-card {
  background-color: $white;
  border-radius: 13px;
  padding: 1rem;
  height: auto;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);

  header {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
      font-size: 1.25rem;
    }
  }

  p {
    font-size: rem-calc(13);
  }

  button {
    font-size: rem-calc(10px);
  }

  .practitioners--list__item img {
    width: 2rem;
    height: 2rem;
  }

  @include breakpoint(laptop) {
    header {
      height: 5rem;
    }

    p {
      font-size: 1rem;
    }

    button {
      font-size: rem-calc(14px);
    }
  }

  .practitioners--list__item {
    padding: 0.25rem 0;
    cursor: pointer;
  }
}

.dashboard__diary {
  @include breakpoint(tablet) {
    .fc-toolbar.fc-header-toolbar {
      display: none;
    }
  }
}

.dashboard__link {
  padding: rem-calc(8 0);
  a {
    font-size: 1rem;
    &::after {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 1rem;
      z-index: 1;
      background: url('/static/img/icons/golden-medium/arrow-down.svg') center /
        cover;
      transform: rotate(-90deg);
    }
  }
}
