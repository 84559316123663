.featured-wrapper {
  position: relative;

  &__slider {
    > .slick-slider.slick-initialized {
      .slick-dots {
        bottom: rem-calc(-45);
      }
    }
    .practitioner-result__images {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
