.title {
  margin-bottom: rem-calc(48);
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  &--smaller {
    margin-bottom: rem-calc(32);
    padding-top: 1rem;
  }
  @include breakpoint(tablet) {
    padding: 0;
  }
}
