.label {
  display: inline-block;
  text-align: center;
  font-size: rem-calc(14);
  font-weight: 500;
  color: $medium-gray;
  background: $light-gray;
  padding: rem-calc(8 16);
  border-radius: 999px;
  vertical-align: middle;
  margin: rem-calc(0 4);

  &--no-margin {
    margin: 0;
  }

  &--small-font {
    font-size: rem-calc(12);
  }

  &--extra-small-font {
    font-size: rem-calc(10);
  }

  &--fullwidth {
    width: 100%;
  }

  &--golden {
    background: $golden-light;
    color: $golden-highlight;
  }

  &--golden-medium {
    background: $golden-medium;
    color: $white;
  }

  &--green {
    background: $green-light;
    color: $green-highlight;
  }

  &--confirmed {
    background-color: $green-middle;
    color: $green-highlight;
  }

  &--completed {
    background: $green;
    border: 1px solid $green;
    color: $green-highlight;
  }

  &--ivory {
    background: $ivory-light;
    color: $ivory-highlight;
  }

  &--red {
    background: $rose-highlight;
    color: $rose-pattern;
  }

  &--rose {
    background: $rose-medium;
    color: $white;
  }

  &--black {
    background: $charcoal-dark;
    color: $white;
  }

  &--brand-red {
    background: $red;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &--small {
    padding: rem-calc(4 16);
  }

  &--extra-small {
    padding: rem-calc(4 8);
  }

  &--placeholder {
    height: 2rem;
    background-color: transparent;
  }

  &--white-outline {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;
  }

  &--red-outline {
    border: 1px solid $rose-highlight;
    background-color: transparent;
    color: $rose-highlight;
  }

  &--black-outline {
    border: 1px solid $charcoal-dark;
    background-color: transparent;
    color: $charcoal-dark;
    text-transform: uppercase;
    padding: rem-calc(5 7);
    font-size: rem-calc(11);
    font-weight: 600;
  }

  &--progress {
    color: $green-highlight;
    border: 1px solid $green-highlight;
  }

  &--not-started {
    background-color: $charcoal-medium;
    color: $charcoal-highlight;
    border: 1px solid $charcoal-medium;
  }

  &--gold {
    background-color: $brand-gold;
    color: $white;
    padding: 0.5rem 0.75rem;
  }

  &__booking {
    &--completed {
      background-color: $brand-charcoal-light;
      color: $white;
    }
    &--confirmed {
      background-color: $brand-gold-light;
      color: $white;
    }
    &--pending {
      background-color: $rose-medium;
      color: $white;
    }
  }
}
