// Grayscale
$white: #ffffff;
$light-gray: #f4f4f4;
$medium-gray: #676767;

:export {
  white: $white;
  light-gray: $light-gray;
  medium-gray: $medium-gray;
}
