.banner {
  color: $white;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__blog {
    @supports (aspect-ratio: 16 / 9) {
      .banner__image img {
        width: 100%;
        background-color: $light-gray;
        aspect-ratio: 16 /9;
      }
    }

    &--page {
      @supports (aspect-ratio: 4 / 1) {
        height: rem-calc(355);

        .banner__image {
          height: 100%;
          position: relative;

          img {
            width: 100%;
            background-color: $light-gray;
            aspect-ratio: 4 / 1;
            object-fit: cover;
          }
        }
      }
      @supports not (aspect-ratio: 4 / 1) {
        height: rem-calc(355);

        .banner__image img {
          object-fit: cover;
          height: rem-calc(355);
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &--full-width {
      flex-basis: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: inherit;
  }

  @include breakpoint(tablet) {
    height: rem-calc(355);

    &__blog {
      height: auto;
    }

    h1 {
      font-size: rem-calc(50);
    }

    &__image {
      img {
        object-fit: cover;
      }
    }
  }

  $colors: (
    charcoal-dark: (
      bg: $charcoal-dark,
      fg: $white,
    ),
    charcoal-medium: (
      bg: $charcoal-medium,
      fg: $charcoal-dark,
    ),
    charcoal-light: (
      bg: $charcoal-light,
      fg: $charcoal-dark,
    ),
    charcoal-pattern: (
      bg: $charcoal-pattern,
      fg: $charcoal-dark,
    ),
    copper-dark: (
      bg: $copper-dark,
      fg: $charcoal-dark,
    ),
    copper-medium: (
      bg: $copper-medium,
      fg: $charcoal-dark,
    ),
    copper-light: (
      bg: $copper-light,
      fg: $charcoal-dark,
    ),
    copper-pattern: (
      bg: $copper-pattern,
      fg: $charcoal-dark,
    ),
    golden-dark: (
      bg: $golden-dark,
      fg: $charcoal-dark,
    ),
    golden-medium: (
      bg: $golden-medium,
      fg: $charcoal-dark,
    ),
    golden-light: (
      bg: $golden-light,
      fg: $charcoal-dark,
    ),
    golden-pattern: (
      bg: $golden-pattern,
      fg: $charcoal-dark,
    ),
    ivory-dark: (
      bg: $ivory-dark,
      fg: $charcoal-dark,
    ),
    ivory-medium: (
      bg: $ivory-medium,
      fg: $charcoal-dark,
    ),
    ivory-light: (
      bg: $ivory-light,
      fg: $charcoal-dark,
    ),
    ivory-pattern: (
      bg: $ivory-pattern,
      fg: $charcoal-dark,
    ),
    bronze-dark: (
      bg: $bronze-dark,
      fg: $charcoal-dark,
    ),
    bronze-medium: (
      bg: $bronze-medium,
      fg: $charcoal-dark,
    ),
    bronze-light: (
      bg: $bronze-light,
      fg: $charcoal-dark,
    ),
    bronze-pattern: (
      bg: $bronze-pattern,
      fg: $charcoal-dark,
    ),
    rose-dark: (
      bg: $rose-dark,
      fg: $charcoal-dark,
    ),
    rose-medium: (
      bg: $rose-medium,
      fg: $charcoal-dark,
    ),
    rose-light: (
      bg: $rose-light,
      fg: $charcoal-dark,
    ),
    rose-pattern: (
      bg: $rose-pattern,
      fg: $charcoal-dark,
    ),
  );

  @each $key in map-keys($colors) {
    &__background--#{$key} {
      color: map-get(map-get($colors, $key), 'fg');
      background: map-get(map-get($colors, $key), 'bg');
      height: 18.75rem;
    }

    &__background-slant--#{$key} {
      color: $charcoal-dark;
      overflow: hidden;
      height: 18.75rem;

      .banner__image {
        background: map-get(map-get($colors, $key), 'bg');
        transform: skew(-26deg) translateX(70%);
      }
    }
  }
}
