.search-widget {
  display: block;
  border-radius: 13px;
  overflow: hidden;
  background-color: $white;
  padding: 1rem;
  min-height: 11rem;

  &__results {
    display: flex;
    justify-content: center;
    padding: 5px 0;

    &:focus {
      outline: 1px dotted $golden-light;
      outline-offset: 0;
    }

    p {
      display: block;
      white-space: normal;
    }

    &:hover {
      cursor: pointer;

      &::before,
      &::after {
        opacity: 1;
      }
    }

    &--treatments {
      padding: 0;
      white-space: nowrap;

      strong {
        font-size: rem-calc(14);
        font-weight: 700;
        color: $golden-dark;
        display: block;
        padding: rem-calc(5px 8px);
        white-space: normal;
      }
    }

    &--location {
      &:focus {
        outline: 1px dotted $golden-light;
        outline-offset: 0;
      }
    }

    &--empty {
      p {
        font-size: rem-calc(14);
        padding: rem-calc(10);
        margin: 0;
        color: $charcoal-dark;
        border: none;

        &:hover {
          font-weight: inherit;
          color: $charcoal-dark;
          text-decoration: none;
        }
      }
    }

    .logo--container {
      width: 20%;

      &__practitioner {
        display: block;
        margin: 0 auto;
        width: rem-calc(45);
        border-radius: 100%;
      }

      &__clinic {
        margin-right: rem-calc(16);
        width: rem-calc(45);
        height: rem-calc(45);
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
          margin-left: rem-calc(16);
        }
      }
    }

    .results--practitioner {
      width: 80%;

      label {
        color: $golden-medium;
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-decoration: none;
      }

      span {
        margin-left: rem-calc(30);
      }
    }

    .results--clinic {
      width: 80%;
      align-self: center;

      label {
        color: $golden-medium;
      }

      span {
        margin-left: rem-calc(30);
      }
    }
  }

  @include breakpoint(tablet) {
    max-width: 24rem;
    box-shadow: 0 15px 35px 0 rgba(43, 64, 78, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 2rem;
    &__sidebar {
      background: $light-gray;
      box-shadow: none;
      padding: 0;
      margin-top: 0;
      min-height: 11rem;
      .field--search__items {
        background-color: $light-gray;
        &--open {
          padding-left: 0;
          padding-right: 0;
        }
        .radio-result label {
          border-color: $charcoal-medium;
        }
      }
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-around;

    div {
      text-align: center;
      width: 100%;

      .button--pill {
        width: 95%;
      }

      @include breakpoint(tablet) {
        button {
          min-width: 7rem;
          padding: rem-calc(9 11);
        }
      }
    }
  }

  &__action {
    margin-top: rem-calc(10);
  }

  .field {
    margin-bottom: rem-calc(8);
  }

  form {
    margin-top: 1rem;
  }

  input {
    border: 1px solid $charcoal-medium;
    padding-left: rem-calc(40);

    &.search {
      background: $white url('/static/img/icons/golden-dark/search.svg')
        rem-calc(12) 50% / auto 16px no-repeat;
    }

    &.location {
      background: $white url('/static/img/icons/golden-dark/location.svg')
        rem-calc(12) 50% / auto 16px no-repeat;
    }
  }
}

.current {
  .button--pill,
  .button--round,
  .button--round a {
    background-color: $brand-gold;
    color: $white;

    &:hover {
      background-color: $brand-gold-dark;
      border-color: $brand-gold-dark;
    }

    &:focus {
      background-color: darken($brand-gold, 20%);
      border-color: darken($brand-gold, 20%);
    }
  }
}
