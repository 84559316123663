.questionnaire {
  &__row {
    border-radius: 13px;
    border: 1px solid $brand-white;
    box-shadow: -2px 4px 5px 0px #f4f4f41a,
      -4px 4px 15px 0 rgba(40, 39, 39, 0.05);

    &--disabled {
      background-color: $charcoal-pattern;
    }

    &--outline {
      border: $charcoal-highlight solid 1px;
    }
  }

  &__flex {
    display: flex;
  }
}
