.featured-clinic {
  background-color: $white;
  border-radius: 13px;

  h3 {
    font-size: rem-calc(18);
    font-weight: 800;
    color: $charcoal-dark;
  }
  &__header {
    height: 5rem;
    overflow: hidden;
    .yotpo.bottomLine.yotpo-small {
      margin-top: -0.75rem;
    }
    .text-m {
      display: none;
    }
    .rating-area {
      margin-top: rem-calc(-4);
    }
  }

  .practitioner-result__images {
    overflow: hidden;
    border-radius: 13px 13px 0 0;
    height: 0;
    padding-bottom: 62.5%;
    img {
      width: 100%;
    }
    &::after {
      content: none;
    }

    .slick-dots {
      width: 45%;
      left: 0;
    }

    .slick-list {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    > .slick-slider.slick-initialized {
      .slick-dots {
        bottom: 0;
      }
    }
  }
  &__text {
    height: 4rem;
    overflow-y: auto;
    p {
      margin-bottom: 0;
      line-height: 1.25;
      font-size: 1rem;
      margin: 0.5rem 0 0.25rem 0;
    }
  }
  &__author {
    margin-top: 0.25rem;
  }

  &__logo {
    position: absolute;
    top: -40%;
    right: 1rem;
    width: 5rem;
    height: 5rem;
    z-index: 5;
    background-color: $white;
    border-radius: 100%;
    display: flex;
    align-items: flex-end;

    .avatar {
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
      border: 2px solid $light-gray;
      z-index: 0;
      background-color: $white;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
