.tooltip-wrapper {
  display: inline-block;
  margin-left: 0.5rem;

  .tooltip-text {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, calc(100% + 10px));
    padding: 0.25rem 0.75rem;
    border-radius: 13px;
    background: $charcoal-dark;
    color: $charcoal-light;
    z-index: 1;

    &[data-hidden='true'] {
      display: none;
    }

    &[data-hidden='false'] {
      display: block;
    }
  }

  &--patient-active {
    margin-top: auto;
    margin-bottom: auto;
    .tooltip-text {
      bottom: 75%;
      left: 55%;
    }
  }

  &--higher {
    .tooltip-text {
      transform: translate(-50%, calc(100% - 1rem));
    }
  }

  &--invite {
    .tooltip-text {
      transform: translate(-50%, -7.5rem);
    }
  }
}

.accessible-tooltip {
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  display: block;
  background: $charcoal-medium url('/static/img/icons/white/question.svg')
    center / 50% auto no-repeat;
  color: transparent;
  cursor: pointer;
}
