.file-uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $light-gray;
  border: $light-gray solid 1px;

  p {
    margin-top: 1rem;
    color: $charcoal-dark;
    font-size: 0.9rem;
  }
  &__dragging {
    border: 2px solid $bronze-medium;
  }
  span.icon {
    background: $white url('/static/img/icons/add.svg') center / 50% auto
      no-repeat;
    display: inline-block;
    border: $light-gray solid 1px;
    border-radius: 100%;
    height: 3em;
    width: 3em;
    box-shadow: 0 4px 4px 0 rgba(40, 39, 39, 0.05);
  }

  &--reset p {
    font-size: inherit;
    color: inherit;
    margin: 0;
  }
}
