.data-header {
  display: flex;
  padding: 1rem;

  span {
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    padding: 0;
    font-size: rem-calc(12);
  }

  @include breakpoint(laptop) {
    span {
      font-size: rem-calc(14);
    }
  }
}
