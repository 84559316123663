#search-results-container {
  height: auto;

  @include breakpoint(tablet) {
    padding: 1rem;
  }
  @include breakpoint(laptop) {
    max-height: rem-calc(900);
    overflow: auto;
    padding: 0;
  }
}

.search-results {
  @include breakpoint(tablet) {
    height: 80vh;
  }

  .row {
    @include breakpoint(tablet) {
      height: 100%;
      flex-wrap: nowrap;
    }
  }

  &__clinics {
    padding: rem-calc(16);

    @include breakpoint(tablet) {
      height: 100%;
      overflow: auto;
    }
  }

  &__map {
    height: rem-calc(320);
    margin-bottom: rem-calc(16);

    @include breakpoint(tablet) {
      height: 100%;
      margin-bottom: 0;
    }
  }
}
