.price-row {
  display: flex;
  font-weight: 600;
  font-size: 1.125rem;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;

  @include breakpoint(tablet) {
    margin: 0 -0.75rem;
    padding: 1.5rem;
    background-color: $charcoal-medium;
    border-radius: 0 0 13px 13px;

    &--offset {
      margin-top: 0.75rem;
      margin-bottom: -0.75rem;
    }

    &--margin {
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
    }

    &--small {
      padding: 0.75rem;
    }
  }

  &__header {
    margin-right: auto;
  }

  &__item {
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted $green-highlight;
    margin-bottom: 0.5rem;

    &:last-child {
      border-bottom: 0;
    }

    @include breakpoint(tablet) {
      &:last-child {
        border-bottom: 1px dotted $green-highlight;
      }
    }
  }
}

.price__row {
  &--default {
    @include breakpoint(tablet) {
      margin: 0;
      border-radius: 13px;
      background-color: $brand-white;
    }
  }

  &--paid {
    background-color: $green;
    margin: 0;
    border-radius: 13px;
  }

  &--paddingless {
    padding-bottom: 0;
    @include breakpoint(tablet) {
      padding-bottom: 1.5rem;
    }

    .price-row__item:last-child {
      border-bottom: 0;
    }
  }
}
