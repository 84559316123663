.recruitment {
  &__image {
    display: none;
  }
}

@media (min-width: $grid-break-point-tablet) {
  .recruitment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__image {
      display: block;
      width: 50%;
    }
  }
}
