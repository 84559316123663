.shadow {
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);

  &__wrapper--child .tile-slanted {
    box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
      0 5px 15px 0 rgba(40, 39, 39, 0.05);
  }

  &__utility {
    box-shadow: 1px 2px 8px 0 rgba($charcoal-dark, 0.2);
  }
  &__utility--spread {
    box-shadow: rgba($charcoal-dark, 0.2) 1px 1px 3px 0;
    @include breakpoint(tablet) {
      box-shadow: rgba($charcoal-dark, 0.07) 2px 2px 16px 0px;
    }
    @include breakpoint(laptop) {
      box-shadow: rgba($charcoal-dark, 0.07) 5px 6px 30px 0px;
    }
  }
}
