.availability-constraint-list {
  display: flex;
  flex-direction: column;

  &__day {
    height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__location {
    flex-shrink: 0;
    &--empty {
      height: 3.5rem;
      margin-bottom: 0.5rem;
      @include breakpoint(tablet) {
        padding-left: 2.5rem;
      }
    }
    &--name {
      height: 3.5rem;
    }
  }

  &__label {
    font-weight: 600;
    padding: 0;
    border-bottom: 1px solid $light-gray;
    @include breakpoint(tablet) {
      padding: 0.5rem 1rem;
    }
    &--top {
      font-weight: 600;
      background: $brand-charcoal-light;
      color: $white;
      border-top-right-radius: 13px;
      border-top-left-radius: 13px;
    }
  }

  &__button {
    padding: 1rem 0;
    width: 100%;
    @include breakpoint(tablet) {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 0;
      border-bottom: 1px solid $light-gray;
    }
    .button {
      width: 100%;
      @include breakpoint(tablet) {
        width: auto;
      }
    }
  }
  &-booking-notice {
    width: 100%;
  }
  &-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 13px;
    border: 1px solid $brand-white;

    &--multilocation {
      border-radius: 0;
      padding: 1.5rem;
      border: none none 1px solid $brand-white none;
      &:nlast-child() {
        border-bottom: none;
      }
      @include breakpoint(tablet) {
        padding: inherit;
        background-color: $brand-white;
        border-radius: 13px;
        margin-block: 0.5rem;
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
      box-shadow: none;
      border: none;
    }

    .field {
      margin-top: 1rem;
      @include breakpoint(tablet) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      &__footer {
        background-color: $light-gray;
        padding: 0.5rem 1rem;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
      }
    }
    .time-select {
      border-radius: 13px;
      border: 1px solid $brand-white;
      background: transparent;
      font-size: rem-calc(13);
      width: 100%;
      height: 3rem;

      .field__select {
        display: inline;
        text-align-last: center;
        &::before {
          content: none;
        }

        &:first-of-type::after {
          content: ':';
          width: 0.5rem;
        }

        select {
          display: inline;
          border: none;
          max-width: calc(50% - 0.25rem);
          margin: 0;
          color: $charcoal-dark;
          background: transparent;
          font-size: rem-calc(13);
          min-width: auto;
          padding-right: 0;
          padding-left: 0.25rem;
        }
      }
      @include breakpoint(tablet) {
        width: 8rem;
      }
    }

    .round-button {
      img {
        width: 0.75rem;
        height: 0.75rem;
      }

      @include breakpoint(tablet) {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 0;
        @supports not (aspect-ratio: 1/1) {
          height: 1.85rem;
          width: auto;
          aspect-ratio: 1/1;
        }
        @supports (aspect-ratio: 1/1) {
          width: 1.85rem;
          height: 1.85rem;
        }
      }
    }

    span {
      padding: 0.7rem 0.5rem;
    }
  }
}
