.overflowSlider {
  width: auto;
  height: auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  margin-bottom: 2rem;
  scroll-behavior: smooth;
  &.filter-gradient {
    mask-image: linear-gradient(to right, $white 70%, transparent 100%);
  }
  &--marginless {
    margin-bottom: 0;
    .field.field--inline:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .before-after-upload {
      padding-bottom: 0;
      &__info {
        .field {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    width: auto;
    height: auto;
    flex-wrap: wrap;
    &.row--no-wrap {
      flex-wrap: nowrap;
    }
    &.filter-gradient {
      mask-image: none;
      margin-bottom: 0;
    }
  }
  .slide {
    width: 80%;
    flex-shrink: 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    scroll-snap-align: center;
    &--fluid {
      width: inherit;
    }
  }
}
