.contact-widget {
  @include breakpoint(tablet) {
    padding: 2em 4em;
    border-radius: 13px;
    box-shadow: 0 15px 35px 0 rgba(43, 64, 78, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.05);
  }

  background-color: $light-gray;
  display: block;
  margin: 0 auto;
  padding: 2em 0;

  .option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    border-bottom: 1px solid $charcoal-medium;

    @include breakpoint(tablet) {
      display: block;
      text-align: center;
      border: none;
      p {
        color: $charcoal-dark;
      }
      img {
        display: block;
        margin: 1em auto;
      }
    }
  }
  a {
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }

    h4 {
      color: $charcoal-dark;
      position: relative;
      margin-bottom: 0;
      &::after {
        background-image: url(/static/img/icons/chevron-right.svg);
        width: 1.25rem;
        height: 1.25rem;
        background-size: 15px;
        display: inline-block;
        background-repeat: no-repeat;
        content: '';
        background-position: center;
        position: absolute;
        top: 1px;
      }
    }
  }
  img {
    margin-right: 2rem;
  }
}
