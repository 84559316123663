.image-uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $light-gray;
  border: $light-gray solid 1px;
  overflow: hidden;

  &:focus {
    border-color: $golden-dark;
    outline: none;
  }

  p {
    margin-top: 1rem;
    color: $charcoal-dark;
    font-size: 0.9rem;
  }

  span.icon {
    background: $white url('/static/img/icons/add.svg') center / 50% auto
      no-repeat;
    display: inline-block;
    border: $light-gray solid 1px;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    box-shadow: 0 4px 4px 0 rgba(40, 39, 39, 0.05);
  }

  .slim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;

    &[data-state*='empty']:hover {
      background-color: darken($color: #f4f4f4, $amount: 5%);
    }

    .slim-btn-group {
      .slim-btn-edit {
        background-image: url('/static/img/icons/white/edit.svg');
      }

      .slim-btn-remove {
        background-image: url('/static/img/icons/white/trash.svg');
      }
    }

    .slim-area {
      .slim-status {
        height: 100%;

        .slim-label {
          visibility: hidden;

          span.icon {
            background: $white url('/static/img/icons/add.svg') center / 50%
              auto no-repeat;
            display: inline-block;
            border: $light-gray solid 1px;
            border-radius: 100%;
            height: 3rem;
            width: 3rem;
            box-shadow: 0 4px 4px 0 rgba(40, 39, 39, 0.05);
          }

          p {
            margin-top: 1rem;
          }
        }
      }

      .slim-loader {
        top: 0.5rem;
      }

      .slim-upload-status[data-state='error'] {
        color: $red;
        background-color: white;
      }

      .slim-upload-status {
        background-color: $green;
        color: $charcoal-dark;
        border-radius: 13px;
        top: 0.625rem;

        .slim-upload-status-icon {
          display: none;
        }
      }
    }
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }

  .slim-btn-group {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .slim-btn-edit {
      background-image: url('/static/img/icons/white/edit.svg');
      cursor: pointer;
    }

    .slim-btn-remove {
      background-image: url('/static/img/icons/white/trash.svg');
      cursor: pointer;
    }
  }
}

.iconimage-uploader-popover {
  .slim-image-editor {
    .slim-editor-btn-group {
      .button {
        @include glowdayButton;
      }
    }
  }
}
