.clinic-step {
  margin: rem-calc(40) auto;
  max-width: 100%;
  height: 28.5rem;
  display: block;
  border: 1px solid $charcoal-medium;
  border-radius: 13px;

  h3 {
    font-family: $global-font-family;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: rem-calc(32);
  }

  @include breakpoint(tablet) {
    border: none;
    min-height: 21rem;
    max-height: 25rem;
    max-width: rem-calc(340);
  }

  &__content {
    padding: rem-calc(16);
  }
}
