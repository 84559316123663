.paging {
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    list-style-type: none;
    margin: rem-calc(0 8);

    a {
      display: block;
      border-radius: 100%;
      font-weight: 500;
      color: $rose-medium;
      border: 2px solid $light-gray;
      text-decoration: none;
      width: rem-calc(32);
      height: rem-calc(32);
      line-height: rem-calc(30);
      text-align: center;

      &:hover {
        background: $rose-medium;
        color: $white;
      }

      &.active {
        background: $rose-medium;
        color: $white;
        border-color: $rose-medium;
      }
    }
  }
}
