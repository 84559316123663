.patient-booking-info {
  .widget__details--contact__row strong {
    font-size: inherit;
  }

  a {
    color: $golden-dark;
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $charcoal-medium;
    flex-wrap: wrap;

    &:first-of-type {
      border-top: 1px solid $charcoal-medium;
    }

    &:last-of-type {
      border-top: none;
    }

    & > div {
      padding: 0.75rem 0.5rem;

      h4 {
        font-size: rem-calc(14);
      }

      .treatment-list {
        color: $charcoal-dark;
        padding: 0 0.5rem 0 1rem;
        margin: 0.5rem 0 0 0;
        font-size: rem-calc(12);
      }

      .source-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__button {
          width: 2rem;
          height: 2rem;

          .button--modal__back.round-button {
            width: 100%;
            height: 100%;
            display: block;
            padding: 0;
            margin: 0;
            position: static;

            img {
              width: 1rem;
              height: 1rem;
              padding: 0;
            }
          }

          @include breakpoint(tablet) {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      .gap {
        margin-left: 0.25rem;
      }

      .treatment-label {
        font-size: rem-calc(12);
        line-height: 1;
      }

      p {
        font-size: rem-calc(14);
        line-height: 1.25;
      }
    }

    &--selection {
      & > div {
        padding: 0.75rem 0;
      }
    }

    .price-item strong {
      font-size: 1.5rem;
    }

    .accordion__notes {
      background-color: $light-gray;

      .accordion__header {
        padding: 0;
      }

      .open {
        margin: 0;
      }

      .accordion__body--content {
        margin: 0;
        padding: 0;
      }
    }
  }

  &__header {
    overflow: hidden;

    .label-wrapper {
      width: 100%;
      border-top: 1px solid $charcoal-medium;
    }

    .label {
      font-size: rem-calc(12);
      margin: 0;
    }
  }

  @include breakpoint(tablet) {
    .label-wrapper {
      margin-left: auto;
      width: initial;
      border-top: 0;
    }

    &__row {
      border-bottom-style: dashed;

      &:first-of-type {
        border-top: none;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &--border {
        &:last-of-type {
          border-bottom: 1px solid $charcoal-medium;
        }
      }

      &--start {
        align-items: flex-start;
      }

      &--main {
        height: 20rem;
      }

      &--details {
        .treatment {
          overflow: auto;
          max-height: 5rem;
        }

        span,
        strong {
          display: block;
          font-size: rem-calc(14);
        }

        small {
          font-size: rem-calc(12);
        }
      }

      &--fields {
        .field label {
          margin-top: 0;
          line-height: 1.5;
          font-size: rem-calc(14);
        }
      }

      .notes-form textarea {
        height: 10rem;
      }

      .price-item__big strong {
        font-size: rem-calc(32);
      }
    }
  }
}
