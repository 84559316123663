.top-questions {
  margin: 1em auto;
  font-weight: 900;
  padding: 0 2rem;

  @include breakpoint(tablet) {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 2rem;

    &__border {
      border-right: 2px solid white;
    }
  }

  .question {
    cursor: pointer;
    border-top: 1px solid $white;
    padding-top: 1rem;
    padding-bottom: 1.25rem;

    a {
      color: white;
      font-size: 1.25rem;
      text-decoration: none;
      display: block;
      line-height: 1.5;
    }

    &:last-child {
      border-bottom: 1px solid $white;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      text-decoration-color: $bronze-light;

      a {
        color: $bronze-light;
      }
    }

    @include breakpoint(tablet) {
      border-top: none;
      padding-top: 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__right {
    @include breakpoint(tablet) {
      padding-left: 3rem;
    }
  }

  &__guide-link {
    cursor: pointer;
    color: $white;
    text-decoration: none;
    h2 {
      display: inline;
      font-size: rem-calc(20);
      padding-left: 1rem;
    }

    @include breakpoint(tablet) {
      padding: 2em 0 0 0;
      text-align: center;

      h2 {
        font-size: rem-calc(28);
      }
    }

    img {
      vertical-align: text-bottom;
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $white;
      padding: rem-calc(1);
      margin-left: rem-calc(10);
      width: rem-calc(20);
      height: rem-calc(20);

      @include breakpoint(tablet) {
        padding: rem-calc(4);
        margin-left: rem-calc(16);
        width: rem-calc(32);
        height: rem-calc(32);
      }
    }

    &:hover,
    &:focus {
      h2 {
        color: $bronze-light;
        text-decoration: underline;
        text-decoration-color: $bronze-light;
      }

      img {
        border-color: $bronze-light;
        background-color: $bronze-light;
      }
    }
  }
}
