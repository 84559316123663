.border-top-bottom {
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;

  &__charcoal-medium {
    border-color: $charcoal-medium;
  }
}

.border-bottom {
  border-bottom: 1px solid $brand-white;

  &__charcoal-medium {
    border-color: $charcoal-medium;
  }

  &__dashed {
    border-bottom: 1px dashed $border-gray;
  }
  &--t-none {
    @include breakpoint(tablet) {
      border-bottom: 0;
    }
  }
}

.border-bottom-t {
  @include breakpoint(tablet) {
    border-bottom: 1px solid $light-gray;
  }
}

.border-top {
  border-top: 1px solid $brand-white;

  &__charcoal-medium {
    border-color: $charcoal-medium;
  }

  &__dashed {
    border-top: 1px solid $border-gray;
    border-top-style: dashed;
  }

  &--t-none {
    @include breakpoint(tablet) {
      border-bottom: 0;
    }
  }
}

.border-right {
  border-right: 1px solid $light-gray;

  &__border-grey {
    border-color: $border-gray;
  }

  &__charcoal-medium {
    border-color: $charcoal-medium;
  }

  &__dashed {
    border-right: 1px solid $border-gray;
    border-right-style: dashed;
  }

  &--t-none {
    @include breakpoint(tablet) {
      border-right: 0;
    }
  }
}

.border-top-t {
  @include breakpoint(tablet) {
    border-top: 1px solid $light-gray;
  }
}

.border-right-t {
  @include breakpoint(tablet) {
    border-right: 1px solid $light-gray;
  }
}

.border-bottom-t-none {
  @include breakpoint(tablet) {
    border-bottom: 0;
  }
}

.border-radius {
  border-radius: 13px;
}

.border {
  border: solid 1px $light-gray;

  &--rose {
    border-color: $rose-highlight;
  }

  &--white {
    border-color: $white;
  }

  &--green {
    border-color: $green;
  }
}
