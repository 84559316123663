.invoice-label {
  position: absolute;
  transform: rotate(45deg);
  right: rem-calc(-37px);
  top: rem-calc(19px);
  font-size: 1rem;
  color: $white;
  background-color: $green;
  width: 8rem;
  &--consultation {
    font-size: rem-calc(10);
    background-color: $ivory-medium;
    line-height: 1.25rem;
    height: 1.25rem;
  }
}
