.session-picker {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  &--border {
    border-bottom: 1px dashed $charcoal-medium;
  }
  &__area {
    min-height: 3rem;
    max-height: 10rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
