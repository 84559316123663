.authorSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
}

.author-details {
  display: flex;

  &--small {
    .author-details__image {
      width: rem-calc(32);
      height: rem-calc(32);
      margin-right: 0.5rem;
    }
    @include breakpoint(laptop) {
      .author-details__image {
        width: rem-calc(48);
        height: rem-calc(48);
      }
      .author-details__details {
        margin-left: 0.25rem;
      }
    }
  }

  &__image {
    width: rem-calc(48);
    height: rem-calc(48);
    border: 1px solid $white;
    margin-right: 1rem;

    @include breakpoint(laptop) {
      margin-right: inherit;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include breakpoint(laptop) {
      margin: rem-calc(5) rem-calc(16);
    }
  }

  &__name {
    font-size: rem-calc(14);
  }

  &__label {
    font-weight: 600;
  }

  &__date {
    color: $charcoal-medium;
    font-size: rem-calc(12);
  }

  &__date--charcoal-highlight {
    color: $charcoal-highlight;
  }
}
