.practitioners {
  &__dashboard {
    justify-content: flex-start;
    span.practitioners__list--icon,
    .practitioners__list--icon a {
      font-weight: 400;
      color: $brand-gold;
      font-size: 1rem;
    }
  }

  &__list {
    max-height: rem-calc(140);
    color: $brand-gold;
    overflow-y: auto;
    margin-right: 0.25rem;
    ::-webkit-scrollbar {
      display: none;
    }

    .text-m {
      display: none;
    }

    .yotpo.bottomLine .standalone-bottomline .yotpo-icon {
      width: rem-calc(14) !important;
      height: rem-calc(14) !important;
    }

    .slat__icon {
      background-color: $ivory-light;
      color: $brand-gold-dark;
    }

    &--item {
      display: flex;
      padding: 0.35rem;
      transition: all 0.2s ease-in-out;
      text-transform: capitalize;
      text-decoration: none;
      position: relative;
      justify-content: flex-start;
      a {
        padding: 0 0.5rem;
        height: 4.5rem;
      }

      a,
      span {
        font-size: rem-calc(16);
        color: $charcoal-dark;
        text-decoration: none;
      }

      &__column {
        display: flex;

        & > div {
          margin: rem-calc(8);
        }
      }

      img,
      .slat__icon {
        vertical-align: middle;
        width: rem-calc(38);
        height: rem-calc(38);
        line-height: rem-calc(38);
      }

      .rating {
        display: flex;
      }

      &::after {
        content: '';
        transition: all 0.2s ease-in-out;
      }

      @include breakpoint(laptop) {
        border-bottom: 1px solid $golden-pattern;

        &:hover {
          &::after {
            content: '';
            position: absolute;
            width: 0.75rem;
            height: 0.75rem;
            right: 0.75rem;
            z-index: 1;
            background: url('/static/img/icons/gold-medium/arrow-right.svg')
              center / cover;
            transition: all 0.2s ease-in-out;
          }

          span {
            color: $brand-gold-dark;
            transition: all 0.2s ease-in-out;
          }
        }

        &:focus {
          outline: 1px dotted $brand-gold;
          outline-offset: 0;

          span {
            color: $brand-gold-dark;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    &--icon {
      position: relative;
      text-decoration: none;
      color: $brand-gold;
      font-size: rem-calc(16);
    }
  }

  &__big {
    overflow-x: hidden;
    max-height: 17rem;

    ::-webkit-scrollbar {
      display: none;
    }

    img,
    .slat__icon {
      vertical-align: middle;
      width: rem-calc(64);
      height: rem-calc(64);
      line-height: rem-calc(64);
    }

    .practitioners__list--item::after {
      content: none;
    }

    @include breakpoint(laptop) {
      max-height: rem-calc(420);

      .practitioners__list--item {
        border-bottom: none;

        &:hover,
        &:focus {
          border-bottom: none;
          transition: none;

          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.clinic-practitioner-list {
  padding: 1rem 0;
  border-bottom: 1px solid $light-gray;
}

.practitioner__avatar {
  margin-right: 0.5rem;
}
