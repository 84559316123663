.additional-item {
  display: flex;
  padding: 0.5rem 0;

  @include breakpoint(tablet) {
    padding: 0;
  }

  &__row {
    padding: rem-calc(8 0);
    border-bottom: 1px dashed $charcoal-medium;
    &--borderless {
      border: none;
    }
  }
}
