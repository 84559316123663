.icon-filled {
  display: inline-block;
  width: rem-calc(48);
  height: rem-calc(48);
  background: $white;
  box-shadow: 0 4px 4px 0 rgba(40, 39, 39, 0.05);
  border-radius: 100%;
  padding: rem-calc(12);
  -webkit-appearance: none;
  outline: none;
  border: none;
  transition: 0.4s all;

  img {
    width: 100%;
  }

  &:hover,
  &:focus {
    background: $light-gray;
    cursor: pointer;
  }

  &--small {
    width: rem-calc(24);
    height: rem-calc(24);
    padding: rem-calc(6);
  }
}

button.icon-filled {
  cursor: pointer;
}
