@for $i from 0 through 15 {
  .margin-top-#{$i} {
    margin-top: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .margin-top__t-#{$i} {
      margin-top: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .margin-top__l-#{$i} {
      margin-top: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .margin-top__d-#{$i} {
      margin-top: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .margin-bottom-#{$i} {
    margin-bottom: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .margin-bottom__t-#{$i} {
      margin-bottom: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .margin-bottom__l-#{$i} {
      margin-bottom: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .margin-bottom__d-#{$i} {
      margin-top: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .margin-left-#{$i} {
    margin-left: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .margin-left__t-#{$i} {
      margin-left: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .margin-left__l-#{$i} {
      margin-left: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .margin-left__d-#{$i} {
      margin-left: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .margin-right-#{$i} {
    margin-right: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .margin-right__t-#{$i} {
      margin-right: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .margin-right__l-#{$i} {
      margin-right: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .margin-right__d-#{$i} {
      margin-right: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .padding-top-#{$i} {
    padding-top: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .padding-top__t-#{$i} {
      padding-top: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .padding-top__l-#{$i} {
      padding-top: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .padding-top__d-#{$i} {
      padding-top: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .padding-bottom-#{$i} {
    padding-bottom: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .padding-bottom__t-#{$i} {
      padding-bottom: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .padding-bottom__l-#{$i} {
      padding-bottom: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .padding-bottom__d-#{$i} {
      padding-bottom: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .padding-left-#{$i} {
    padding-left: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .padding-left__t-#{$i} {
      padding-left: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .padding-left__l-#{$i} {
      padding-left: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .padding-left__d-#{$i} {
      padding-left: rem-calc($i * 16);
    }
  }
}

@for $i from 0 through 15 {
  .padding-right-#{$i} {
    padding-right: rem-calc($i * 16);
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through 15 {
    .padding-right__t-#{$i} {
      padding-right: rem-calc($i * 16);
    }
  }
}

@include breakpoint(laptop) {
  @for $i from 0 through 15 {
    .padding-right__l-#{$i} {
      padding-right: rem-calc($i * 16);
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through 15 {
    .padding-right__d-#{$i} {
      padding-right: rem-calc($i * 16);
    }
  }
}
