.location__list {
  scrollbar-gutter: stable;
  @include breakpoint(tablet) {
    max-height: 15rem;
    overflow-y: auto;
    padding-right: 0.75rem;
    overscroll-behavior-y: contain;
  }
}

.location__map {
  @include breakpoint(tablet) {
    padding-left: 1.25rem;
  }
}
