.blog-author {
  @include breakpoint(tablet) {
    display: flex;
    justify-content: space-between;
    content-visibility: auto;
  }
}

.related-articles__section {
  margin-left: -1rem;
  margin-right: -1rem;
  content-visibility: auto;
  height: rem-calc(330);
  overflow-y: auto;
}

.blog-grid {
  display: flex;
  width: 100%;

  &--wrap {
    flex-wrap: wrap;
  }
}
.blog-min-height {
  @include breakpoint(laptop) {
    min-height: 55rem;
  }
}
