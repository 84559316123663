.toggle {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 1.5rem;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle__slider {
      background-color: $charcoal-highlight;
      border-color: $charcoal-highlight;

      &::before {
        transform: translateX(2rem);
      }
    }

    &:focus + .toggle__slider {
      box-shadow: 0 0 1px $charcoal-dark;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    transition: 0.2s ease-in;
    border-radius: 13px;
    border: 1px solid $charcoal-medium;

    &:before {
      position: absolute;
      width: 1rem;
      height: 1rem;
      content: '';
      left: 0.25rem;
      bottom: rem-calc(3);
      background-color: $green;
      transition: 0.2s ease-in;
      border-radius: 100%;
    }

    &--appointment {
      &::before {
        background-color: $ivory-medium;
      }
    }
  }

  &--disabled {
    .toggle__slider {
      cursor: not-allowed;
      filter: saturate(20%);
    }
  }
}
