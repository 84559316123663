.questionnaire-form {
  &__modal {
    max-width: 95vw;
    max-height: 90vh;
    overflow: hidden;
    background-color: $light-gray;

    @include breakpoint(tablet) {
      width: rem-calc(512);
    }
  }

  &__content {
    overflow-y: auto;
    max-height: 75vh;
    overflow-x: hidden;
    background-color: $white;
    border-radius: 13px;
  }
}
