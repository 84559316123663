$global-font-family: 'proxima-nova', 'Helvetica', 'Ariel', sans-serif;
$header-font-family: lust, 'Garamond', serif;

body {
  font-family: $global-font-family;
  color: $charcoal-dark;
  font-size: 16px;

  * {
    font-family: $global-font-family;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 rem-calc(16);
  line-height: 1.25;
}

h1,
h2,
h3 {
  &.small {
    font-size: rem-calc(20px);
  }

  &.smaller {
    font-size: rem-calc(18);
  }

  &.medium {
    font-size: rem-calc(24);
  }
}

h1 {
  font-size: $heading-1-font-size-mobile;

  @include breakpoint(tablet) {
    font-size: $heading-1-font-size-tablet;
  }

  @include breakpoint(desktop) {
    font-size: $heading-1-font-size-desktop;
  }
}

h2 {
  font-size: $heading-2-font-size-mobile;

  @include breakpoint(tablet) {
    font-size: $heading-2-font-size-tablet;
  }

  @include breakpoint(desktop) {
    font-size: $heading-2-font-size-desktop;
  }
}

h3 {
  font-size: $heading-3-font-size-mobile;

  @include breakpoint(tablet) {
    font-size: $heading-3-font-size-tablet;
  }

  @include breakpoint(desktop) {
    font-size: $heading-3-font-size-desktop;
  }
}

h4 {
  font-size: $heading-4-font-size-mobile;

  @include breakpoint(tablet) {
    font-size: $heading-4-font-size-tablet;
  }

  @include breakpoint(desktop) {
    font-size: $heading-4-font-size-desktop;
  }
}

h5 {
  font-size: $heading-5-font-size-mobile;

  @include breakpoint(tablet) {
    font-size: $heading-5-font-size-tablet;
  }

  @include breakpoint(desktop) {
    font-size: $heading-5-font-size-desktop;
  }
}

h1 {
  font-family: $header-font-family;
  font-weight: 400;

  &.default-font {
    font-family: $global-font-family;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-style: normal;
}

.default-font {
  h1 {
    font-family: $global-font-family;
  }
}

p,
ul,
ol {
  font-size: rem-calc(16);
  line-height: 1.5;
  margin: 0 0 rem-calc(16);
  color: $medium-gray;

  &.small {
    font-size: rem-calc(14);
  }
}

span.medium {
  font-size: rem-calc(18);
}

a {
  color: $brand-gold-dark;
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted $golden-light;
  }
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: rem-calc(14);
  font-weight: 500;
  font-family: $global-font-family;
}

.stat-label {
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: rem-calc(10);
  font-weight: 500;
  margin-top: rem-calc(16);
  color: $medium-gray;
}

.bold {
  font-weight: 600;
}

.block {
  display: block;
}

.break-all {
  word-break: break-all;
}

.boldest {
  font-weight: 700;

  h1 {
    font-weight: 700;
  }
}

.text-right {
  text-align: right;

  &__t {
    @include breakpoint(tablet) {
      text-align: right;
    }
  }
}

.text-center {
  text-align: center;
}

.text__t-center {
  @include breakpoint(tablet) {
    text-align: center;
  }
}

.text-left {
  text-align: left;

  &__t {
    @include breakpoint(tablet) {
      text-align: left;
    }
  }

  &__l {
    @include breakpoint(laptop) {
      text-align: left;
    }
  }
}

.no-style a {
  color: inherit;
  text-decoration: none;
  font-weight: inherit;
}

.underline {
  -webkit-text-decoration-color: $charcoal-dark;
  text-decoration-color: $charcoal-dark;
  a {
    color: $charcoal-dark;
  }

  &--only {
    a {
      font-weight: 400;
    }
  }
}

.golden {
  color: $brand-gold-dark;

  &--light {
    color: $brand-gold-light;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalise {
  text-transform: capitalize;
}

.medium {
  @include breakpoint(tablet) {
    &--t {
      font-size: 100%;
    }
  }
}

blockquote {
  border-left: 10px solid $light-gray;
  margin: 1.5rem 0.75rem;
  padding: 0.5rem 0.75rem;
  quotes: '\201C''\201D''\2018''\2019';
  font-style: italic;
  font-size: rem-calc(20);
  color: $medium-gray;

  &:before {
    color: #ccc;
    content: open-quote;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.25rem;
    vertical-align: -0.4rem;
  }
}

abbr[title].no-dotted-style {
  text-decoration: none;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: optional;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'lust';
  src: url('https://use.typekit.net/af/6a323f/000000000000000000014314/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6a323f/000000000000000000014314/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6a323f/000000000000000000014314/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
