.option {
  text-align: center;
  margin-bottom: rem-calc(32);

  @include breakpoint(tablet) {
    margin-bottom: 0;
    display: inline-block;
  }

  &__icon {
    display: inline-block;
    width: rem-calc(48);
    margin-bottom: rem-calc(16);
  }

  h2 {
    font-size: rem-calc(20);
  }

  p {
    color: $medium-gray;
  }
}
