.footer {
  padding: rem-calc(64 0 100);
  position: absolute;
  z-index: -1;
  width: 100%;

  @include breakpoint(laptop) {
    position: static;
    z-index: inherit;
  }

  &__column {
    border-bottom: 1px solid $light-gray;
    padding: rem-calc(16 0);
    h2 {
      cursor: pointer;
      margin-bottom: 0;
      font-size: rem-calc(16);

      @include breakpoint(tablet) {
        cursor: initial;
        border: none;
        margin-bottom: 0.5rem;
        font-size: rem-calc(18);
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem-calc(16);
        height: rem-calc(16);
        background: url('/static/img/icons/add.svg') center / cover no-repeat;
        transition: 0.4s all;

        @include breakpoint(tablet) {
          content: none;
        }
      }
    }

    ul {
      list-style-type: none;
      max-height: 0px;
      overflow: hidden;
      display: none;
      padding: 0;
      @include breakpoint(tablet) {
        max-height: initial;
        font-size: rem-calc(12);
        display: block;
      }
      @include breakpoint(laptop) {
        font-size: rem-calc(14);
      }
    }

    &--expanded {
      ul {
        max-height: rem-calc(800);
        display: block;
      }

      h2 {
        margin-bottom: 0.5rem;
        &::after {
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    @include breakpoint(tablet) {
      border: none;
    }
  }

  h2 {
    position: relative;
    font-family: $global-font-family;
    font-size: rem-calc(16);
    font-weight: 700;
  }

  ul {
    margin-bottom: rem-calc(32);

    @include breakpoint(tablet) {
      margin-bottom: 0;
    }

    li a {
      color: $medium-gray;
      text-decoration: none;
      line-height: 2;
      transition: 0.4s all;

      &:hover,
      &:focus {
        color: $golden-dark;
      }

      &:focus {
        outline: none;
      }
    }

    &.columns {
      @include breakpoint(tablet) {
        column-count: 2;
        column-gap: $grid-gutter;
      }
    }

    &.social {
      margin: rem-calc(16 0);
      padding: 0;
      @include breakpoint(tablet) {
        margin: rem-calc(48 0 32 0);
      }

      li {
        display: inline-block;
        margin-right: rem-calc(24);

        img {
          height: rem-calc(16);
        }
      }
    }
  }

  p {
    font-weight: 500;
    color: $medium-gray;
  }
  &__disclaimer {
    .cms-content p {
      font-size: rem-calc(12);
      color: $charcoal-medium;
      margin-bottom: 0;
    }
  }
}
