.custom-arrow {
  &.slick-next {
    right: rem-calc(-10px);

    &::before {
      content: none;
    }
    @include breakpoint(tablet) {
      right: rem-calc(-16px);
    }
  }
  &.slick-prev {
    left: rem-calc(-22px);

    &::before {
      content: none;
    }
    @include breakpoint(tablet) {
      left: rem-calc(-27px);
    }
  }
  button {
    border-radius: 100%;
    border: none;
    width: 2.175rem;
    height: 2.175rem;
    display: block;
    background: url(/static/img/icons/charcoal-dark/arrow-down.svg) 50% / cover
      no-repeat;
    background-color: $white;
    background-size: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    cursor: pointer;
  }

  .next-arrow {
    transform: rotate(-90deg);
  }
  .prev-arrow {
    transform: rotate(90deg);
  }
  &.slick-disabled {
    button {
      background: url(/static/img/icons/charcoal-highlight/arrow-down.svg) 50% /
        cover no-repeat;
      background-color: $light-gray;
      cursor: not-allowed;
      background-size: 16px;
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }
  }
}
.next-blog-article {
  position: absolute;
  right: 0;
  width: 2.5rem;
  z-index: 1;
}

.prev-blog-article {
  position: absolute;
  left: 0.25rem;
  width: 2.5rem;
  z-index: 1;
}

.custom-arrow {
  &--zindex {
    z-index: 4;
  }
}
