.auto-scroll {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: $charcoal-medium;
  margin: 1rem;
  z-index: 2;

  &:hover,
  &:active,
  &:focus {
    background-color: $border-gray;
  }

  &--reversed {
    img {
      transform: rotate(180deg);
    }
  }

  &--top {
    top: rem-calc(-20);
    background-color: $brand-white;
    @include breakpoint(tablet) {
      bottom: 0;
      top: auto;
    }
  }
}
