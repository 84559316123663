.tooltip-popup-wrapper {
  .accessible-tooltip {
    font-size: rem-calc(10);
    padding: 0;
    &::before {
      content: none;
    }
  }
  .modal__dialog {
    max-width: 80vw;
    @include breakpoint(tablet) {
      max-width: 45vw;
    }
    @include breakpoint(laptop) {
      max-width: 40vw;
    }
    @include breakpoint(desktop) {
      max-width: 35vw;
    }
  }
}
