.menu {
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $brand-white;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
  position: relative;
  will-change: contents;
  &--alert {
    padding-top: 0.5rem;
    & > .menu__content {
      top: 3.25rem;
    }
  }
  &__header {
    padding: 0.5rem;
    background-color: $white;
    border-radius: 4px;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    transition: all 0.2s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      width: rem-calc(16px);
      height: rem-calc(16px);
      right: 0.5rem;
      z-index: 1;
      background: url('/static/img/icons/charcoal-dark/arrow-down.svg') center /
        cover;
      background-size: rem-calc(16px);
      transform: rotate(-90deg);
      transition: all 0.4s ease-in-out;
    }
    &:has(input[type='checkbox']:checked) {
      background-color: $brand-gold-light;
      transition: all 0.2s ease-in-out;
      color: $white;
      input[type='checkbox']:focus + label {
        color: $white;
      }
      &::after {
        transform: rotate(0);
        transition: all 0.4s ease-in-out;
        background: url('/static/img/icons/white/arrow-down.svg') center / cover;
      }
    }
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 2rem;
      left: 0;
      cursor: pointer;
      &:focus {
        & + label {
          outline: 1px dotted $golden-light;
          font-weight: 600;
          font-size: 1.25rem;
          color: $golden-dark;
        }
      }
    }

    @include breakpoint(laptop) {
      display: none;
    }
  }
  &__content {
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    max-height: 0;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 2.75rem;
    background-color: $brand-white;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    &--open {
      padding-top: 0.5rem;
      max-height: rem-calc(1000);
    }
    @include breakpoint(laptop) {
      flex-direction: row;
      column-gap: 0.5rem;
      padding: 0 1rem;
      max-height: rem-calc(800);
      position: static;
    }
  }
  &__item {
    flex: 1 1 max-content;
    margin-bottom: 0.5rem;
    min-width: 14ch;
    &--content {
      height: 0;
      overflow: auto;
      background-color: $white;
      border-radius: 0 0 10px 10px;
    }
    .menu__button:has(input[type='checkbox']:checked) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid $brand-white;

      &::after {
        transform: rotate(0);
      }
    }
    &--open {
      padding: 0.5rem;
      height: auto;
      max-height: 20rem;
    }
  }

  &__button {
    --image: '';

    background-color: $white;
    border: none;
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    text-decoration: none;
    font-weight: 600;
    position: relative;
    font-size: 1rem;
    text-transform: capitalize;
    border-radius: 10px;
    color: $charcoal-dark;
    &::after {
      content: '';
      position: absolute;
      width: rem-calc(16px);
      height: rem-calc(16px);
      right: 0.5rem;
      z-index: 1;
      background: url('/static/img/icons/charcoal-dark/arrow-down.svg') center /
        cover;
      background-size: rem-calc(16px);
      transform: rotate(-90deg);
      transition: all 0.4s ease-in-out;
    }
    &::before {
      content: '';
      background: var(--image) no-repeat center / cover;
      background-size: rem-calc(14px);
      content: '';
      position: absolute;
      left: 0.5rem;
      width: rem-calc(16px);
      height: rem-calc(16px);
    }

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 2rem;
      left: 0;
      cursor: pointer;
      &:focus {
        & + label {
          outline: 1px dotted $golden-light;
          font-weight: 600;
          color: $golden-dark;
        }
      }
      &:checked {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &::after {
          transform: rotate(0);
        }
      }
    }
    label {
      font-size: 1rem;
    }
  }
  &__link--row {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    &:not(:last-child) {
      border-bottom: 1px solid $brand-white;
    }
    position: relative;
    a,
    button {
      text-transform: capitalize;
      text-decoration: none;
      color: $charcoal-dark;
      font-size: rem-calc(16);
      line-height: 1.125;
      font-weight: 400;
      letter-spacing: normal;
      padding: 0;
      border-radius: 0;
      text-align: left;
      &:hover {
        color: $brand-gold-dark;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &::before {
      content: '';
      width: rem-calc(14px);
      height: rem-calc(18px);
      position: absolute;
      background: url('/static/img/icons/charcoal-dark/arrow-down.svg') center /
        cover no-repeat;
      left: 0;
      z-index: 1;
      background-size: rem-calc(14px);
      transform: rotate(-90deg);
    }
  }
}
