.treatment-forms {
  overflow-y: hidden;

  &__preview {
    max-height: 34rem;
    overflow-y: auto;

    &--small {
      max-height: 30rem;
    }

    &--tall {
      max-height: 65vh;
    }
  }
}
