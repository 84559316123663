.patient-directory {
  &__sticky-bar {
    position: sticky;
    background: $white;
    top: 0;
    z-index: 2;
    padding: 1rem;
    border: solid 1px $light-gray;
  }

  &__export {
    justify-content: flex-end;
  }
}
