.appointment-summary {
  &__header {
    font-size: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-bottom: 0.25rem;

    &--small {
      font-size: rem-calc(14);
    }
  }

  &__comments {
    overflow-y: auto;
    display: block;
    max-height: 4rem;
    word-break: break-word;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    &.border-top {
      padding-top: 0.5rem;
      border-top: 1px solid $light-gray;
    }
  }

  &__item {
    margin-bottom: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }

    &--no-border {
      border-bottom: none;
    }

    @include breakpoint(tablet) {
      border-bottom: none;
    }
  }

  .treatment-list {
    &__item {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
