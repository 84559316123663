.treatment-guide-illustration {
  display: flex;
  flex-direction: column;
  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
  &__image {
    @supports not (aspect-ratio: 1/1) {
      padding-top: 100%;
      height: 0px;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @supports (aspect-ratio: 1/1) {
      aspect-ratio: 1 / 1;
    }
  }
  h3 {
    font-size: rem-calc(15);
    font-weight: 600;
    height: rem-calc(39);
  }
  img {
    border-radius: 13px;
    background-color: $charcoal-dark;
    display: block;
  }
}
