.iconimage-uploader {
  width: 100%;
  display: flex;

  &__icon {
    height: 7rem;
    line-height: 6.6em;
    width: 7rem;
    border-radius: 100%;
    position: relative;
    display: flex;
    border: $light-gray solid 1px;
    margin-right: rem-calc(16);

    img {
      border-radius: 100%;
      width: 6.5rem;
      vertical-align: middle;
      @include breakpoint(tablet) {
        width: 7rem;
      }
    }

    .round-button {
      align-self: center;
      margin: auto;
      img {
        border: none;
        margin-left: 0;
      }

      border-radius: 50%;
      z-index: 10;
    }

    .slim {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: none;
      position: absolute;

      &[data-state*='empty']:hover {
        background: none;
      }

      .slim-btn-group {
        line-height: 100%;
        padding: 0.75rem 0;
        bottom: -1.5rem;

        .slim-btn-edit {
          background-image: url('/static/img/icons/white/edit.svg');
        }

        .slim-btn-remove {
          background-image: url('/static/img/icons/white/trash.svg');
        }
      }

      .slim-area {
        .slim-loader {
          width: 100%;
          right: 0;
          height: 100%;
          top: 0;
        }
        .slim-upload-status[data-state='error'] {
          color: $red;
          background-color: white;
        }
        .slim-upload-status {
          right: 50%;
          transform: translateX(50%);
          background-color: $green;
          color: $charcoal-dark;
          border-radius: 13px;

          .slim-upload-status-icon {
            display: none;
          }
        }

        .slim-status {
          height: 12rem;

          @include breakpoint(tablet) {
            height: 7rem;
          }
        }
      }
    }

    &--free {
      .slim {
        .slim-area .slim-result {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          justify-items: center;
          img.in {
            display: block;
            position: relative;
            width: 100%;
            border-radius: 0;
          }

          img {
            display: none;
          }
        }
      }
    }
  }

  &__detail {
    padding-top: rem-calc(16);
    p {
      font-size: 0.8em;
    }
  }
}

.iconimage-uploader-popover {
  .slim-image-editor {
    .slim-editor-btn-group {
      .button {
        @include glowdayButton;
      }
    }
  }
}
