.blog-article-card {
  cursor: pointer;
  margin-bottom: 2rem;

  &__image {
    @supports (aspect-ratio: 16 / 9) {
      img {
        aspect-ratio: 16 / 9;
        background-color: $light-gray;
        display: block;
      }
    }
  }

  &__category-name {
    font-size: rem-calc(12);
    margin-bottom: rem-calc(4);
    color: $charcoal-highlight;
    height: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__summary {
    font-size: rem-calc(14);
  }

  &__read-time {
    padding-left: rem-calc(8);
  }

  .blog-article-card__body {
    h3 {
      height: rem-calc(39px);
      font-weight: 600;
      font-size: rem-calc(15);
      margin-bottom: rem-calc(5);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include breakpoint(tablet) {
        font-size: rem-calc(17);
        height: rem-calc(42px);
      }

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      height: 5.8em;
      overflow: hidden;
      text-align: left;
    }
    .author-details__name {
      height: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .author-details:hover {
      .author-details__name {
        text-decoration: underline;
      }
    }

    &.author-biography {
      @include breakpoint(tablet) {
        p {
          overflow: auto;
        }
      }
      @include breakpoint(tablet) {
        p {
          max-height: 11rem;
        }
      }
    }
  }

  &-featured {
    background-color: $light-gray;

    .blog-article-card__image {
      margin-bottom: 0;
      width: 100%;
      flex: 1 1 50%;

      @supports (aspect-ratio: 1 / 1) {
        img {
          aspect-ratio: 1 / 1;
        }
      }

      &--author {
        flex: 1 1 50%;
        width: 100%;
        margin: 0;

        img {
          display: block;
          width: 100%;
          aspect-ratio: 1 /1;
        }
      }
    }

    .blog-article-card__body {
      padding: auto 0;
      flex: 1 1 50%;

      h3 {
        font-size: rem-calc(18px);
        color: $charcoal-dark;
        font-weight: 600;
        margin-bottom: rem-calc(5);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: rem-calc(64);

        @include breakpoint(laptop) {
          font-size: rem-calc(24);
          -webkit-line-clamp: 2;
        }
      }

      ol,
      ul,
      p {
        color: $medium-gray;
        font-size: rem-calc(16);
      }

      .author-details {
        &__details {
          margin-left: 0;
        }

        &__name {
          color: $charcoal-dark;
          font-weight: 600;
        }

        &__date {
          color: $medium-gray;
          font-size: rem-calc(14);
        }
      }
    }

    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
    }

    @include breakpoint(laptop) {
      .blog-article-card__body {
        p {
          height: auto;
        }
      }
    }
  }
}

.post-details__author {
  padding: 1rem 0;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
  align-items: center;

  &-information {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: rem-calc(14);
    font-weight: 600;
  }

  &__name {
    font-size: rem-calc(14);
    margin-top: rem-calc(5);
  }

  &__image {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }
}
