.reason-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: rem-calc(425);

  @include breakpoint(laptop) {
    height: rem-calc(550);
  }

  &--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &--text {
    padding: 1rem 1.5rem 0 1.5rem;
    text-align: center;
    @include breakpoint(laptop) {
      padding: 3rem 3rem 0 3rem;
      max-width: 35rem;
      height: 9.5rem;
    }
  }

  &--image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center/contain no-repeat;
  }

  &--bronze-light {
    .reason-tile--image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 110%;
      background-position-x: rem-calc(-48px);
      background-position-y: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      @include breakpoint(tablet) {
        left: rem-calc(-48px);
        height: 100%;
        background-position-x: 0;
      }
      @include breakpoint(laptop) {
        left: rem-calc(-98px);
      }
      @include breakpoint(desktop) {
        left: rem-calc(-22px);
      }
    }
  }

  h4 {
    font-family: $global-font-family;
    font-weight: 600;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  $colors: (
    charcoal-dark: (
      bg: $charcoal-dark,
      fg: $white,
    ),
    charcoal-medium: (
      bg: $charcoal-medium,
      fg: $charcoal-dark,
    ),
    charcoal-light: (
      bg: $charcoal-light,
      fg: $charcoal-dark,
    ),
    charcoal-pattern: (
      bg: $charcoal-pattern,
      fg: $charcoal-dark,
    ),
    copper-dark: (
      bg: $copper-dark,
      fg: $charcoal-dark,
    ),
    copper-medium: (
      bg: $copper-medium,
      fg: $charcoal-dark,
    ),
    copper-light: (
      bg: $copper-light,
      fg: $charcoal-dark,
    ),
    copper-pattern: (
      bg: $copper-pattern,
      fg: $charcoal-dark,
    ),
    golden-dark: (
      bg: $golden-dark,
      fg: $charcoal-dark,
    ),
    golden-medium: (
      bg: $golden-medium,
      fg: $charcoal-dark,
    ),
    golden-light: (
      bg: $golden-light,
      fg: $charcoal-dark,
    ),
    golden-pattern: (
      bg: $golden-pattern,
      fg: $charcoal-dark,
    ),
    ivory-dark: (
      bg: $ivory-dark,
      fg: $charcoal-dark,
    ),
    ivory-medium: (
      bg: $ivory-medium,
      fg: $charcoal-light,
    ),
    ivory-light: (
      bg: $ivory-light,
      fg: $charcoal-dark,
    ),
    ivory-pattern: (
      bg: $ivory-pattern,
      fg: $charcoal-dark,
    ),
    bronze-dark: (
      bg: $bronze-dark,
      fg: $charcoal-dark,
    ),
    bronze-medium: (
      bg: $bronze-medium,
      fg: $charcoal-dark,
    ),
    bronze-light: (
      bg: $bronze-light,
      fg: $charcoal-dark,
    ),
    bronze-pattern: (
      bg: $bronze-pattern,
      fg: $charcoal-dark,
    ),
    rose-dark: (
      bg: $rose-dark,
      fg: $charcoal-dark,
    ),
    rose-medium: (
      bg: $rose-medium,
      fg: $charcoal-dark,
    ),
    rose-light: (
      bg: $rose-light,
      fg: $charcoal-dark,
    ),
    rose-pattern: (
      bg: $rose-pattern,
      fg: $charcoal-dark,
    ),
  );

  @each $key in map-keys($colors) {
    &--#{$key} {
      p,
      h4 {
        color: map-get(map-get($colors, $key), 'fg');
      }

      background: map-get(map-get($colors, $key), 'bg');
    }
  }
}
