ul {
  &.regular {
    list-style-type: none;

    li a {
      display: block;
      font-size: rem-calc(12);
      letter-spacing: 0.4px;
      text-transform: uppercase;
      padding: rem-calc(8 0 8 8);
      color: $medium-gray;
      font-weight: bold;
      text-decoration: none;
      border-left: 2px solid $light-gray;
      letter-spacing: 0.05em;

      &:hover {
        cursor: pointer;
        color: $golden-highlight;
      }

      &.active {
        border-left: 2px solid $golden-dark;
        color: $golden-highlight;
      }
    }
  }
  &.unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
    &--dark {
      color: $charcoal-dark;
    }
  }
}

dl {
  &.post-details {
    dt {
      font-size: rem-calc(14);
      font-weight: 600;
    }
    dd {
      font-size: rem-calc(16);
      margin: 0;
      padding: rem-calc(7) 0;

      &:last-child {
        border-bottom-width: 0;
        padding-left: rem-calc(5px);
      }
    }
  }
}
.horizontal {
  display: inline;
  padding: 0;
  margin-left: -5px;

  li {
    display: inline;
    img {
      width: 1.5rem;
    }
  }
}
