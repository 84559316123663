.lightbox {
  background-color: $light-gray;
  max-height: rem-calc(470);
  min-height: rem-calc(200);
  display: flex;
  flex-direction: column;
  span {
    display: block;
    padding: 0.5rem;
  }

  video {
    cursor: pointer;
  }

  button {
    border: none;
    padding: 0;
    background-color: none;
    margin: 0;

    @supports (aspect-ratio: 16 /9) {
      aspect-ratio: 16/9;
      img {
        height: auto;
        max-width: 100%;
        aspect-ratio: 16/9;
        object-fit: contain;
      }
    }

    @supports not (aspect-ratio: 16 /9) {
      img {
        max-height: rem-calc(180px);
        width: auto;
        @include breakpoint(tablet) {
          max-height: rem-calc(432px);
        }
      }
    }
  }
  &--radius {
    border: 1px solid transparent;
    border-radius: 13px;
  }
  &--transparent {
    background-color: transparent;
  }

  &--auto-height {
    max-height: 100%;
    height: auto;
  }
}
