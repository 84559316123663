.homepage-search {
  background-color: $white;
  padding: 1rem;
  border-radius: rem-calc(13px);
  @include breakpoint(laptop) {
    border-radius: rem-calc(40px);
    padding: 0.25rem 1rem;
  }
  .row {
    margin: 0;
  }
  .search-widget__navigation {
    justify-content: space-between;
  }
  .search-widget__form {
    flex: 1 1 65%;
    &:last-child > div:last-child {
      padding-right: 0;
    }
  }
  .search-widget__results--empty p {
    color: $charcoal-dark;
  }
}
