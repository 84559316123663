//benefits table
.benefits {
  h2 {
    text-align: center;
  }

  &__cost {
    text-transform: uppercase;
    font-weight: 900;
  }

  &__table {
    max-width: 100%;
    text-align: left;
    margin: 0 1rem;

    @include breakpoint(laptop) {
      width: 100%;
      margin: auto;
    }

    &--row {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      flex: auto;
      border-top: 1px solid $golden-highlight;
      padding: 1rem;
      font-weight: 700;
      font-size: rem-calc(20);
      &:first-child {
        color: $rose-medium;
      }

      &:last-child {
        border-bottom: 1px solid $golden-highlight;
      }

      div:first-child() {
        flex: auto;
      }
    }

    &--other {
      padding: 1rem 1rem 1.5rem 1rem;
      display: flex;
      flex-wrap: wrap;
      width: auto;
      text-align: left;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: rem-calc(24);
      margin: 0 1rem 1rem 1rem;
      max-width: 100%;
      @include breakpoint(laptop) {
        margin-left: 0;
        margin-right: 0;
      }
      .benefit {
        margin-top: 1rem;
        display: flex;
        align-items: center;
      }

      img {
        vertical-align: middle;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  &__footer {
    color: $charcoal-highlight;
  }
}
