.calendar {
  --height: 1;

  &__wrapper {
    height: calc(100vh - 2rem);

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        min-height: -webkit-fill-available;
        height: -webkit-fill-available;
      }
    }
    @include breakpoint(tablet) {
      height: unset;
      overflow: auto;
      .calendar__grid {
        height: unset;
      }
    }
  }

  &__grid {
    height: calc(100vh - 1rem);
    overflow: scroll;
  }

  &__form {
    transition: margin-top 0.3s ease-out;
    margin-top: 0;
    background-color: $white;
    z-index: 1;
    &--open {
      margin-top: -96vh;
      transition: margin-top 0.3s ease-out;
    }

    @include breakpoint(tablet) {
      z-index: 0;
      &--open {
        margin-top: 0;
      }
    }
  }

  &__question {
    .field.field--inline {
      justify-content: flex-start;
      .radio {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }
}

.fc-event.event {
  &--fullday {
    height: calc(var(--height) / 8);
    border-color: $brand-white;
    border-radius: 0.25rem !important;
    background-color: $white;
    background-image: radial-gradient(
      $brand-white-dark 0.25px,
      $brand-white 0.25px
    );
    background-size: 5px 5px;
    .fc-content {
      color: $brand-charcoal;
      .fc-title {
        color: $brand-charcoal;
      }
    }
  }

  &--available {
    height: calc(var(--height) / 8);
    border-color: $brand-white;
    border-radius: 0.25rem !important;
    background-color: white;
    background-size: 5px 5px;
    .fc-event-title {
      color: $brand-charcoal;
    }
  }
}

.adhoc-available {
  color: black;
  .fc-daygrid-day-frame {
    background-color: white;
  }
  .fc-daygrid-event-harness {
    // background-color: white;
    z-index: 2;
    height: 100%;
  }
}
