// Grayscale
$red-light: #f6dde4;
$red: #f62459;
$red-highlight: #69172c;
$green-light: #e7fff3;
$green: #3ef3ad;
$green-highlight: #236c46;
$green-middle: #5ad3a4;

:export {
  red-light: $red-light;
  red: $red;
  red-highlight: $red-highlight;
  green-light: $green-light;
  green: $green;
  green-highlight: $green-highlight;
}
