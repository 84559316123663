.qualification-item {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  @include breakpoint(tablet) {
    margin-top: 0;
  }

  &__image {
    width: 2rem;
    height: 2rem;
    background-color: $white;
    font-family: $global-font-family;
    border-radius: 100%;
    margin-right: 0.5rem;

    img {
      width: 3.5rem;
      display: block;

      @supports (aspect-ratio: 1/1) {
        aspect-ratio: 1/1;
      }

      @supports not (aspect-ratio: 1/1) {
        height: 100%;
      }
    }

    &--generic {
      display: flex;
      justify-content: center;
      padding: 0.25rem;
      background-color: $charcoal-pattern;
    }
  }

  &__details {
    align-self: center;

    span {
      display: block;
      font-size: 1rem;
      text-align: left;
    }
  }
}
