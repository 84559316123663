.address-lookup {
  position: relative;
  z-index: 20;
  border-bottom: 1px solid $charcoal-medium;
  padding: rem-calc(16 0);
  text-align: center;

  &__search {
    position: relative;
    margin: rem-calc(16 0);
    background: $white;
    border: 1px solid $charcoal-medium;
    box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
      0 5px 15px 0 rgba(40, 39, 39, 0.05);
    border-radius: 13px;
    flex: 2;
    order: 2;

    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
      margin: rem-calc(0 16);
    }

    div {
      width: 100%;
    }

    input {
      font-family: $global-font-family;
      flex: 1;
      height: rem-calc(44);
      border: none;
      outline: none;
      padding: rem-calc(12 12 12 40);
      width: 100%;

      &.search {
        background: url('/static/img/icons/golden-dark/search.svg') rem-calc(12)
          50% / auto 16px no-repeat;
      }
    }

    p {
      margin-left: rem-calc(40);

      &:hover {
        cursor: pointer;
        background: $rose-medium;
        color: $rose-highlight;

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: rem-calc(44);
    height: rem-calc(44);
    background: url('/static/img/icons/golden-dark/arrow-right.svg') center /
      auto 16px no-repeat;
    outline: none;
    border: none;

    @include breakpoint(tablet) {
      top: 0;
      right: 0;
      bottom: auto;
    }
  }
}
