.testimonial-card {
  display: flex;
  border-radius: 13px;
  border: 1px solid $light-gray;
  background-color: $white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);
  flex-direction: column;
  height: rem-calc(200);

  &__image {
    overflow: hidden;
    padding: 0.75rem;
    background-color: $light-gray;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  &__content {
    height: rem-calc(108px);
    padding: 0.75rem;
    overflow: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    &--text {
      overflow: auto;
    }
    p {
      font-size: rem-calc(14);
      margin-bottom: rem-calc(4px);
    }
    &--customer {
      align-self: center;
    }
  }
  span {
    color: $golden-highlight;
    font-size: rem-calc(16);
  }

  @include breakpoint(tablet) {
    height: rem-calc(131px);
    flex-direction: row;

    &__content {
      padding: 0.75rem;
      height: 100%;
      flex-basis: calc(100% - 131px);
    }
    &__image {
      padding: 0;
      border-bottom-right-radius: 13px;
      border-top-right-radius: 13px;
      border-bottom-left-radius: 0;
      max-width: rem-calc(131);
    }
    span {
      color: $bronze-medium;
    }
  }
}
button.no-style {
  border: 0;
  padding: 0;
}
