.record-grid {
  display: grid;
  grid-template: [row1-start] 'top' auto [row1-end] [row2-start] 'notes' auto [row2-end] [row3-start] 'bottom-left' auto [row3-end] [row4-start] 'bottom-left' auto [row4-end] / auto;
  gap: 1rem;

  &--standalone {
    grid-template-columns: auto;
  }

  &--gap {
    gap: 0;
  }

  @include breakpoint(tablet) {
    grid-template: [row1-start] 'top notes' auto [row1-end] [row2-start] 'bottom-left notes' auto [row2-end] [row3-start] 'bottom-left bottom-right' auto [row3-end] / 50% 50%;

    &--tall {
      grid-template: [row1-start] 'top notes' 6rem [row1-end] [row2-start] 'bottom-left notes' auto [row2-end] [row3-start] 'bottom-left bottom-right' auto [row3-end] / 50% 50%;
    }

    &--forms {
      grid-template: [row1-start] 'top forms' auto [row1-end] [row2-start] 'bottom-left forms' auto [row2-end] / 1fr 1fr;

      &.record-grid--standalone {
        grid-template-columns: 2fr 5fr;
      }
    }
  }

  @include breakpoint(laptop) {
    grid-template-columns: 1fr 1fr;

    &--tall {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include breakpoint(desktop) {
    grid-template-columns: 1fr 1fr;

    &--tall {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__top {
    @include breakpoint(tablet) {
      grid-row: row1-start/row1-end;
      grid-column: top-start/top-end;

      &--end {
        grid-row: row1-start/row3-end;
      }
    }
  }

  &__notes {
    @include breakpoint(tablet) {
      grid-row: row1-start/row2-end;
      grid-column: notes-start/notes-end;
    }
  }

  &__forms {
    @include breakpoint(tablet) {
      grid-row: row1-start/row2-end;
      grid-column: forms-start/forms-end;
    }
  }

  &__bottom-left {
    @include breakpoint(tablet) {
      grid-row: row2-start/row3-end;
      grid-column: bottom-left-start/bottom-left-end;
    }

    &--capped {
      @include breakpoint(tablet) {
        grid-row: row2-start/row2-end;
        grid-column: bottom-left-start/bottom-left-end;
      }
    }
  }

  &__bottom-right {
    @include breakpoint(tablet) {
      grid-row: row3-start/row4-end;
      grid-column: bottom-right-start/bottom-right-end;
    }
  }
}
