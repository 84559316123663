.widget {
  border-radius: 13px;
  background-color: $white;
  position: relative;
  font-size: rem-calc(14);
  margin: 1rem auto;
  width: 100%;
  padding: 1rem;

  strong {
    color: $charcoal-dark;
  }

  &--centered {
    margin: auto;
  }

  @include breakpoint(tablet) {
    padding: 0;
  }

  .source-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;

    img {
      margin: 0.5rem auto;
      display: block;
      max-width: 2rem;
    }

    &-customer {
      background-color: $golden-medium;
    }

    &-practitioner {
      background-color: $ivory-medium;

      > img {
        margin: 0.65rem auto;
      }
    }
  }

  @include breakpoint(tablet) {
    &.modal__dialog {
      padding: 0;

      .button--modal__close {
        width: 3rem;
        height: 3rem;
      }

      .button--modal__back {
        width: 3rem;
        height: 3rem;

        img {
          padding: 0.25rem;
        }
      }
    }
  }

  a:not(.button, .golden) {
    color: $charcoal-dark;

    &:hover {
      color: $charcoal-dark;
    }
  }

  .price {
    font-weight: 700;
    font-size: rem-calc(40);
    color: $charcoal-highlight;

    p {
      font-size: 1.25rem;
    }
  }

  &__contact {
    text-align: center;
    grid-column: col / span 2;
    margin-top: 0.5em;
  }

  .datepicker__slots {
    max-height: 10rem;
    overflow-x: hidden;
    overflow-y: auto;

    @include breakpoint(laptop) {
      grid-template-columns: repeat(7, 1fr);
      overflow: inherit;
      max-height: inherit;
    }
  }
}

.consultation-tab {
  @include breakpoint(tablet) {
    .button-group .button {
      width: 100%;
    }
  }
}
