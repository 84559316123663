.booking-card {
  border-radius: 13px;
  padding: 1rem;
  transition: 0.4s all;
  position: relative;
  max-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: rem-calc(22);
  }

  &__customer {
    color: $white;
    width: 100%;
    > * {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    h3 {
      font-size: rem-calc(14);

      @include breakpoint(laptop) {
        max-width: 15rem;
      }
    }

    a {
      font-size: rem-calc(14);
      color: $white;
      text-decoration: none;
      transition: 0.4s all ease-out;

      &:hover {
        transition: 0.4s all ease-in;
        color: darken($white, 15%);
      }
    }
  }

  &__treatment {
    padding: 1rem 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    li {
      color: $white;
      text-transform: capitalize;
      font-size: rem-calc(14);
    }

    @include breakpoint(laptop) {
      width: 100%;
      height: 4.5rem;
      margin-bottom: 0.5rem;
    }

    .appointment-description[title] {
      text-decoration: none;
    }
  }

  &__location {
    padding: 0.5rem 0;
    height: 4rem;
  }

  &__date {
    font-size: rem-calc(14px);
    text-transform: capitalize;
    color: $white;
    display: block;
    margin-top: 0.5rem;

    @include breakpoint(desktop) {
      font-size: rem-calc(12px);
      position: absolute;
      top: 1rem;
      right: 1rem;
      margin-top: 0;
    }
  }

  &__action {
    position: relative;
    height: 2rem;

    span {
      font-size: rem-calc(12);
    }
  }
  &.unactioned {
    background: $rose-highlight;
    transition: 0.4s all;
    color: $white;
    &:hover {
      background-color: darken($rose-highlight, 5);
      transition: 0.4s all;
    }
    &:focus,
    &:active {
      background-color: darken($rose-highlight, 15);
      transition: 0.4s all;
    }
  }

  &.pending {
    background-color: $rose-medium;
    transition: 0.4s all;
    color: $white;
    &:hover {
      background-color: $rose-dark;
      transition: 0.4s all;
    }
    &:focus,
    &:active {
      background-color: $rose-highlight;
      transition: 0.4s all;
    }
  }
}
