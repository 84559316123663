.treatment-card {
  height: 9rem;
  z-index: 0;
  padding: 1.25rem;

  &__wrapper {
    .tile-slanted {
      margin-bottom: 0;
      height: 9rem;
    }
    margin-bottom: 2rem;

    &--accessibility-tooltip {
      position: absolute;
      bottom: 0.5rem;
      right: 1.25rem;
      .accessible-tooltip {
        width: 1.5rem;
        height: 1.5rem;
      }
      .tooltip-text {
        left: -6rem;
        width: 15rem;
        p,
        span,
        ul {
          font-size: rem-calc(12);
          color: $white;
          display: block;
        }
        p {
          margin-bottom: 0.25rem;
        }
        ul {
          padding-left: rem-calc(12);
          margin-bottom: 0.25rem;
        }
        @include breakpoint(tablet) {
          left: -3rem;
          width: 10rem;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    padding-bottom: 100%;
    padding: inherit;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    justify-content: space-between;

    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: rem-calc(16);
    }
  }

  &__title {
    font-family: $global-font-family;
    font-weight: 500;
    color: $golden-highlight;
    text-transform: uppercase;
    font-size: rem-calc(12);
    line-height: 1.25;
    width: 100%;
    margin-bottom: rem-calc(4);
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &--treatment {
      font-family: $global-font-family;
      font-weight: 500;
      color: $golden-highlight;
      text-transform: uppercase;
      line-height: 1.25;
      font-size: rem-calc(12);
      width: 80%;
      margin-bottom: rem-calc(4);
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include breakpoint(tablet) {
        font-size: rem-calc(10);
      }
      @include breakpoint(laptop) {
        font-size: rem-calc(12);
      }
    }
  }

  &__description {
    font-size: rem-calc(10);
    font-weight: 500;
  }

  &__button {
    position: absolute;
    -webkit-appearance: none;
    top: rem-calc(0);
    right: rem-calc(0);
    padding: 0;
    outline: none;
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    background: url('/static/img/icons/select-item.svg') center / cover
      no-repeat;
    border-radius: 100%;
    cursor: pointer;

    @include breakpoint(tablet) {
      top: rem-calc(16);
      right: rem-calc(16);
    }

    &--delete {
      background: $red url('/static/img/icons/white/trash.svg') center / 50%
        auto no-repeat;
    }
  }

  &__from {
    font-family: $global-font-family;
    font-weight: 500;
    color: $medium-gray;
    text-transform: uppercase;
    font-size: rem-calc(10);
    line-height: 1.25;
    width: 75%;
    margin-bottom: rem-calc(4);
    display: block;
  }

  &__price {
    display: block;
    font-size: rem-calc(18);
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 0.9;

    &--above {
      margin-bottom: 0.5rem;
    }
  }

  &__currency {
    font-size: rem-calc(18);
    vertical-align: top;
    font-weight: 500;
  }

  &__duration {
    border-radius: 13px;
    display: inline-block;
    padding: rem-calc(4 8);
    background: $green;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: $green-highlight;
    font-size: rem-calc(12);
    font-weight: 500;
  }

  .card-tooltip {
    display: none;
    @include breakpoint(laptop) {
      position: absolute;
      bottom: rem-calc(18);
      right: rem-calc(16);
      display: block;
    }
  }

  &--selected {
    border-color: $green;

    &::before {
      background: $green-light;
    }

    .treatment-card__button {
      background: $green url('/static/img/icons/approve.svg') center / cover
        no-repeat;
    }
  }
}
