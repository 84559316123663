details {
  * {
    box-sizing: border-box;
  }

  summary {
    list-style: none;
    position: relative;
    -webkit-appearance: none;

    &::after {
      position: absolute;
      content: '';
      background: $white url('/static/img/icons/charcoal-dark/arrow-down.svg')
        center / cover no-repeat;
      background-size: 16px 16px;
      width: 2.25rem;
      height: 2.25rem;
      right: 1rem;
      top: 1rem;
      border-radius: 100%;
      transform: rotate(0deg);
      transition: all 0.4s ease-in-out;
    }
  }

  &[open] {
    & > summary::after {
      transform: rotate(-180deg);
      transition: all 0.4s ease-in-out;
    }
  }
  & > summary::-webkit-details-marker,
  & > summary::marker {
    display: none;
  }
}

.testimonial {
  border-radius: 13px;
  background-color: $brand-white;
  border: 1px solid $brand-white;

  summary {
    &:focus {
      outline: 1px dotted $golden-medium;
    }
  }

  .testimony--readonly {
    .richTextEditor {
      p {
        color: $brand-charcoal-light;
      }
    }
  }

  &__form {
    overflow: hidden;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border: 1px solid $light-gray;

    .field div[role='textbox'] {
      border-color: $brand-white-dark;
      &:disabled {
        color: $brand-white-dark;
      }
    }

    div[role='textbox'] {
      height: rem-calc(188) !important;
      resize: none;
      .__placeholder::before {
        content: 'A great description can make all the difference!';
      }
    }

    &--background {
      background-color: $light-gray;
    }

    &-section {
      background-color: $white;

      &:first-of-type {
        background-color: $light-gray;
      }
    }

    &--overflow {
      overflow: initial;
    }
  }

  .image-uploader {
    width: 100%;
    padding-top: 100%;
    height: 0px;
  }

  @include breakpoint(tablet) {
    &__form {
      background-color: $white;

      &-section {
        &:first-of-type {
          background-color: $white;
        }
      }
    }
  }
}
