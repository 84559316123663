table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;

  td {
    padding: rem-calc(8);
    vertical-align: text-top;
    white-space: nowrap;
  }

  tr {
    &:last-child {
      border-bottom: 0;
    }
  }

  thead {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: rem-calc(14);
    border-bottom: rem-calc(8) solid $white;
  }

  tbody {
    tr {
      td {
        padding: 1rem;
        margin-top: rem-calc(4);

        img {
          width: 7rem;
          vertical-align: middle;
        }
      }
    }
  }
}
.table {
  &__responsive {
    table-layout: fixed;
    overflow-x: auto;
    display: block;
    width: 100%;
  }

  text-align: left;
  border-collapse: collapse;
  border: none;
  @include breakpoint(laptop) {
    display: table-cell;
  }

  thead {
    tr,
    th {
      padding: 1rem;
    }
    border-bottom: none;
    background-color: $light-gray;
  }
  tbody {
    tr {
      &:first-child() {
        border-top: 1px solid $light-gray;
      }
    }
  }

  tr,
  td {
    border-bottom: 1px solid $light-gray;
  }
}
