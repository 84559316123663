.slat {
  background: $white;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);
  border: 1px solid $brand-white;
  border-radius: 13px;
  padding: rem-calc(24);
  margin-bottom: rem-calc(16);
  flex-direction: column;

  &__location {
    overflow-x: auto;
    h4 {
      font-size: rem-calc(12);
    }
    @include breakpoint(tablet) {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
    }
    .checkbox {
      margin: 0.5rem 0;
      @include breakpoint(tablet) {
        margin: 0 0.5rem 0 0;
      }
    }
  }
  &--no-shadow {
    @include breakpoint(laptop) {
      box-shadow: none;
      border-bottom: 1px solid $light-gray;
      border-radius: 0;
      margin-bottom: 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &--aligned {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint(laptop) {
      justify-content: flex-end;
    }
  }

  @include breakpoint(laptop) {
    display: flex;
    padding: rem-calc(16);
  }

  form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;

    .field {
      margin-right: 1%;
      flex-grow: 2;
      @include breakpoint(laptop) {
        height: 4rem;
      }

      input {
        height: rem-calc(50);
      }

      &:last-child {
        margin-top: rem-calc(10);

        @include breakpoint(laptop) {
          margin-right: 0;
          margin-top: rem-calc(50);
        }
      }

      &:last-of-type {
        flex-grow: 1;
      }
    }

    @include breakpoint(laptop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__status {
    width: 5.5em;
    p {
      font-size: 0.75rem;
    }

    &--auto-width {
      width: auto;
    }
  }

  &__accepted {
    .slat__icon {
      color: $charcoal-dark;
      background-color: $green;
    }
  }

  &__revoked {
    .slat__icon {
      color: $brand-charcoal-dark;
      background-color: $brand-white-dark;
    }
  }

  &__icon {
    display: inline-block;
    font-family: $header-font-family;
    font-size: rem-calc(24);
    font-weight: 700;
    width: rem-calc(48);
    height: rem-calc(48);
    text-align: center;
    line-height: rem-calc(48);
    color: $rose-dark;
    background: $rose-light;
    border-radius: 100%;
    vertical-align: super;
  }

  &__details {
    margin: rem-calc(16 0);

    @include breakpoint(laptop) {
      margin: rem-calc(0 0 0 24);
    }

    &--info {
      display: inline-block;

      @include breakpoint(tablet) {
        margin-left: 1rem;
      }
    }

    h2 {
      font-family: $global-font-family;
      font-size: rem-calc(16);
      font-weight: 700;
      margin: 0;
    }
  }

  &__confirm {
    flex-direction: row;
    display: flex;

    button {
      margin-left: 1em;
      width: 5em;
      height: 4em;
    }
  }

  &__actions {
    margin-top: 1rem;

    button {
      cursor: pointer;
      border: none;
      padding: 0;
      margin: 0;
      background-color: $white;
      font-size: rem-calc(14);
      text-align: left;
      width: 5rem;
      color: $charcoal-dark;
    }

    .icon-filled {
      width: 1.65rem;
      height: 1.65rem;
      padding: rem-calc(5);
      box-shadow: none;
      vertical-align: middle;
    }

    @include breakpoint(laptop) {
      width: 5rem;
      margin-top: 0;

      button {
        font-size: rem-calc(12);
      }

      .icon-filled {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &--practitioner {
    display: flex;
    flex-direction: column;

    @include breakpoint(laptop) {
      flex-direction: row;
      align-items: center;
    }

    .slat__details {
      overflow-x: auto;
      overflow-y: hidden;
      display: block;

      @include breakpoint(laptop) {
        margin-left: 0;
        width: 100%;
        padding: 0.5rem 0;
      }

      input {
        min-width: rem-calc(100);
        font-size: rem-calc(14);
        width: 100%;
        border-radius: 13px;
        padding: rem-calc(5 10);
        color: $charcoal-dark;

        @include breakpoint(laptop) {
          font-size: rem-calc(14);
        }

        &:focus {
          border-color: $golden-dark;
          outline: none;
        }
      }

      h2 {
        font-size: rem-calc(14);
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      a {
        color: $medium-gray;
        font-weight: 500;
        text-decoration: none;
        @include breakpoint(tablet) {
          max-width: 30ch;
          display: flex;
          overflow-x: auto;
          overflow-y: initial;
          height: 4ch;
        }
      }
    }
  }

  &__admin {
    h4 {
      font-size: rem-calc(14);
    }
    .checkbox {
      input {
        width: auto;
      }
    }
  }

  &--grid {
    display: grid;
    grid-template: [row1-start] 'details' 1fr [row1-end] [row2-start] 'status' 0.5fr [row2-end] / 1fr;

    @include breakpoint(laptop) {
      grid-template: [row1-start] 'details status' 1fr [row1-end] / 7fr 5fr;
    }
  }

  &--location {
    display: grid;
    grid-template: [row1-start] 'details' 2fr [row1-end] [row2-start] 'location' auto [row2-end] [row3-start] 'status' 1fr [row3-end] / 1fr;

    @include breakpoint(laptop) {
      grid-template: [row1-start] 'details location status' 1fr [row1-end] / 4fr 6fr 2fr;
      column-gap: 0.5rem;
    }
  }

  &--edit {
    flex-direction: column;
    align-items: flex-start;

    .slat__details {
      margin-left: 0;
      width: 100%;

      input {
        width: 100%;
      }
    }

    .slat__actions {
      width: 100%;
      text-align: right;
      margin-right: 0.5rem;

      button {
        text-align: right;
      }
    }

    .slat__icon {
      margin: 1rem 0.5rem;
    }
  }
}
