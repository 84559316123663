.account {
  background-color: $brand-white;
  padding: 0.5rem;

  h2 {
    font-weight: 600;
    font-family: $global-font-family;
    font-size: rem-calc(18);
  }

  a {
    text-decoration: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  @include breakpoint(tablet) {
    border-radius: 13px;
  }

  .panel {
    margin-top: 0;
  }

  &__mobile {
    &--button {
      width: 100%;
      background-color: $brand-white;
      border: none;
      text-align: left;
      padding: 0.5rem 0.5rem 0.5rem 2.5rem;
      text-decoration: none;
      font-weight: 600;
      position: relative;
      color: $charcoal-dark;

      &::after {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: 1.5rem;
        z-index: 1;
        background: url('/static/img/icons/charcoal-dark/arrow-down.svg') center /
          cover;
        transform: rotate(-90deg);
        transition: all 0.4s ease-in-out;
      }

      &__clicked::after {
        transform: rotate(0deg);
        transition: all 0.4s ease-in-out;
      }

      &:focus {
        outline: 1px dotted $light-gray;
      }
    }
  }

  &__accordion {
    .accordion__header {
      position: relative;
      padding-left: 3rem;

      &::before {
        content: '';
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        width: 1.75rem;
        height: 1.75rem;
        transition: all 0.4s;
      }
    }

    &--practitioner {
      .accordion__header::before {
        background: url('/static/img/icons/charcoal-dark/my-account.svg') center /
          cover;
      }
    }

    .accordion__body.open {
      height: 14rem;
    }

    &--clinic {
      .accordion__header::before {
        background: url('/static/img/icons/charcoal-dark/edit.svg') center /
          cover;
      }
    }

    &--team {
      .accordion__quick-links .open {
        height: rem-calc(200);
        border-top: 0;
      }

      .practitioners__list--item {
        justify-content: flex-start;
      }

      .practitioners__list--icon a {
        font-size: rem-calc(12);
      }

      .accordion__animation--closed {
        .accordion__header {
          span {
            visibility: visible;
          }
        }
      }

      .accordion__header::before {
        background: url('/static/img/icons/practitioner.svg') no-repeat center /
          cover;
      }
    }

    &--treatment {
      .accordion__header::before {
        background: url('/static/img/icons/charcoal-dark/treatments.svg')
          no-repeat;
      }
    }

    &--payments {
      .accordion__header::before {
        background: url('/static/img/icons/charcoal-dark/payments.svg')
          no-repeat center / cover;
      }
    }

    &--patients {
      .accordion__header::before {
        background: url('/static/img/icons/patients.svg') no-repeat center /
          cover;
      }
    }

    &--communication {
      .accordion__header {
        &::before {
          background: url('/static/img/icons/email.svg') no-repeat center /
            cover;
          background-size: rem-calc(18px);
        }
        & > span {
          padding-right: 1.5rem;
        }
      }
    }
  }

  &__quick-links {
    padding: 0.25rem 0;

    @include breakpoint(tablet) {
      margin-bottom: rem-calc(2);
    }

    a,
    & > button {
      font-size: 0.75rem;
      font-weight: 400;
      color: $brand-gold;
      padding: 0.25rem 0.75rem 0.25rem 0;
      text-decoration: none;
    }

    &--view {
      a {
        position: relative;
        display: block;

        &::after {
          content: '';
          position: absolute;
          width: 1rem;
          height: 1rem;
          right: -0.15rem;
          z-index: 1;
          background: url('/static/img/icons/golden-medium/arrow-down.svg')
            center / cover;
          transform: rotate(-90deg);
        }
      }
    }

    &--edit {
      a {
        position: relative;
        display: block;
        margin-bottom: rem-calc(2);

        &::after {
          content: '';
          position: absolute;
          width: 1rem;
          height: 1rem;
          right: -0.15rem;
          z-index: 1;
          background: url('/static/img/icons/golden-medium/edit.svg') center /
            cover;
        }
      }
    }
  }
}
