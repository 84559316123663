.error {
  color: $red;
  font-weight: 600;

  &:hover {
    color: $rose-highlight;
  }

  a {
    color: $red;
  }

  &--before-after-img {
    position: absolute;
    top: 25%;
    left: 20%;
  }
}
