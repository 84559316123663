.facial-markup {
  background-color: $charcoal-dark;
  min-height: 100vh;

  // for safari not taking url bar into account for 100vh
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      min-height: -webkit-fill-available;
    }
  }

  &__confirm-modal {
    max-width: 30rem;
  }

  &__editor {
    flex: 1;
    position: relative;
    > div {
      position: absolute;
    }
  }
}
