// Breakpoints

$laptopbreak: rem-calc(1024);
$tabletbreak: rem-calc(768);
$mobilebreak: rem-calc(320);
$desktopbreak: rem-calc(1440);
$largebreak: rem-calc(1660);

$mobilemediumheight: rem-calc(640);

$grid-break-point-mobile: $mobilebreak;
$grid-break-point-tablet: $tabletbreak;
$grid-break-point-laptop: $laptopbreak;
$grid-break-point-desktop: $desktopbreak;
$grid-break-point-large: $largebreak;

$grid-break-point-mobile-medium-height: $mobilemediumheight;

$breakpoints: (
  mobile-min: $mobilebreak,
  tablet-min: $tabletbreak,
  laptop-min: $laptopbreak,
  desktop-min: $desktopbreak,
  large-min: $largebreak,
  mobile-max: $mobilebreak - 1,
  tablet-max: $tabletbreak - 1,
  laptop-max: $laptopbreak - 1,
  desktop-max: $largebreak - 1,
);

:export {
  desktopbreak: $desktopbreak;
  tabletbreak: $tabletbreak;
  laptopbreak: $laptopbreak;
  mobilebreak: $mobilebreak;
  largebreak: $largebreak;
}
