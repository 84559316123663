.cancellation-card {
  background-color: $white;
  cursor: pointer;
  margin-bottom: rem-calc(32);
  border: 1px solid $charcoal-medium;
  height: 8.5rem;
  text-align: left;
  &:focus {
    outline: none;
    border: 1px solid $green;
  }
  @include breakpoint(tablet) {
    height: 9rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem;
    }
  }

  &__title {
    font-family: $global-font-family;
    font-weight: 500;
    text-transform: uppercase;
    font-size: rem-calc(12);
    line-height: 1.5;
    width: 75%;
    margin-bottom: rem-calc(4);
  }

  &__description {
    font-size: rem-calc(12);
    font-weight: 500;
  }

  &__rate {
    display: block;
    font-size: rem-calc(24);
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 0.9;

    @include breakpoint(tablet) {
      margin-top: auto;
    }
  }

  .tooltip {
    position: absolute;
    bottom: rem-calc(16);
    right: rem-calc(16);
  }

  &--selected {
    border-color: $green;

    .cancellation-card__title {
      color: $green-highlight;
    }

    &::before {
      background: $green-light;
    }
  }
}
