.treatment-plan-modal {
  height: auto;
}

.treatment-plan-payment {
  @include breakpoint(tablet) {
    .treatment-plan__header--title {
      margin-right: 1rem;
    }
  }
  @include breakpoint(laptop) {
    &.modal__dialog.widget {
      width: 40rem;
    }
  }
}

.treatment-plan {
  &__row {
    display: flex;
    margin-bottom: 0.25rem;

    span {
      font-size: rem-calc(11);
    }

    .action {
      justify-content: flex-end;
      display: flex;
    }

    div {
      border-right: 1px solid $light-gray;
    }

    .action {
      border-right: none;
      .round-button img {
        width: 1rem;
        height: 1rem;
        @include breakpoint(tablet) {
          height: auto;
        }
      }
    }

    @include breakpoint(tablet) {
      align-items: center;

      span {
        font-size: rem-calc(12);
      }

      div {
        border-right: none;
      }

      .action .round-button {
        padding: 0.5rem;
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
}

.treatment__wrapper {
  height: 6rem;
  overflow-y: auto;
  padding-right: 0.5rem;
}
