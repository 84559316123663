.section {
  @extend .padding-top-2,
    .padding-bottom-2,
    .padding-top__t-3,
    .padding-bottom__t-3;

  &__light-gray {
    background: $light-gray;
  }

  &--white {
    background-color: $brand-white;
  }

  &__back-to-top {
    background: url('/static/img/icons/arrow-up-black.svg') center / contain;
  }

  &--charcoal {
    background: $charcoal-dark;
    color: $white;

    &--back-to-top {
      background: url('/static/img/icons/arrow-up-white.svg') center / contain;
    }

    p,
    ol,
    ul {
      color: $white;

      a {
        color: $golden-medium;
      }
    }
    .blog-article-card__category-name {
      color: $light-gray;
    }
  }

  &--charcoal-original {
    background: $charcoal;
  }

  &--light {
    background-color: $white;
  }
}
