.discount-widget {
  padding-top: 0.5rem;

  .field {
    label {
      font-size: rem-calc(12);
      display: inline-block;
      vertical-align: middle;
    }

    input {
      font-size: rem-calc(12);
      margin-bottom: 0;
    }

    &--discount {
      input {
        background: url('/static/img/icons/cost.svg') rem-calc(12) 50% / auto
          16px no-repeat;
        padding-left: rem-calc(32);
      }
    }
  }

  @include breakpoint(tablet) {
    padding-top: 1rem;

    .field input {
      height: inherit;
      max-width: calc(100% - 0.5rem);
    }

    .button--small:not(.button--flat) {
      font-size: rem-calc(10px);
      padding: 0.5rem 0.75rem;
      margin-top: 0;
    }
  }

  &--top {
    padding-top: 0;
  }
}
