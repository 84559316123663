.consent-optin {
  background: $charcoal-medium;
  color: $charcoal-dark;
  padding: 0.5rem;

  &__text {
    color: $charcoal-dark;
  }

  &__checkbox {
    .checkbox h4 {
      color: $charcoal-dark;
    }

    &--disabled {
      .checkbox h4 {
        color: $charcoal-highlight;
      }
    }
  }
}
