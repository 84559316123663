.overflow-hidden {
  overflow: hidden;
  height: 100vh;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      min-height: -webkit-fill-available;
      height: -webkit-fill-available;
    }
  }
}

//screen reader only
.sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
  &--t {
    @include breakpoint(tablet) {
      position: absolute;
      height: 1px;
      width: 1px;
      clip: rect(1px 1px 1px 1px); // IE 6 and 7
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
      -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
      overflow: hidden !important;
    }
  }
}

.disabled {
  color: $charcoal-medium;
}

.approve {
  aspect-ratio: 1 / 1;
  max-width: 10rem;
}
.rotated {
  transform: rotate(90deg);
}
.tile-slanted {
  background: transparent;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);
  border-radius: 13px;
  margin-bottom: rem-calc(32);
  background: $white;
  overflow: hidden;
  position: relative;
  padding: rem-calc(15);
  height: 8rem;
  width: 100%;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 170%;
    height: 100%;
    background: $light-gray;
    transform: skew(-26deg) translateX(30%);
    transition: 0.4s all;
  }

  &:focus {
    border-color: $golden-dark;
    outline: none;
  }

  @include breakpoint(tablet) {
    &:hover {
      &::before {
        transform: skew(-26deg) translateX(15%);
      }
    }
  }
}

.round-image {
  border-radius: 100%;

  &--error {
    background-color: $red;
    padding: 0.75rem;
  }
}

.hide-on-mobile {
  display: none;

  @include breakpoint(tablet) {
    display: initial;
  }
}

.hide-on-tablet {
  @include breakpoint(tablet) {
    display: none;
  }

  @include breakpoint(laptop) {
    display: inherit;
  }
}

.hide-on-laptop {
  @include breakpoint(laptop) {
    display: none;
  }
}

.back-to-treatments {
  text-align: right;
  position: relative;
  height: 1rem;

  a {
    font-size: 0.75rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  img {
    width: 1rem;
    vertical-align: middle;
  }
}

.clickable {
  cursor: pointer;

  &:focus,
  &:active {
    outline: 1px dotted $golden-light;
    outline-offset: 0.25rem;
  }
}

.guide__row {
  &::before {
    content: ' ';
    display: block;
    padding-top: 10rem;
    margin-top: -5rem;
  }
}

.slick-track {
  &:focus {
    outline: none;
  }
}

.corner-padding {
  display: inline-block;
  align-self: flex-end;
  margin-bottom: 2rem;
}

.top-padding {
  display: inline-block;
  align-self: center;
}

.overflow-x {
  overflow-x: auto;
}

.filter-gradient {
  mask-image: linear-gradient(to right, $white 70%, transparent 100%);
}

.slant-side {
  position: relative;

  &::before {
    content: '';
    background-color: $charcoal-dark;
    transform: skew(-26deg) translateX(-42%);
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }

  80% {
    opacity: 70%;
  }

  100% {
    opacity: 0;
  }
}

.animation-fade-out {
  animation: fadeOut 3s ease-in;
  opacity: 0;
}

.break-word {
  word-break: break-all;
}

.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include breakpoint(tablet) {
    &--t {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.z-index {
  z-index: 10;
  @include breakpoint(laptop) {
    z-index: initial;
  }
}

.hide-on-print {
  @media print {
    display: none;
  }
}

@media print {
  input:disabled,
  textarea:disabled,
  select:disabled {
    color: $charcoal-dark !important;
  }
}
