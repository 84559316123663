.hamburger {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: rem-calc(44);
  height: rem-calc(44);
  z-index: 10;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: none;
  transition: transform 0.5s;
  padding: rem-calc(8);

  @include breakpoint(tablet) {
    margin-left: rem-calc(16);
    display: none;
  }

  &__line {
    content: '';
    position: absolute;
    width: rem-calc(28);
    height: 2px;
    background: $charcoal-dark;
    display: block;
    transition: 0.25s transform;
    top: 50%;
    margin-top: rem-calc(-1);

    &--white {
      background: $white;
    }

    &:nth-child(1) {
      transform: translateY(-8px);
    }

    &:nth-child(3) {
      transform: translateY(8px);
    }
  }

  &--open {
    .hamburger__line {
      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: scaleX(0);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
