/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &:hover {
    scrollbar-color: $charcoal-medium transparent;
  }
}
// DO NOT NEED THESE TO SCALE, SORRY FOR PX
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $charcoal-medium;
  border-radius: 8px;
  border: none;
}

.show-scrollbar-hover {
  @include breakpoint(laptop) {
    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $charcoal-medium !important;
      }
    }
  }
}

.disable-scrollbars {
  @include breakpoint(laptop) {
    scrollbar-width: none;
    overflow: auto;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* Chrome/Safari/Webkit */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }
}
