.header {
  &__wrapper {
    min-height: 5rem;
  }
  &__branding {
    img {
      width: 5rem;
      height: 5rem;
      object-fit: cover;
      display: block;
    }
  }

  .search__navigation {
    padding-top: 1rem;
    text-align: left;
    &.animation {
      margin-top: 0;
    }
    @include breakpoint(tablet) {
      margin-top: 0;
      overflow: inherit;
    }
    @include breakpoint(laptop) {
      padding-bottom: 1rem;
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

  @include breakpoint(tablet) {
    flex-direction: row;
    align-items: center;
  }

  &__top {
    padding: 1rem;
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: block;
    max-width: 10rem;
    height: auto;
    @include breakpoint(tablet) {
      max-width: rem-calc(200);
    }
  }

  &__links {
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    max-height: 0;
    position: absolute;
    z-index: 5;
    top: 4rem;
    background-color: $white;
    width: 100%;
    overflow: auto;
    &--open {
      padding: 1rem;
      max-height: rem-calc(450);
      box-shadow: 1px 6px 8px 0 rgba($charcoal-dark, 0.32);
      @include breakpoint(tablet) {
        box-shadow: none;
        position: static;
      }
    }
    &--lower {
      top: 7rem;
    }

    @include breakpoint(tablet) {
      position: static;
      max-height: inherit;
      overflow: inherit;

      .unstyled {
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;
      }
    }
  }

  &__link {
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-bottom: 1px solid $brand-white;

    a,
    button {
      text-transform: capitalize;
      padding: 0;
      text-decoration: none;
      color: $brand-gold-dark;
      font-size: 1.125rem;
      font-weight: 800;
      line-height: 1;
      border-radius: 0;
    }

    @include breakpoint(tablet) {
      border-bottom: 0;
      font-size: 1rem;
      margin: 0;

      a,
      button {
        cursor: pointer;
        color: $brand-charcoal-light;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: $charcoal-dark;

          @include breakpoint(desktop) {
            border-bottom: rem-calc(2) solid $golden-highlight;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.sub-nav {
  .nav__link:last-child {
    border-bottom: none;
  }
  @include breakpoint(tablet) {
    &__account {
      &.unstyled {
        padding: 0 0.75rem;
        .nav__link {
          margin: 0;
        }
      }
      margin: 0;
      display: none;
      position: absolute;
      background-color: $brand-white;
      border-radius: 13px;
      top: 2.5rem;
      left: -3vw;
      list-style: none;
      flex-direction: column;
      width: 14ch;
      height: 0;
      box-shadow: 1px 6px 8px 0 rgba($charcoal-dark, 0.32);
      overflow: hidden;
    }
    &:hover .sub-nav__account,
    &:focus-within .sub-nav__account,
    .sub-nav--open {
      display: flex;
      height: max-content;
      overflow: initial;
    }
    &__menu {
      @include breakpoint(tablet) {
        padding: 0.5rem 2rem 0.5rem 0.5rem;
        border-radius: 13px;
        position: relative;
        background-color: $brand-white;
        &::after {
          content: '';
          width: 0.75rem;
          height: rem-calc(40);
          background: url('/static/img/icons/chevron-right.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 0.75rem;
          top: 0;
          right: 0.75rem;
          transform: rotate((90deg));
          position: absolute;
        }
      }
    }
  }
}
