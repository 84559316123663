.before-after-card {
  position: relative;
  display: flex;
  &__wrapper {
    border-radius: 13px;
  }
  &__horizontal {
    flex-direction: column;
    overflow: hidden;

    &--image {
      padding-top: 50%;
      width: 100%;
      height: 0px;
      position: relative;
      flex-basis: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
      &:first-child {
        border-bottom: 1px solid $white;
        border-radius: 13px 13px 0 0;
        overflow: hidden;
      }
      &:last-child {
        border-top: 1px solid $white;
      }
    }
  }
  &--incomplete {
    border-radius: 13px;
    border: 3px solid $rose-highlight;
    @include css-shadow($rose-highlight, 0.75);
  }
  &__vertical {
    flex-direction: row;
    overflow: hidden;
    border-bottom: 2px solid $white;

    &--image {
      flex-basis: 50%;
      padding-top: 100%;
      width: 100%;
      height: 0px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
      &:first-child {
        border-right: 1px solid $white;
        border-top-left-radius: 13px;
        overflow: hidden;
      }
      &:last-child {
        border-left: 1px solid $white;
        border-top-right-radius: 13px;
        overflow: hidden;
      }
    }
  }
  &__caption {
    height: 5rem;
    background: $white;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    display: flex;
    padding: 0.5rem 1rem;
    &--name {
      flex-basis: 60%;
      padding-right: 0.5rem;
      align-self: center;
      overflow: hidden;
      .golden {
        color: $brand-gold;
      }
    }
    &--full {
      flex-basis: 100%;
      padding-top: 0.5rem;
      .golden {
        color: $brand-gold;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &--button {
      flex-basis: 40%;
      align-self: center;
    }
    h5 {
      font-size: rem-calc(12);
      margin-bottom: 0;
      font-weight: 500;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--horizontal {
      .before-after-card__caption--avatar {
        left: 1rem;
      }
    }
    &--avatar {
      position: absolute;
      left: calc(50% - 1.5rem);
      top: rem-calc(-55px);

      .avatar {
        width: 4rem;
        height: 4rem;
        box-shadow: none;
      }
    }
  }
}
