.patient-notes {
  max-height: 45rem;
  overflow-y: auto;
  &__full {
    max-height: 100%;
  }
}

.patient-treatment-note {
  background-color: $brand-white;
  &__time {
    color: $brand-white-dark;
  }

  &__textarea {
    width: 100%;
    min-height: 12rem;
    max-height: 24rem;
    resize: vertical;
  }

  &__text {
    min-height: 4rem;
    max-height: 12rem;
    overflow-y: auto;
    border-color: $brand-white-dark;
    max-width: 60ch;
  }

  &__content {
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__details {
    line-height: 1;
    color: $brand-charcoal;
  }

  &__confirm-delete-modal {
    max-width: 30rem;
  }
}
