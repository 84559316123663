// Charcoal
$charcoal-pattern: #f4f4f4;
$charcoal-light: #ffffff;
$charcoal-medium: #cac7c7;
$charcoal-dark: #1e1f1d;
$charcoal: #2b2b2b;
$charcoal-highlight: #606060;
$border-gray: #b9b9b9;

// Copper
$copper-pattern: #d8c6b4;
$copper-light: #9b693f;
$copper-medium: #76471e;
$copper-dark: #46281e;
$copper-highlight: #a16e23;

// Golden
$golden-pattern: #faf3d7;
$golden-light: #edd270;
$golden-medium: #c6a443;
$golden-dark: #9c7731;
$golden-highlight: #816910;
$golden-typography: #b59029;

// Ivory
$ivory-pattern: #ebe7e0;
$ivory-light: #f5e7d3;
$ivory-medium: #c5b196;
$ivory-dark: #5d453b;
$ivory-highlight: #7a541c;

// Bronze
$bronze-pattern: #ffebde;
$bronze-light: #fcd8c1;
$bronze-medium: #c39882;
$bronze-dark: #866656;
$bronze-highlight: #4e3426;

// Rose
$rose-pattern: #ffebe5;
$rose-light: #f8bbab;
$rose-medium: #f28e78; // new pink
$rose-dark: #ae5043;
$rose-highlight: #c73611;

// Brand colors
// white
$brand-white-dark: #a2a1a1;
$brand-white: #eeeeef;
$brand-white-light: #fff;

// charcoal
$brand-charcoal-dark: #1e1f1d;
$brand-charcoal: #2b2b2b;
$brand-charcoal-light: #3d3d3d;

// silver
$brand-silver: #efefef;

// Gold
$brand-gold-dark: #a17a53;
$brand-gold: #bc955a;
$brand-gold-light: #d2ae76;

:root {
  --charcoal-pattern: #f4f4f4;
  --charcoal-light: #ffffff;
  --charcoal-medium: #cac7c7;
  --charcoal-dark: #1e1f1d;
  --charcoal-highlight: #606060;
  --golden-light: #edd270;
  --golden-medium: #c6a443;
  --new-gold: #bc955a;
  --new-gold-dark: #a17a53;
  --new-gold-light: #d2ae76;
  --brand-white: #eeeeef;
  --brand-white-dark: #a2a1a1;
  --rating: 0%;
}

:export {
  charcoal-pattern: $charcoal-pattern;
  charcoal-light: $charcoal-light;
  charcoal-medium: $charcoal-medium;
  charcoal-dark: $charcoal-dark;
  charcoal-highlight: $charcoal-highlight;
  copper-pattern: $copper-pattern;
  copper-light: $copper-light;
  copper-medium: $copper-medium;
  copper-dark: $copper-dark;
  copper-highlight: $copper-highlight;
  golden-pattern: $golden-pattern;
  golden-light: $golden-light;
  golden-medium: $golden-medium;
  golden-dark: $golden-dark;
  golden-highlight: $golden-highlight;
  ivory-pattern: $ivory-pattern;
  ivory-light: $ivory-light;
  ivory-medium: $ivory-medium;
  ivory-dark: $ivory-dark;
  ivory-highlight: $ivory-highlight;
  bronze-pattern: $bronze-pattern;
  bronze-light: $bronze-light;
  bronze-medium: $bronze-medium;
  bronze-dark: $bronze-dark;
  bronze-highlight: $bronze-highlight;
  rose-pattern: $rose-pattern;
  rose-light: $rose-light;
  rose-medium: $rose-medium;
  rose-dark: $rose-dark;
  rose-highlight: $rose-highlight;
}
