.founders {
  text-align: left;

  &__background.mobile-hero {
    @supports not (height: clamp(340px, 25vh, 500px)) {
      min-height: rem-calc(380);
      max-height: rem-calc(620);
    }

    @supports (height: clamp(340px, 25vh, 500px)) {
      height: clamp(340px, 25vh, 500px);
    }

    @media screen and (min-width: rem-calc(481)) {
      @supports (height: clamp(340px, 41vh, 500px)) {
        height: clamp(340px, 41vh, 500px);
      }
    }
  }

  &__signature {
    @include breakpoint(tablet) {
      img {
        height: 6rem;
      }
    }

    @include breakpoint(laptop) {
      img {
        height: 3rem;
      }
    }
  }

  @include breakpoint(tablet) {
    .column {
      flex-direction: column-reverse;
      display: flex;
    }
  }

  &__social {
    list-style: none;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid $charcoal-medium;

    img {
      width: 1.75rem;
      vertical-align: middle;
    }

    li {
      display: block;
      margin: 1rem 0;

      a {
        font-size: rem-calc(15);
      }

      @include breakpoint(laptop) {
        margin: 0.5rem 0;

        a {
          font-size: 1rem;
        }
      }
    }

    @include breakpoint(tablet) {
      border: none;

      img {
        width: rem-calc(18);
        margin-right: rem-calc(8);
      }

      span {
        font-size: 0.75rem;
      }
    }
  }
}
