.video-card {
  position: relative;
  z-index: 2;
  width: 100%;
  overflow: hidden;
  background: $charcoal-dark;
  border-radius: 13px;
  cursor: pointer;
  margin-bottom: rem-calc(32);
  z-index: 1;
}

@include breakpoint(tablet) {
  .playlist-wrapper {
    position: relative;
    display: block;

    .video-card {
      height: rem-calc(220);
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        position: absolute;
        top: 0;
        margin: 0;
        left: 0;
        padding: 0;
        width: 100%;
        border-radius: 13px;
        height: 100%;
        object-fit: cover;
        filter: grayscale(0.4);
        z-index: 2;
      }
    }
  }
}

@include breakpoint(desktop) {
  .video-card__more {
    height: rem-calc(220);

    border-radius: 13px;
  }

  .video-card:hover {
    .video-card__content,
    img {
      opacity: 0;
      border-radius: 13px;
      transition: all 0.4s;
    }
  }
}

.playlist {
  display: flex;
  align-content: flex-start;

  .video-card__item {
    flex: 1 1 auto;
    height: rem-calc(150);
    text-transform: capitalize;
  }

  img {
    position: absolute;
    top: 0;
    margin: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(0.4);
    border-radius: 13px;
  }
}

.video-card__content {
  text-align: center;
  height: 100%;
  width: 100%;
  background: rgba($golden-medium, 0.4);
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;
  top: 0;
  position: absolute;
  border-radius: 13px;
  z-index: 2;
  h4 {
    margin-bottom: rem-calc(5);
    text-transform: capitalize;
  }

  &::before {
    content: url('/static/img/icons/white/play.png');
  }
}
