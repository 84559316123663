.accordion {
  width: 100%;

  &__header {
    background-color: transparent;
    width: 100%;
    border: none;
    padding: rem-calc(10);
    text-align: left;
    color: inherit;
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;

    span {
      display: inline-block;
      width: 100%;
    }
  }

  &__animation--open {
    .accordion__header::after {
      content: '';
      transform: rotate(-180deg);
      transition: all 0.4s ease-in-out;
    }
  }

  &__body {
    transition: height 0.5s;
    transition-timing-function: cubic-bezier(0.1, 0.5, 0.1);
    overflow-y: hidden;

    &--content {
      padding: 1rem;
    }

    &--default {
      background-color: $white;
      border-bottom-right-radius: 13px;
      border-bottom-left-radius: 13px;
      border-left: 1px solid;
      border-right: 1px solid;
      border-bottom: 1px solid;
    }
  }

  .closed {
    display: none;
    @include breakpoint(tablet) {
      height: 0;
      overflow: hidden;
      display: block;
    }
  }

  .open {
    border-top: 1px solid $charcoal-pattern;
    display: block;
    height: auto;
    overflow: initial;
  }

  &--golden {
    background-color: $golden-medium;
    color: $white;

    @include breakpoint(tablet) {
      margin-top: -2rem;
    }
  }

  &--builder {
    margin-bottom: 2rem;

    span {
      font-size: 70%;

      @include breakpoint(tablet) {
        font-size: inherit;
      }
    }

    small {
      font-size: 60%;

      @include breakpoint(tablet) {
        font-size: 80%;
      }
    }

    .accordion {
      &__header {
        padding: 1rem;

        &::after {
          position: absolute;
          content: '';
          background: url('/static/img/icons/white/arrow-down.svg') center /
            cover;
          width: 1rem;
          height: 1rem;
          right: 1rem;
          top: 1.5rem;
          transition: all 0.4s ease-in-out;
        }
      }

      &__body {
        height: rem-calc(250);

        @include breakpoint(tablet) {
          height: rem-calc(320);
        }
      }
    }

    .closed {
      height: 0;
    }
  }

  &--form {
    padding: 0.5rem;
    box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
      0 5px 15px 0 rgba(40, 39, 39, 0.05);
    border-radius: 13px;
    background-color: $white;

    .accordion__header {
      font-size: rem-calc(12);
      color: $golden-medium;
      text-transform: uppercase;
      font-weight: 600;

      &::after {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: 1rem;
        z-index: 1;
        background: url('/static/img/icons/golden-medium/arrow-down.svg') center /
          cover;
        transition: all 0.4s ease-in-out;
      }
    }

    .accordion__body--content {
      padding: 0.5rem;
    }
  }

  &__quick-links {
    margin-bottom: 0.5rem;
    border-radius: 13px;
    transition: all 0.4s ease-in;
    background-color: $white;
    padding: 0.5rem;
    position: relative;
    overflow: hidden;

    .accordion__header {
      color: $charcoal-dark;
      font-weight: 600;
      font-size: 1rem;

      &::after {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: 1rem;
        z-index: 1;
        background: url('/static/img/icons/charcoal-dark/arrow-down.svg') center /
          cover;
        transition: all 0.4s ease-in-out;
      }

      &:focus {
        outline: 1px dotted $light-gray;
        outline-offset: rem-calc(12);
        transition: none;
      }
    }

    .open {
      height: rem-calc(100);
    }
  }
}

.policy {
  .open {
    border: none;
  }

  &__warning {
    margin-bottom: 0.5rem;

    strong {
      font-size: rem-calc(14);
    }
  }

  .accordion__header {
    padding: 0;

    &:focus {
      outline: 1px dotted $golden-light;
    }

    span {
      color: $golden-dark;
      font-weight: 600;
      text-decoration: underline;
      font-size: rem-calc(14);
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .accordion__body--content {
    text-align: justify;
    font-size: rem-calc(12);
    margin: 0;

    p {
      font-size: rem-calc(12);
      margin: 0;
    }
  }

  &__content {
    margin-bottom: 0.25rem;
  }

  &__cancellation {
    .accordion__header span {
      text-align: left;
    }
  }
}

.accordion__animation--open .accordion__header {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.4s ease-in-out;
}
