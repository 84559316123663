.consent-form {
  &__text {
    max-height: 15rem;
    overflow-y: auto;
  }

  &__modal {
    max-width: 90%;
    @include breakpoint(laptop) {
      max-width: 60.5rem;
    }
  }
}
