.practitioner-result {
  border-radius: 13px;
  border: 1px solid $brand-white;
  border-collapse: collapse;
  margin: rem-calc(4px 4px 20px 4px);
  transition: background ease-in-out 0.3s;
  @supports (content-visibility: auto) {
    content-visibility: auto;
    contain-intrinsic-size: 0 500px;
  }

  &.practitioner-selected {
    background: $brand-white;
    transition: background ease-in-out 0.3s;
  }

  @include breakpoint(tablet) {
    margin: rem-calc(4px 8px 16px 8px);
    display: flex;
  }
  .content-divider.content-divider--extra-small {
    margin: 1rem 0;

    @include breakpoint(tablet) {
      margin: 1.5rem 0;
    }
  }

  &__images {
    overflow: hidden;
    width: 100%;
    position: relative;
    .slick-list {
      height: 0;
      padding-bottom: 62.5%;
      border-radius: 13px 13px 0 0;

      @include breakpoint(tablet) {
        border-radius: 13px 0 0 13px;
      }

      .slick-slide {
        img {
          max-width: 100%;
        }
      }
    }

    .slick-dots {
      bottom: 0;
      width: 30%;

      li {
        width: rem-calc(10);
        height: rem-calc(10);
      }
    }

    @include breakpoint(tablet) {
      max-width: 50%;
      flex-basis: 45%;
    }
    @include breakpoint(laptop) {
      max-width: calc(45% + 2rem);
      flex-basis: calc(45% + 2rem);
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem;

    & > div {
      max-width: 50%;
      flex-basis: 50%;

      .button {
        padding: 0.75rem 1rem;
      }
    }

    @include breakpoint(tablet) {
      justify-content: flex-end;
      align-items: center;
      padding: 0.75rem 0 0 0;
      margin-top: 0;
      border-top: 1px solid $brand-white;
    }
  }

  &__info {
    @include breakpoint(tablet) {
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 55%;
      flex-basis: 55%;
    }
    @include breakpoint(laptop) {
      max-width: 50%;
      flex-basis: 50%;
      z-index: 1;
    }
  }

  &__details {
    display: flex;
    padding: 0.75rem;
    align-items: flex-start;
    justify-content: space-between;

    &--clinic {
      width: 10rem;
      display: flex;
      flex-direction: column;
    }

    @include breakpoint(tablet) {
      padding: 0;
      &--clinic {
        width: 12rem;
      }
      .golden {
        font-size: rem-calc(12);
        margin-bottom: 0.5rem;
      }
    }

    &--standout {
      margin-bottom: -10px;
      margin-top: -7px;

      @include breakpoint(tablet) {
        display: flex;
        align-items: center;
        margin-bottom: -10px;
        justify-content: space-between;
        margin-top: rem-calc(-10px);
      }
    }

    h2 {
      font-size: rem-calc(20);
      font-family: $global-font-family;
      margin-bottom: rem-calc(0);
      font-weight: 700;
      text-transform: capitalize;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        text-decoration: underline;
      }
    }

    .golden {
      text-transform: capitalize;
      font-size: rem-calc(12);
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    .rating {
      display: block;
    }
  }

  &__distance {
    padding: 0.75rem;
    border-top: 1px solid $brand-white;
    border-bottom: 1px solid $brand-white;
    @include breakpoint(tablet) {
      padding: 0.5rem 0;
      border: none;
    }
  }

  &__avatar {
    position: relative;
    margin-top: -3rem;
    display: block;
    height: 7rem;

    @include breakpoint(tablet) {
      margin-top: 0;
      width: max-content;
      height: auto;
    }

    img.practitioner-result__logo {
      width: 6rem;
      height: 6rem;
      border-radius: 100%;
      display: block;
      margin: auto;
      overflow: hidden;
      background-color: $white;
      cursor: pointer;
      aspect-ratio: 1/1;
      border: 2px solid $white;

      &:focus,
      &:active {
        outline: 1px dotted $golden-light;
      }

      @include breakpoint(tablet) {
        position: unset;
        top: 0;
        width: 3.25rem;
        border: none;
        height: 3.25rem;
      }
    }

    &--level {
      position: absolute;
      bottom: 1.25rem;
      background: $white;
      right: remo-calc(4px);
      width: 1.5rem;
      height: 1.5rem;
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      @include breakpoint(tablet) {
        right: 0;
        bottom: -0.5rem;
      }
    }
  }

  &__price {
    display: block;
    font-weight: 700;
    margin-bottom: rem-calc(0);
    font-size: rem-calc(16);

    @include breakpoint(tablet) {
      font-size: rem-calc(18);
    }
  }

  &__treatment {
    color: $medium-gray;
    font-weight: 500;
    font-size: rem-calc(12);
    display: block;
    overflow: hidden;
    white-space: break-spaces;
    text-align: left;
    text-overflow: ellipsis;
    padding-right: 0.5rem;

    @include breakpoint(tablet) {
      padding-right: 0;
    }
  }

  &__logo {
    order: 2;
    margin-left: rem-calc(16);
    margin-right: rem-calc(16);
    width: rem-calc(112);

    @include breakpoint(tablet) {
      margin-left: auto;
    }
  }

  &--active {
    background: $light-gray;
  }
}
