.client-clinic-forms {
  .form-selection-hint {
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sessions__item {
      width: 50%;
    }
  }
}
