.field {
  position: relative;
  margin-bottom: rem-calc(16);

  &--no-margin {
    margin-bottom: 0;
  }

  &--static {
    position: static;
  }

  label {
    display: block;
    margin-bottom: rem-calc(8);
    font-weight: bold;

    .optional {
      font-style: italic;
    }
  }

  &--marginless {
    label {
      margin-bottom: 0;
    }
  }

  input,
  textarea,
  select {
    display: block;
    border-radius: 13px;
    transition: border-color 0.3s;
    font-family: $global-font-family;
    width: 100%;
    color: $charcoal-dark;
    appearance: none;
    background-color: $white;
    border: 1px solid $charcoal-medium;
    font-size: rem-calc(16);
    padding: rem-calc(8 12);
    margin-bottom: rem-calc(4);
    height: rem-calc(44);
    line-height: rem-calc(24);
    -webkit-appearance: none;

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: $brand-white-dark;
    }

    &:hover {
      border-color: $charcoal-dark;
    }

    &:focus {
      border-color: $golden-dark;
      outline: none;
    }

    &[aria-disabled='true'],
    &:disabled {
      color: $charcoal-highlight;
    }

    &.marginless {
      margin-bottom: 0;
    }
    :required:invalid {
      border-color: $red;
    }
  }

  textarea {
    padding: rem-calc(16 12);
    min-height: rem-calc(160);
  }

  &__select {
    position: relative;

    select {
      -webkit-appearance: none;
      text-overflow: ellipsis;
      padding-right: 2rem;
      white-space: nowrap;
      overflow: hidden;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem-calc(16);
      width: rem-calc(16);
      height: rem-calc(16);
      background: url('/static/img/icons/arrow-down.svg') center / cover
        no-repeat;
      pointer-events: none;
    }
  }

  &--date {
    input {
      background: url('/static/img/icons/date.svg') rem-calc(12) 50% / auto 16px
        no-repeat;
      padding-left: rem-calc(40);
      max-width: 42rem;
      background-color: $white;
    }
  }

  &--name {
    input {
      background: url('/static/img/icons/name.svg') rem-calc(12) 50% / auto 16px
        no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &__price {
    input {
      background: $white url('/static/img/icons/cost.svg') rem-calc(12) 50% /
        auto 16px no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &__duration {
    input {
      background: $white url('/static/img/icons/time.svg') rem-calc(12) 50% /
        auto 16px no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &--search {
    .input {
      background: $white url('/static/img/icons/golden-dark/search.svg')
        rem-calc(12) 50% / auto 16px no-repeat;
      padding-left: rem-calc(40);
    }

    &__dark {
      .input {
        background: $white url('/static/img/icons/search.svg') rem-calc(6) 50% /
          auto 16px no-repeat;

        &--closer {
          padding-left: rem-calc(30);
        }
      }
    }

    &__horizontal {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      .main_button,
      .main_field {
        flex: 1 auto;
        margin-bottom: 0.5rem;

        button {
          height: 2.75rem;
        }
      }

      @include breakpoint(tablet) {
        flex-direction: row;

        .main_button {
          align-self: flex-end;
        }

        .main_field {
          flex: 5;
          margin-right: 1%;

          input {
            margin-bottom: 0;
          }
        }

        .align {
          align-self: flex-start;
          margin-top: rem-calc(27px);
        }
      }
    }

    &__items {
      min-height: 3rem;
      width: 100%;
      max-height: 9rem;
      overflow-y: auto;
      overflow-x: hidden;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
      background-color: $white;

      &--open {
        padding: 1rem;
      }

      strong {
        border-block-end: 0;
        border-bottom: 1px solid $light-gray;
        font-size: rem-calc(18);

        &:hover {
          cursor: pointer;
          color: $golden-dark;
          font-weight: 700;
          text-decoration: underline;

          &::before,
          &::after {
            opacity: 1;
          }
        }
      }

      p,
      .button--link {
        color: $medium-gray;
        font-size: 1rem;
        line-height: 1.5;
        padding: rem-calc(8);
        border-block-end: 0;
        border-bottom: 1px solid $light-gray;

        &:hover {
          cursor: pointer;
          color: $golden-dark;
          font-weight: 600;

          &::before,
          &::after {
            opacity: 1;
          }
        }

        &.error:hover {
          color: $rose-highlight;
        }
      }
    }
  }

  &--email {
    input {
      background: url('/static/img/icons/email.svg') rem-calc(12) 50% / auto
        16px no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &--password {
    input {
      background: url('/static/img/icons/password.svg') rem-calc(12) 50% / auto
        16px no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &--confirmation {
    input {
      background: url('/static/img/icons/check.svg') rem-calc(12) 50% / auto
        16px no-repeat;
      padding-left: rem-calc(40);
    }
  }

  &--inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      flex: 1;
      text-transform: none;
      letter-spacing: 0;
      margin: 0;
    }

    &__radio {
      flex-direction: column;
      align-items: flex-start;

      @include breakpoint(tablet) {
        flex-direction: row;
        align-items: inherit;

        label {
          flex: 1 1 25%;
        }
      }
    }

    .radio {
      margin-left: rem-calc(32);

      &__statutoryBody {
        margin-left: 0;
        flex: 1 1 100%;
      }

      &__faux {
        margin-right: rem-calc(8);
      }
    }
  }

  &--error {
    color: $red;

    input,
    textarea,
    select {
      border-color: $red;
    }
  }

  .input {
    &--disabled {
      cursor: not-allowed;
      border-color: $brand-white;
      &:focus,
      &:hover {
        border-color: $brand-white;
      }
    }
  }
}

.radio {
  &--wrapper {
    @include breakpoint(tablet) {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__statutoryBody {
    flex: auto;

    label {
      color: $charcoal-medium;
      cursor: default;

      .radio__faux {
        cursor: default;
      }
    }

    input:checked + label {
      color: $charcoal-dark;
      cursor: default;
    }
  }
}
