.star-rating {
  display: flex;
  direction: row;
}

.star-rating button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.star-rating .star {
  line-height: 2.5rem;
  font-size: 2rem;
  color: #d3d3d3;
}

.star-rating .on .star {
  color: #ffca28;
}

.star-rating .off .star {
  color: #d3d3d3;
}

.star-rating .description {
  margin-left: 1rem;
  line-height: 2.5rem;
  font-size: 1.25rem;
  color: $medium-gray;
}
