.related-article {
  display: flex;
  h2 {
    font-size: rem-calc(16);
    text-decoration: underline;
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  &.center {
    justify-content: center;
    align-items: center;
    h2 {
      margin-bottom: 0;
    }
  }
}
