.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: rem-calc(16 0);
  &--short {
    margin: 0.25rem 0;
    .checkbox__faux {
      margin-right: rem-calc(1);
    }
  }

  &--show-inactive-patients {
    margin-right: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    h4 {
      font-size: 16px;
    }
  }

  input[type='checkbox'] {
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    width: 1px;

    &:focus {
      outline: none;
    }

    &:checked + label .checkbox__faux {
      background: $green url('/static/img/icons/check.svg') center / 50% auto
        no-repeat;
      border: 1px solid $green;
    }

    &:disabled:not(:checked) + label .checkbox__faux {
      background: $light-gray;
      border-color: $charcoal-medium;
      cursor: not-allowed;
    }

    &.not-allowed + label .checkbox__faux {
      cursor: not-allowed;
    }

    &:focus + label .checkbox__faux {
      outline: 1px dotted $golden-medium;
    }
  }

  &__text {
    &--gray {
      color: $charcoal-medium;
    }

    &--white {
      color: $white;
    }

    &--small {
      font-size: rem-calc(16);
      font-weight: normal;
    }
  }

  &__faux {
    display: inline-block;
    margin-right: rem-calc(8);
    margin-bottom: 0;
    vertical-align: middle;
    flex: none;
    width: rem-calc(32);
    height: rem-calc(32);
    border-radius: 100%;
    border: 1px solid $charcoal-medium;
    background-color: $white;
    cursor: pointer;
  }

  label {
    margin: 0;
    vertical-align: middle;
    color: $medium-gray;
    font-weight: 400;
  }
}
