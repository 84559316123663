.search-current-client {
  .input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    border: none;
  }
  &__results {
    max-height: 8rem;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $white;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 1px 16px 10px -11px rgba($charcoal-dark, 0.5);
  }
  &.appointment__fields .search-current-client__results label {
    color: $charcoal-dark;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
}
