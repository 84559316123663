.time-slot {
  &--available {
    font-weight: 600;
    color: $charcoal-dark;
  }

  &--unavailable,
  &--closed {
    font-weight: 600;
    color: $charcoal-medium;
  }
}

//  this is needed for the reschedule section not fiting on mobile screens.
#reshedule {
  .react-datepicker {
    .react-datepicker__time-container {
      width: 67px;
      @include breakpoint(tablet) {
        width: 85px;
      }
    }
  }
}
