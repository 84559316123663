.discount-dropdown {
  padding-top: 1rem;
  .pound {
    position: absolute;
    top: 2.5rem;
    left: 2rem;
    font-size: 1rem;
    line-height: 2.75rem;
    color: $charcoal-medium;
  }
  input.padding-left-2 {
    padding-left: 2rem;
  }
  button {
    margin-top: 1rem;
  }
  &.field__select {
    &::before {
      top: rem-calc(45);
    }
    &.disabled {
      &::before {
        content: none;
      }
    }
    select:disabled {
      color: $charcoal-medium;
      cursor: not-allowed;
      &:hover {
        border-color: $light-gray;
      }
    }
  }
}
