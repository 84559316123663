.treatment-consultation {
  @include breakpoint(tablet) {
    overflow: hidden;
  }

  &__title {
    font-size: 1.5rem;
    padding-bottom: rem-calc(4);
    border-bottom: 1px dashed $charcoal-medium;
  }
}
