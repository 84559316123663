.patient-summary {
  &__item {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0.5rem 0;

    * {
      margin-inline-start: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    @include breakpoint(tablet) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--row {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__link {
    color: $charcoal-dark;
    font-weight: 800;
    text-decoration: none;
  }

  &__text {
    max-height: 10rem;
    overflow-y: auto;
  }
}
