.input__range {
  @supports (accent-color: $green) {
    accent-color: $green;
  }

  @supports not (accent-color: $green) {
    display: block;
    border-radius: 13px;
    transition: border-color 0.3s;
    width: 100%;
    appearance: none;
    background-color: $green;
    border: 1px solid $charcoal-medium;
    height: rem-calc(12);
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
      background-color: $green;
      border-radius: 13px;
    }

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1rem;
      height: 1rem;
      background-color: $green;
      border-radius: 100%;
      border-color: $green-highlight;

      &:hover {
        width: rem-calc(18px);
        height: rem-calc(18px);
      }
    }
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}
