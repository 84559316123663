.popup {
  & > .mapboxgl-popup-content {
    border-radius: 13px;
    width: 13rem;
    overflow-x: hidden;
  }
  &__price {
    font-size: rem-calc(16);
  }
  &__content {
    background-color: $white;
    margin: 0.5rem 0;
    border-radius: 13px;
    border: none;
    display: block;
    box-shadow: none;
    text-align: left;
    .bold {
      color: $charcoal-dark;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      cursor: pointer;
      border: none;
    }
    li {
      padding-left: 0.25rem;
      line-height: 1.25;
      font-size: rem-calc(12);
      .golden {
        font-size: 0.75rem;
      }
    }
    .round-image {
      width: rem-calc(52);
      border: 2px solid $light-gray;
      margin: 0 auto;
    }
  }
}

.marker-style {
  position: relative;
  background-color: transparent;
  border: none;
  img {
    height: 3rem;
  }
}

.marker {
  position: relative;
  left: -50%;
  margin-top: calc(-100% - 0.53rem);
  display: block;
  width: rem-calc(44);
  height: rem-calc(44);
  font-weight: 700;
  font-size: rem-calc(12);
  color: $golden-highlight;
  line-height: rem-calc(44);
  text-align: center;
  cursor: pointer;

  span {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    background: $brand-gold;
  }
}

.mapboxgl-popup-close-button {
  font-size: 1.125rem;
}
