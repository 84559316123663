.related-article-grid {
  border-radius: 13px;
}

.sidebar {
  display: none;
  @include breakpoint(tablet) {
    display: block;
  }
}
