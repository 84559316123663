.treatment-selection-grid {
  padding: rem-calc(10 32 0 32);
  h3 {
    font-size: 1rem;
  }
  &__wrapper {
    height: 5rem;
    overflow-y: auto;
    h4 {
      font-size: rem-calc(14);
    }
    @include breakpoint(tablet) {
      height: 4.5rem;
    }
    @include breakpoint(laptop) {
      height: rem-calc(200);
    }
  }

  @include breakpoint(tablet) {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }

  @include breakpoint(laptop) {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
  }
}

.treatment-row {
  content-visibility: auto;
  border-bottom-style: dashed;
  display: flex;
  margin-bottom: 0.25rem;
  align-items: center;
  padding: 0.25rem 0 0.5rem 0;

  &--offset {
    padding-right: 0.5rem;
  }
  span {
    font-size: rem-calc(14);
  }
  .action {
    .button--utility {
      box-shadow: 0 4px 6px 0 rgba(43, 64, 78, 0.1),
        0 5px 15px 0 rgba(0, 0, 0, 0.05);
      margin: 0 auto;
    }
  }
}
