.breadcrumbs {
  border-bottom: 1px solid $light-gray;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;

      a,
      .button--link {
        display: block;
        padding: rem-calc(8 8 8 0);
        text-decoration: none;
        color: $brand-charcoal;
        text-transform: uppercase;
        letter-spacing: 0.4px;
        font-size: rem-calc(12);

        &::after {
          content: '';
          display: inline-block;
          margin-left: rem-calc(16);
          width: rem-calc(8);
          height: rem-calc(8);
          vertical-align: middle;
          background: url('/static/img/icons/chevron-right.svg') center /
            contain;
        }

        &:hover {
          color: $charcoal-dark;
        }
      }
      .button--link::after {
        content: none;
      }

      &.active {
        a,
        .button--link {
          color: $golden-highlight;
        }
      }

      &:last-child {
        a {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}
