.pagination-link {
  cursor: pointer;
  border: solid $charcoal-medium 1px;
  background-color: $white;
  padding: 0.25rem;
  border-radius: 4px;
  color: $green-highlight;
  width: 100%;

  &:hover {
    background-color: $charcoal-pattern;
  }

  &:disabled {
    cursor: not-allowed;
    &:not(.selected) {
      background-color: $charcoal-pattern;
    }
  }

  &.selected {
    background-color: $green;
    border: solid $green-highlight 1px;
    &:hover {
      background-color: $green-middle;
    }
  }
}
