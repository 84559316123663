.journey-step {
  height: 8rem;
  &.row {
    margin-left: 0;
    margin-right: 0;
  }

  p {
    font-size: rem-calc(12);
  }
  &__content {
    height: 8rem;
  }
}

.slider-wrapper {
  height: 10rem;
}
