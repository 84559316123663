.treatment-menu {
  display: flex;
  flex-direction: column;

  &__button {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;

    @include breakpoint(laptop) {
      margin: 1rem 0;
      padding: 1rem 0;
      border-bottom: 1px solid $light-gray;
    }
  }

  .treatment-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;

    @include breakpoint(laptop) {
      flex-direction: row;
      padding-bottom: 0;
    }

    .treatment-category-details {
      align-self: center;
    }
  }

  .treatment-form-headers {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    justify-content: space-between;
    font-weight: 900;

    .remove-item {
      margin-bottom: 10px;
    }
  }

  .treatment-category-name {
    display: none;

    @include breakpoint(laptop) {
      display: block;
      height: 7rem;
      padding: rem-calc(15);
    }
  }

  .treatment-item {
    &-details {
      align-self: center;
      padding-left: 0.5rem;
      cursor: text;
      position: relative;

      img {
        width: 0.75rem;
        height: 0.75rem;
      }

      .round-button {
        width: 2rem;
        height: 2rem;
        padding: 0;

        @include breakpoint(tablet) {
          width: 2.75rem;
          height: 2.75rem;
          padding: 0.5rem;
        }

        @include breakpoint(laptop) {
          width: 1.85rem;
          height: 1.85rem;
        }
      }

      h5 {
        padding: rem-calc(10 0);
        margin: 0;
      }

      label {
        align-self: center;
        font-size: rem-calc(12);
        margin: 0;
      }
    }

    &-error {
      input {
        border: 1px solid $red;
      }
    }

    &__row {
      border-top: 1px solid $golden-light;
      padding-top: 1rem;

      &:first-of-type {
        border: none;
        padding: 0;
      }

      @include breakpoint(tablet) {
        border: none;
        padding: 0 1rem;
      }

      &--border {
        border-bottom: 1px solid $light-gray;
        margin: 0 0.5rem 1rem 0.5rem;
        padding: 0;
      }
    }

    &-name {
      border-radius: 13px;
      box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
        0 5px 15px 0 rgba(40, 39, 39, 0.05);
      border: none;

      span {
        display: block;
        margin-top: rem-calc(12);

        @include breakpoint(tablet) {
          padding-left: 1rem;
        }
      }

      .field__select select {
        border: none;
      }

      &__selection {
        padding-left: 0.5rem;

        & > select {
          margin-bottom: 0;
        }

        &--fit-content {
          height: -webkit-fit-content;
          height: fit-content;
        }
      }
    }

    &-select {
      border: 1px solid $charcoal-highlight;
      box-shadow: none;
      margin-top: 1rem;

      .field__select {
        width: 100%;
        border-color: $charcoal-highlight;

        &::before {
          background: url('/static/img/icons/charcoal-highlight/add.svg') center /
            cover;
          width: 1rem;
          height: 1rem;
        }

        select {
          color: $charcoal-highlight;
          box-shadow: none;
          background: transparent;
          font-weight: 600;
          margin-bottom: 0;
          font-size: rem-calc(13);
          width: 100%;

          @include breakpoint(tablet) {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

.treatment-remove {
  position: absolute;
  top: rem-calc(10);

  .round-button {
    width: 2rem;
    height: 2rem;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.treatment {
  &__name {
    font-size: 1.125rem;
    @include breakpoint(tablet) {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

.treatment-item {
  &-error {
    input {
      border: 1px solid $red;
    }
  }
}
.treatment__error {
  @include breakpoint(tablet) {
    height: 1.5rem;
  }
}
