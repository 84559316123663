.explainer-video {
  h4 {
    margin: 2rem 0;
    padding-top: 2rem;
    border-top: rem-calc(1px) solid $white;
  }

  @include breakpoint(tablet) {
    text-align: center;
    .video-card {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
    h4 {
      border: none;
      padding-top: 0;
    }
  }
}
