.stat-card {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: rem-calc(16);
  overflow: hidden;
  height: rem-calc(168);
  background: $white;
  border-radius: 13px;
  box-shadow: 0 15px 35px 0 rgba(43, 64, 78, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.05);
  counter-reset: list;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 160%;
    height: 100%;
    background: $light-gray;
    transform: skew(-26deg) translateX(40%);
    transition: 0.4s all;
  }

  &:hover {
    &::before {
      transform: skew(-26deg) translateX(-25%);
    }
  }

  &__stat {
    font-size: rem-calc(40);
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1;
    margin-top: rem-calc(8);
  }

  &__currency {
    font-size: rem-calc(16);
    vertical-align: top;
    font-weight: 500;
  }

  &__description {
    font-family: $global-font-family;
    font-weight: 500;
    color: $golden-highlight;
    text-transform: uppercase;
    font-size: rem-calc(10);
    line-height: 1.5;
    margin-top: auto;
  }

  &__list {
    list-style-type: none;

    li {
      display: block;
      font-family: $global-font-family;
      font-weight: 500;
      color: $charcoal-dark;
      text-transform: uppercase;
      font-size: rem-calc(12);
      line-height: 1.5;
      margin-bottom: rem-calc(4);

      &::before {
        counter-increment: list;
        content: counter(list);
        display: inline-block;
        margin-right: rem-calc(8);
        border-radius: 100%;
        font-weight: 500;
        color: $ivory-highlight;
        background: $ivory-medium;
        width: rem-calc(24);
        height: rem-calc(24);
        line-height: rem-calc(24);
        text-align: center;
      }
    }
  }

  .tooltip {
    position: absolute;
    bottom: rem-calc(16);
    right: rem-calc(16);
  }

  &--rose-light {
    &::before {
      background: $rose-light;
    }
  }

  &--ivory-light {
    &::before {
      background: $ivory-light;
    }
  }

  &--golden-light {
    &::before {
      background: $golden-light;
    }
  }

  &--bronze-light {
    &::before {
      background: $bronze-light;
    }
  }
}
