.record-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &__wrapper {
    display: flex;
    background-color: $white;
    border-radius: rem-calc(26);
  }

  &__title {
    font-size: 1rem;
  }

  .button--modal__close,
  .button--modal__back {
    position: static;
  }

  &__button-space {
    min-width: 2rem;
  }

  @include breakpoint(tablet) {
    padding: 1.25rem 2.5rem;
  }
}

.record-navigation {
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
  width: 90%;

  @include breakpoint(laptop) {
    grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));
  }

  grid-gap: 0.5rem;
  max-width: rem-calc(400px);
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  &__item,
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    font-weight: 400;
    font-size: 0.75rem;
    padding: 0.25rem;
    height: 2rem;
    justify-content: center;
    min-width: calc(100% / 5);

    @include breakpoint(tablet) {
      height: 3rem;
    }

    @include breakpoint(laptop) {
      height: 3.5rem;
    }

    span {
      font-size: rem-calc(10);
    }

    /** For now !**/
    &:focus {
      outline: none;
    }

    &--disabled {
      cursor: not-allowed;

      a {
        color: $charcoal-medium;
      }

      .record-navigation__item--icon {
        background-color: $charcoal-medium;
      }
    }

    &--current {
      background-color: $green-middle;
      border-radius: 13px;
      color: $green-highlight;

      .record-navigation__item--icon {
        background-color: $green-highlight;
      }
    }

    &--active {
      .record-navigation__item--icon {
        background-color: $green-highlight;
      }
    }

    &--icon {
      width: 1rem;
      height: 1rem;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      margin-bottom: 0.25rem;

      @include breakpoint(tablet) {
        width: 2rem;
        height: 2rem;
      }
    }

    &--summary {
      mask-image: url(/static/img/icons/green/date.svg);
    }

    &--consultation {
      mask-image: url(/static/img/icons/white/notes.svg);
    }

    &--record {
      mask-image: url(/static/img/icons/white/treatment.svg);
    }

    &--payment {
      mask-image: url(/static/img/icons/white/payment.svg);
    }

    &--review {
      mask-image: url(/static/img/icons/white/record.svg);
    }

    &--forms {
      mask-image: url(/static/img/icons/white/forms.svg);
    }
  }
}

.record-appointment {
  display: grid;
  grid-template: [row1-start] 'status treatment date action' auto [row1-end] / 2fr 4fr 1fr;
  margin-bottom: 0.5rem;
  gap: rem-calc(8);
}

.record {
  &__navigation {
    padding: 0.25rem;
    border-radius: rem-calc(36);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));

    @include breakpoint(laptop) {
      grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));
    }

    grid-gap: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  &__item {
    border-radius: rem-calc(36);
    height: 2.5rem;
    color: $green-highlight;
    text-decoration: none;
    justify-content: center;
    @include breakpoint(tablet) {
      justify-content: flex-start;
      background-color: $white;
    }
    @include breakpoint(laptop) {
      height: 3.5rem;
    }
    &:focus {
      outline: 1px dotted $green-middle;
      outline-offset: 1px;
    }

    &--icon {
      width: 2rem;
      height: 2rem;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      background-color: $green-highlight;

      @include breakpoint(tablet) {
        width: 2rem;
        height: 2rem;
        mask-size: 1.5rem;
        margin: 0 0.5rem 0 0.75rem;
      }
    }

    &--summary {
      mask-image: url(/static/img/icons/green/date.svg);
      mask-size: 1.25rem;
    }

    &--consultation {
      mask-image: url(/static/img/icons/white/notes.svg);
    }

    &--record {
      mask-image: url(/static/img/icons/white/treatment.svg);
    }

    &--payment {
      mask-image: url(/static/img/icons/white/payment.svg);
      mask-size: 1.25rem;
    }

    &--review {
      mask-image: url(/static/img/icons/white/record.svg);
    }

    &--forms {
      mask-image: url(/static/img/icons/white/forms.svg);
    }

    &--forms-library {
      mask-image: url(/static/img/icons/white/forms.svg);
    }

    &--forms-assign {
      mask-image: url(/static/img/icons/white/checklist.svg);
      mask-size: 1.25rem;
    }

    &--disabled {
      background-color: darken($white, 3);
      color: $brand-charcoal-light;
      cursor: not-allowed;
      .record__item--icon {
        background-color: $brand-charcoal-light;
      }
    }

    &--link {
      font-weight: 600;
      font-size: 0.75rem;
      @include breakpoint(laptop) {
        font-size: 1rem;
      }
    }

    &--current {
      background-color: $green;
      border-radius: rem-calc(36);
      @media (hover: hover) {
        &:hover {
          background: $green-middle;
          border-radius: rem-calc(36);
        }
      }
    }

    &--active:not(.record__item--current) {
      @media (hover: hover) {
        &:hover {
          background-color: darken($white, 3);
        }
      }
    }
  }
}
