.reviews {
  h4 {
    font-size: rem-calc(16);
    color: $charcoal-highlight;
  }

  .reviews-pager {
    margin-top: rem-calc(10);
    text-align: center;
    font-size: rem-calc(11);
    a {
      cursor: pointer;
      margin-left: rem-calc(10);
      min-width: rem-calc(14);
      display: inline-block;
    }
    a.active {
      text-decoration: underline;
      text-underline-offset: rem-calc(2);
    }
    a.arrow-left {
      font-size: rem-calc(14);
      margin-left: 0;
      margin-right: rem-calc(16);
    }
    a.arrow-right {
      font-size: rem-calc(14);
      margin-left: rem-calc(26);
      margin-right: 0;
    }
  }
}

.review {
  padding: 1rem 0;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;

  p {
    font-size: rem-calc(14);
  }

  pre {
    font-size: rem-calc(14);
    overflow: auto;
    white-space: pre-wrap;
  }

  h3 {
    font-family: $global-font-family;
    color: $charcoal-dark;
    font-size: rem-calc(16);
    font-weight: 700;
    margin-bottom: -0.25rem;
  }

  h4 {
    color: $brand-gold-dark;
    font-size: rem-calc(16);
    margin-bottom: rem-calc(10);
  }

  &__date {
    float: right;
    font-size: rem-calc(13);
    color: $charcoal-dark;
    margin-top: rem_calc(-12);
  }

  &__reply {
    margin-left: rem-calc(20);
    padding-left: rem-calc(20);
    border-left: 1px solid $light-gray;
  }

  &__replyheader {
    font-family: $global-font-family;
    color: $charcoal-dark;
    font-size: rem-calc(16);
    font-weight: 700;
  }

  &__replydate {
    float: right;
    font-size: rem-calc(13);
    color: $charcoal-dark;
    margin-top: rem_calc(-32);
  }
}
/*
.review {
  padding-bottom: rem-calc(32);
  margin-bottom: rem-calc(32);
  border-bottom: 1px solid $light-gray;
  text-align: left;

  header {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(16);

    h3 {
      font-family: $global-font-family;
      font-size: rem-calc(16);
      font-weight: 700;
      margin-bottom: rem-calc(4);
    }

    p {
      color: $medium-gray;
      font-size: rem-calc(12);
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }

  &__image {
    width: rem-calc(38);
    height: rem-calc(38);
    border-radius: 100%;
    margin-right: rem-calc(8);

    @include breakpoint(tablet) {
      margin-right: rem-calc(16);
      width: 4rem;
      height: 4rem;
    }
  }

  &__meta {
    flex: 2;
  }

  p {
    margin: 0;
  }

  &__reply {
    padding: rem-calc(14) 0 0 rem-calc(64);
    flex: 1 100%;
  }
}
*/
#main_content {
  /*----- Styles -----*/

  .yotpo {
    &.bottomLine {
      height: 3rem;
    }
    .yotpo-nav-content .yotpo-reviews-filters.yotpo-active {
      display: none;
    }
    div,
    span,
    p,
    a,
    img,
    i,
    strong,
    sup,
    ul,
    li,
    form,
    label,
    .reviews-header .reviews-amount {
      font-family: $global-font-family;
    }
    .yotpo-label-container {
      border-top-color: $light-gray;
    }

    a {
      color: $brand-gold-dark !important;
      font-size: rem-calc(10);
      font-weight: 600;
    }

    .star-clickable:focus {
      outline: none;
    }

    .yotpo-comments-box {
      border-color: $light-gray;
    }

    .yotpo-nav ul .yotpo-nav-tab.yotpo-active {
      span {
        font-size: 1.25rem;
        text-transform: lowercase;
        color: $charcoal-dark;
        display: block;
        &::first-letter {
          text-transform: none;
        }
      }

      .yotpo-nav-wrapper {
        border: none;
        cursor: auto;
        &::before {
          content: 'Glowday Verified Reviews';
          font-family: $global-font-family;
          font-weight: 700;
          color: $charcoal-dark;
          font-size: 1.25rem;
        }
        span {
          position: absolute;
          height: 1px;
          width: 1px;
          clip: rect(1px 1px 1px 1px); // IE 6 and 7
          clip: rect(1px, 1px, 1px, 1px);
          clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
          -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
          overflow: hidden !important;
        }
      }
    }

    .yotpo-review {
      .yotpo-header {
        .yotpo-header-element {
          display: flex;
          flex-direction: column;
          clear: right;

          .y-label {
            &.yotpo-user-name {
              color: $charcoal-dark;
              font-size: 1rem;
              margin-top: rem-calc(12);
              font-family: $global-font-family;
            }

            &.yotpo-review-date {
              color: $charcoal-dark;
              font-size: rem-calc(13);
            }

            &.yotpo-user-title {
              display: none;
            }
          }
        }

        &.yotpo-verified-buyer {
          .yotpo-header-element.yotpo-icon-profile {
            display: none;
          }
        }

        &.yotpo-store-owner {
          .yotpo-header-element.yotpo-icon-profile {
            background: $brand-gold !important;
          }

          .yotpo-icon-store::before {
            font-size: 2rem;
            position: absolute;
            top: -0.5rem;
            left: 1rem;
            color: $white;
          }
        }
      }

      .yotpo-main {
        margin-left: 0;
      }

      .yotpo-footer {
        margin-left: 0;
        margin-top: 2rem;

        .yotpo-helpful.yotpo-helpful {
          margin-top: 1rem;
          padding-top: 1rem;
        }
        .footer-actions {
          display: none;
        }

        @media screen and (max-width: rem-calc(575)) {
          &.yotpo-main-widget.yotpo-small
            .yotpo-review
            .footer-additional-actions {
            float: none;
          }
        }
      }

      /* CSS to remove "Reviewed on:"*/
      .yotpo-grouping-reference::before {
        content: 'Treatment:';
      }
    }

    .yotpo-icon-circle-checkmark::before {
      content: none;
    }

    .new-yotpo-small-box {
      border: none;
    }

    .standalone-bottomline .star-clickable {
      flex-direction: row;
      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    /* Big rating */
    .main-widget .bottom-line-items {
      float: left;
      border-bottom: 1px solid $light-gray;
      margin-bottom: -1rem;
      padding-bottom: 2rem;

      span.font-color-gray-darker {
        color: $charcoal-highlight;
      }
    }

    .yotpo-first-review {
      .yotpo-first-review-stars {
        text-align: left;
      }

      .yotpo-first-review-content {
        text-align: left;

        & > span {
          display: none;
        }

        &::before {
          content: 'There are no reviews';
        }
      }
    }
  }

  .font-color-gray {
    color: $charcoal-medium;
    font-weight: 600;
  }

  .yotpo-logo-link-new {
    display: none;
  }

  /* ratings */
  span .reviews-qa-labels-container {
    display: block;
    width: 6rem;
    text-align: left;
    margin-left: 5rem;
    margin-top: 0;
  }

  @media screen and (min-width: rem-calc(940)) {
    .yotpo span .reviews-qa-labels-container {
      margin-top: -0.5rem;
    }
  }

  .reviews-qa-labels-container.mL0 {
    margin-left: 1rem !important;
  }

  .yotpo-grouping-reference {
    display: none;
  }
}

.yotpo .yotpo-review .yotpo-main .content-review,
.yotpo .yotpo-comment .yotpo-main .content-review,
.yotpo .yotpo-question .yotpo-main .content-review,
.yotpo .yotpo-onsite-upload .yotpo-main .content-review {
  color: $charcoal-dark;
  font-size: rem-calc(14);
}

.yotpo .yotpo-review .yotpo-main .content-title,
.yotpo .yotpo-comment .yotpo-main .content-title,
.yotpo .yotpo-question .yotpo-main .content-title,
.yotpo .yotpo-onsite-upload .yotpo-main .content-title {
  color: $brand-gold-dark !important;
  font-size: 1rem;
  margin-bottom: rem-calc(8);
}

/* rating stars */
.practitioner-result__details .yotpo .standalone-bottomline .star-clickable {
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
}

@media screen and (max-width: $grid-break-point-tablet) {
  .yotpo .standalone-bottomline .star-clickable {
    float: none;
    align-items: center;
    margin: 0.5rem 0;
  }

  .practitioner-result__details .yotpo .standalone-bottomline .star-clickable {
    align-items: flex-start;
  }
}

@media screen and (min-width: $grid-break-point-tablet) {
  .practitioner-result__details .yotpo .standalone-bottomline .star-clickable {
    align-items: flex-end;
    text-align: right;
  }

  .practitioner-results__info .yotpo.bottomLine.yotpo-small {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}

.practitioner-result__details .yotpo .text-m {
  align-self: flex-start;
  padding-left: 0.25rem;
  @include breakpoint(tablet) {
    align-self: flex-end;
  }
  @include breakpoint(laptop) {
    padding-left: 0;
    padding-right: 0.25rem;
  }
}

#main_content {
  .yotpo.yotpo-main-widget .yotpo-header .yotpo-icon-profile,
  .yotpo.yotpo-main-widget .yotpo-icon-star,
  .yotpo.yotpo-main-widget .yotpo-icon-half-star,
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star,
  .yotpo.embedded-widget .yotpo-header .yotpo-icon-profile,
  .yotpo.embedded-widget .yotpo-icon-star,
  .yotpo.embedded-widget .yotpo-icon-half-star,
  .yotpo.embedded-widget .yotpo-icon-empty-star,
  .yotpo.yotpo-modal .yotpo-header .yotpo-icon-profile,
  .yotpo.yotpo-modal .yotpo-icon-star,
  .yotpo.yotpo-modal .yotpo-icon-half-star,
  .yotpo.yotpo-modal .yotpo-icon-empty-star,
  .yotpo.bottomLine .yotpo-header .yotpo-icon-profile,
  .yotpo.bottomLine .yotpo-icon-star,
  .yotpo.bottomLine .yotpo-icon-half-star,
  .yotpo.bottomLine .yotpo-icon-empty-star,
  .yotpo.yotpo-reviews-carousel .yotpo-header .yotpo-icon-profile,
  .yotpo.yotpo-reviews-carousel .yotpo-icon-star,
  .yotpo.yotpo-reviews-carousel .yotpo-icon-half-star,
  .yotpo.yotpo-reviews-carousel .yotpo-icon-empty-star,
  .mobile-filters-modal .yotpo-header .yotpo-icon-profile,
  .mobile-filters-modal .yotpo-icon-star,
  .mobile-filters-modal .yotpo-icon-half-star,
  .mobile-filters-modal .yotpo-icon-empty-star {
    background-image: none !important;
    color: $brand-gold-light !important;
  }
  .canopy__overview {
    .standalone-bottomline .star-clickable {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.yotpo-regular-box.yotpo-bottomline.bottom-line-items-container {
  display: none;
}
.yotpo .yotpo-label-container {
  border-top: none !important;
}
