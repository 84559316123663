.category-menu {
  min-height: 4rem;
  max-width: 100%;
  display: flex;

  @include breakpoint(laptop) {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  &__wrapper {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  &__control {
    background-color: $white;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in;
    &.rotate {
      transform: rotate(-90deg);
      transition: transform 0.5s ease-in;
    }
  }
  .open-text {
    display: block;
    background: none;
    color: $white;
    border: none;
    font-weight: 600;
    font-style: normal;
    font-size: $heading-2-font-size-mobile;
    &.disabled:focus {
      outline: none;
      border: none;
    }

    @include breakpoint(tablet) {
      font-size: $heading-2-font-size-tablet;
    }

    @include breakpoint(desktop) {
      font-size: $heading-2-font-size-desktop;
    }
  }

  #category-links {
    position: absolute;
    z-index: 2;
    top: 4rem;
    width: 100%;
    overflow: auto;
    max-height: 0;
    background: $charcoal-dark;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

    &.open {
      max-height: 26rem;
    }

    .category-link {
      line-height: 2;
      border-bottom: 1px solid $white;
      a {
        color: $white;
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
      }
      &.active a {
        font-weight: 600;
      }
    }

    @include breakpoint(laptop) {
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      &.open {
        max-height: 42rem;
      }
    }
    @include breakpoint(desktop) {
      &.open {
        max-height: 45rem;
      }
    }
  }
}
