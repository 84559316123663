@use 'sass:math';
// Sizing

$global-width: 1200px;
$grid-gutter: rem-calc(16);

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  max-width: $global-width;
  margin: 0 auto;

  &--bottom {
    display: flex;
    height: 100%;
  }
}

.container--wide {
  max-width: $global-width * 1.3;
  margin: 0 auto;
}

.banner-width {
  max-width: rem-calc(1600);
  display: block;
  margin: 0 auto;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &--direction-column {
    flex-direction: column;
  }

  @include breakpoint(tablet) {
    &--t-row {
      flex-direction: row;
    }
  }

  .row {
    width: auto;
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;

    &--no-margin {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--collapse > .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--gap {
    & > * + * {
      margin-left: 1rem;
    }
  }

  // handle horizontal alignment

  &--center {
    justify-content: center;
  }

  &--justified {
    justify-content: space-between;
  }

  &--spaced {
    justify-content: space-around;
  }

  &--end {
    justify-content: flex-end;
  }

  // handle vertical alignment

  &--middle {
    align-items: center;

    @include breakpoint(tablet) {
      &--t {
        align-items: center;
      }
    }
  }

  &--bottom {
    align-items: flex-end;
  }

  &--top {
    align-items: flex-start;
  }
}

ul.row {
  list-style: none;
  padding: 0;
  margin: 0;
}

.column {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  margin: 0;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;

  &--flex {
    display: flex;
  }

  &--relative {
    position: relative;
  }

  &--grow {
    flex-grow: 1;
  }

  &--gap {
    column-gap: 1rem;
  }
}

.content-center {
  justify-content: center;
}

.content-right {
  text-align: right;
}

.content--spaced {
  align-content: space-around;
}

@for $i from 1 through 12 {
  .column-m-#{$i} {
    flex-basis: math.div(100%, 12) * $i;
    max-width: math.div(100%, 12) * $i;
  }

  .column-m-order-#{$i} {
    order: #{$i};
  }

  .column-m-offset-#{$i} {
    margin-left: math.div(100%, 12) * $i;
  }
}

@media (min-width: $grid-break-point-tablet) {
  @for $i from 1 through 12 {
    .column-t-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;
    }

    .column-t-order-#{$i} {
      order: #{$i};
    }

    .column-t-offset-#{$i} {
      margin-left: math.div(100%, 12) * $i;
    }
  }
}

@media (min-width: $grid-break-point-laptop) {
  @for $i from 1 through 12 {
    .column-d-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;
    }

    .column-d-order-#{$i} {
      order: #{$i};
    }

    .column-d-offset-#{$i} {
      margin-left: math.div(100%, 12) * $i;
    }
  }
}

@media (min-width: $grid-break-point-desktop) {
  @for $i from 1 through 12 {
    .column-lg-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;
    }

    .column-lg-order-#{$i} {
      order: #{$i};
    }

    .column-lg-offset-#{$i} {
      margin-left: math.div(100%, 12) * $i;
    }
  }
}

.column-d-offset-0 {
  @media (min-width: $grid-break-point-laptop) {
    margin-left: 0;
  }
}

.column-t-offset-0 {
  @media (min-width: $grid-break-point-tablet) {
    margin-left: 0;
  }
}

.gap-1 {
  gap: 1rem;
}
