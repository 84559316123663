.search-field {
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  background-color: $brand-white;
  & > div {
    border: none;
  }

  input {
    box-shadow: none;
    padding: 0.75rem 0.5rem;
    line-height: 1rem;
    font-size: 1rem;
    height: auto;
    margin-bottom: 0;
    @include breakpoint(tablet) {
      height: 100%;
      width: 22rem;
    }
    @include breakpoint(laptop) {
      height: 100%;
      width: 30rem;
    }
    @include breakpoint(desktop) {
      width: 35rem;
    }
  }

  .input-open {
    border-bottom: none;
    border-radius: 13px 13px 0 0;
    @include breakpoint(tablet) {
      border-color: $golden-medium;
      border-bottom-right-radius: 0;
      border-top-right-radius: 13px;
      width: 22rem;
    }
    @include breakpoint(laptop) {
      width: 30rem;
    }
    @include breakpoint(desktop) {
      width: 35rem;
    }
  }

  .field--search__items--open {
    position: relative;
    width: 100%;
    padding-top: 0.5rem;
    @include breakpoint(tablet) {
      position: absolute;
      border-color: $golden-medium;
      width: 22rem;
    }
    @include breakpoint(laptop) {
      width: 30rem;
    }
    @include breakpoint(desktop) {
      width: 35rem;
    }
  }

  @include breakpoint(tablet) {
    flex-wrap: nowrap;
    border: none;
    input {
      box-shadow: inherit;
    }
  }

  &__navigation {
    display: flex;
    width: 100%;
    padding: 0.5rem 0;
    .button--pill {
      padding: 0.75rem;
      @include breakpoint(tablet) {
        font-size: rem-calc(8);
      }
      @include breakpoint(laptop) {
        font-size: 0.75rem;
      }
    }
    div {
      &:first-child {
        margin-right: rem-calc(4);
      }
      &:last-child {
        margin-left: rem-calc(4);
      }
    }
  }
}

.result__client {
  padding-bottom: 0.25rem;
  border-color: $ivory-light;
  &:hover {
    p,
    span {
      font-weight: 600;
    }
    border-color: $ivory-medium;
  }

  &--name {
    text-transform: capitalize;
    p {
      color: $brand-charcoal;
    }
    span {
      text-transform: lowercase;
    }
  }
}

.client-search__wrapper {
  background-color: $brand-white;
  &--input {
    @include breakpoint(tablet) {
      padding: 0.5rem 0 0.5rem 0.5rem;
    }
  }
}
