.cms-content {
  p,
  ul,
  ol {
    font-size: rem-calc(18);
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
  }

  .lightbox video {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  figure {
    margin: 0 0 rem-calc(16);
    text-align: center;

    img {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }

  &.blog-article-body {
    p:first-child::first-letter {
      float: left;
      font-size: rem-calc(56);
      line-height: rem-calc(8);
      font-weight: 700;
      color: $brand-gold-dark;
      padding: 0 rem-calc(4) 0 rem-calc(3);
    }
    h4 {
      font-weight: 600;
    }
    h2,
    h3,
    h4 {
      color: $charcoal-highlight;
    }
  }

  .floated-image {
    width: 100%;
  }

  &__home-page {
    align-self: center;
    h1 {
      @include breakpoint(tablet) {
        font-size: rem-calc(40);
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
    p {
      font-size: rem-calc(14);

      @include breakpoint(tablet) {
        font-size: rem-calc(18);
      }

      @include breakpoint(tablet) {
        font-size: rem-calc(20);
      }
      &:first-of-type {
        margin: rem-calc(8) 0 0;
      }
    }
  }

  @media (min-width: $grid-break-point-tablet) {
    .floated-image {
      width: 30%;

      &-right {
        float: right;
        margin: 0 0 1rem 1rem;
      }

      &-left {
        float: left;
        margin: 0 1rem 1rem 0;
      }
    }
  }
}
