.before-after-renderer {
  padding: 0.5rem;
  width: 656px;
  &--vertical {
    padding: 0.5rem;
    width: 672px;
  }
}

.before-after-previewer {
  display: none;
  height: 100vh;
  position: fixed;
  z-index: 20;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  background: rgba($charcoal-dark, 0.9);
  align-items: center;

  // for safari not taking url bar into account for 100vh
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      height: -webkit-fill-available;
    }
  }

  &--visible {
    display: flex;
  }

  &__cell {
    @supports not (aspect-ratio: 2/1) {
      position: relative;
      padding-top: 33%;
      height: 0px;
      width: 60vw;
      display: block;
      margin-left: auto;
      margin-right: auto;

      @media screen and (orientation: landscape) and (max-height: 47.98rem) {
        width: 30vw;
        padding-top: 16.5%;
      }

      @include breakpoint(laptop) {
        width: 35vw;
        padding-top: 16.5%;
      }
    }

    &--vertical {
      @supports not (aspect-ratio: 1/2) {
        position: relative;
        padding-left: 33%;
        height: 33vh;
        width: 0;

        @media screen and (orientation: landscape) and (max-height: 47.95rem) and (max-width: 47.98rem) {
          padding-left: 16.5%;
          height: 60vh;
        }
        @include breakpoint(tablet) {
          padding-left: 16.5%;
          height: 60vh;

          @media screen and (orientation: portrait) {
            height: 45vh;
            padding-left: 33%;
          }
        }

        @include breakpoint(laptop) {
          height: 60vh;
          padding-left: 16.5%;
        }
      }
    }
  }

  &__image {
    @supports (aspect-ratio: 2/1) {
      width: min(80vw, 40rem);
      aspect-ratio: 2/1;

      @media screen and (orientation: landscape) and (max-height: 48rem) {
        height: min(35vh, 20rem);
        width: auto;
      }
    }

    @supports not (aspect-ratio: 2/1) {
      position: absolute;
      width: 100%;
      top: 0;
    }

    &--portrait {
      @supports (aspect-ratio: 1/2) {
        height: min(80vh, 40rem);
        aspect-ratio: 1/2;
        width: auto;

        @media screen and (orientation: portrait) and (max-width: 47.98rem) and (max-height: 64rem) {
          height: min(40vh, 20rem);
          width: auto;
        }
      }

      @supports not (aspect-ratio: 1/2) {
        position: absolute;
        height: 100%;
        left: 0;
      }
    }

    &--missing {
      background-color: $white;

      @supports (aspect-ratio: 1/2) {
        margin-inline: auto;
      }

      @supports not (aspect-ratio: 1/2) {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__btn {
    width: 8rem;
  }
}
