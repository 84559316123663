.treatment-grid {
  min-height: rem-calc(380);
  overflow-y: hidden;
  overflow-x: hidden;

  &__controls {
    min-height: rem-calc(380);
    max-height: 35rem;
    overflow-y: auto;
  }

  &__button {
    background: $light-gray;
    color: $charcoal-dark;
    text-align: left;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0.5rem 0.75rem;
    font-family: $global-font-family;
    border: none;
    height: 3rem;
    transition: background-color 0.2s ease-in-out;
    margin: 0.25rem 0;

    &::before {
      content: none;
    }

    &--selected {
      background-color: $brand-gold;
      color: $white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $brand-gold-dark;
        color: $white;
        transition: background-color 0.2s ease-in-out;
      }
    }
  }

  .overflowSlider {
    margin-bottom: 0;
    max-height: 35rem;
    overflow-y: hidden;

    .slide {
      padding: 0 0.75rem;
      background: $light-gray;
      border-radius: 13px;
      overflow-y: auto;

      & > div:last-child {
        border-bottom: 1px solid #b9b9b9;
        border-bottom-style: dashed;
      }
    }
  }
}
