.canopy {
  position: relative;
  padding-bottom: rem-calc(16);
  margin: 0px auto;
  max-width: 1200px;

  &__empty {
    height: 100%;
    color: $white;
  }

  &__gallery {
    border-radius: 13px;
    overflow: hidden;
    display: grid;
    row-gap: 0;
    column-gap: 0;
    grid-template: [row1-start] 'landing image1 image2' 1fr [row1-end] [row2-start] 'landing image3 image4' 1fr [row2-end] / 2fr 1fr 1fr;

    .slick-list {
      height: 0;
      padding-bottom: 62.5%;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;

      .slick-track .slick-slide img {
        width: 100%;
      }
    }

    > .img {
      background-color: $charcoal-dark;
    }
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 16/10;
    }

    .canopy__landing {
      grid-row: row1-start/row2-end;
      aspect-ratio: 16/10;
      width: min(calc(50vw - 1rem), calc(rem-calc(600px) - 1rem));
      background-color: $charcoal-dark;
    }

    $gridImageChild: (
      'image1': 2,
      'image2': 3,
      'image3': 4,
      'image4': 5,
    );

    @each $area, $child in $gridImageChild {
      .img:nth-child(#{$child}) {
        grid-column: #{$area}-start/#{$area}-end;
      }
    }
  }

  &__logo {
    border-radius: 100%;
    z-index: 0;
    background-color: $white;
    display: flex;
    align-items: center;
    overflow: hidden;
    @supports (aspect-ratio: 1/1) {
      height: auto;
      width: 5rem;
      aspect-ratio: 1/1;
    }
    @supports not (aspect-ratio: 1/1) {
      height: 4rem;
      width: 4rem;
      aspect-ratio: 1/1;
    }
  }

  &__overview {
    margin-bottom: rem-calc(16);

    @include breakpoint(tablet) {
      margin-bottom: 0;
      margin-top: 2rem;
    }

    .button {
      margin-bottom: rem-calc(64);
      display: none;
    }

    h1 {
      font-size: 1.75rem;
      text-transform: capitalize;
      margin-bottom: rem-calc(4);
    }
  }

  &__slider {
    &--empty {
      aspect-ratio: 16/10;
      background: var(--charcoal-dark);
      width: 100%;
      margin: 0 auto;
      border-radius: 13px;
    }
    img {
      border-radius: 13px;
    }
    .custom-arrow {
      top: 80%;
      &.slick-next {
        right: 10%;
      }
      &.slick-prev {
        left: 70%;
        z-index: 2;
      }
    }
  }
}

.address {
  padding-top: 1rem;
  text-transform: capitalize;
  font-style: normal;
  h3 {
    padding: 0;
    margin-bottom: rem-calc(8);
    font-size: rem-calc(20);
  }
  &--view {
    padding-top: 0;
    .unstyled {
      color: $charcoal-dark;
    }
  }
}
