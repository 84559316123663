select.inline-select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.4em;
  background: url(/static/img/icons/arrow-down.svg) no-repeat right 0.4em center /
    1.4em;
  padding: 0 1.7em 0 0.5em;
  color: black;
  margin: 0 0.3rem 0 0.1rem;
  background-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid #cac7c7;
}
