.navigation__search {
  position: relative;
  margin: rem-calc(16 0);
  background: $white;
  border-radius: 13px;
  width: 20rem;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-size: 70%;

  & > div {
    border: 1px solid $charcoal-medium;
    border-radius: 13px;
    width: 7rem;
    margin-bottom: 0;
  }

  .button {
    font-size: 80%;
    line-height: 1;
  }
  .field--search__items--open {
    width: 13rem;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-left: 1px solid $charcoal-medium;
    border-right: 1px solid $charcoal-medium;
    border-bottom: 1px solid $charcoal-medium;
    background-color: $white;
    max-height: 14rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: inherit;

    @include breakpoint(tablet) {
      position: absolute;
      z-index: 4;
    }
  }
  @include breakpoint(laptop) {
    font-size: 100%;
    align-items: center;
    margin: rem-calc(0 16);
    width: 32rem;

    & > div {
      width: 13rem;
    }

    .button {
      font-size: 0.75rem;
    }
  }

  input {
    font-family: $global-font-family;

    height: rem-calc(44);
    border: 1px solid $charcoal-medium;
    border-radius: 13px;
    outline: none;
    margin-bottom: rem-calc(16);
    padding: rem-calc(12 12 12 40);
    width: 100%;
    box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
      0 5px 15px 0 rgba(40, 39, 39, 0.05);

    &::placeholder {
      color: $charcoal-highlight;
    }

    &.search {
      background: url('/static/img/icons/golden-dark/search.svg') rem-calc(12)
        50% / auto 16px no-repeat;
    }

    &.location {
      background: url('/static/img/icons/golden-dark/location.svg') rem-calc(12)
        50% / auto 16px no-repeat;
    }

    @include breakpoint(tablet) {
      box-shadow: none;
      margin-bottom: 0;
    }
  }
}

.result_dropdown {
  flex: auto;
  text-align: left;

  ul {
    list-style: none;
    padding: 0;
  }

  li:hover {
    cursor: pointer;

    &::before,
    &::after {
      opacity: 1;
    }
  }

  p {
    margin: 0;
    color: $charcoal-dark;
    border-bottom: 1px solid $light-gray;
    padding: 0.5rem;
    text-overflow: ellipsis;
    overflow-x: hidden;

    &:hover {
      text-decoration: underline;
      color: $golden-dark;
      font-weight: 600;
    }
  }
}
