.reason-to-join {
  background-color: $ivory-light;
  padding: 2rem 1rem;

  &__footer {
    margin-top: 1rem;
    border: none;

    h4 {
      font-size: rem-calc(30);
      font-family: $global-font-family;
      text-transform: capitalize;
      font-weight: 600;
    }
  }
}
.reason-card {
  padding: 1rem;
  align-items: center;
  h3 {
    margin: 0;
  }
  &__icon {
    margin-bottom: rem-calc(10);
    height: rem-calc(64);
  }
  &__header {
    min-height: 2.5em;
    display: flex;
    margin-bottom: 0.5em;
  }
  h4 {
    line-height: 1.25em;
    margin: auto;
  }

  @include breakpoint(tablet) {
    height: rem-calc(270);
    background: $white;
    border-radius: 13px;
    margin-bottom: rem-calc(32);
    color: $charcoal-dark;
    margin-left: 0;
    h3 {
      margin: rem-calc(20 0);
    }
  }
}
