.practitioner-photos {
  border-radius: 10px;
}

.id-upload {
  max-height: 100px;
  min-height: 65px;

  p {
    margin-top: 3px;
    margin-bottom: 7px;
    font-size: 0.7rem;
  }

  span {
    margin-top: 10px;
  }
}

.uploadedIds {
  list-style-position: outside;
  list-style-type: none;
  padding: 0;

  li {
    a {
      text-decoration: underline;
      align-self: center;
      max-width: 14rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: none;
      }
    }

    &:nth-child(even) {
      padding-top: 0.75rem;
    }
  }
}

.newIdUpload {
  margin-top: 1rem 0;
  display: flex;
  flex-direction: column;

  .field {
    flex: 2;
  }

  .button-group {
    display: flex;
    align-self: center;
    width: 100%;

    div {
      flex: 1 100%;
      padding: rem-calc(0 5);
    }

    .button {
      width: 100%;
    }

    @include breakpoint(laptop) {
      width: inherit;
      padding-left: 1rem;

      .button {
        width: inherit;
      }
    }
  }

  @include breakpoint(laptop) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
