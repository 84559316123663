.search__sort {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-navigation {
    flex: 1 1 40%;
    align-self: center;
    font-weight: 600;
    @include breakpoint(tablet) {
      padding: rem-calc(18);
    }
  }

  &-filters {
    display: flex;
    flex: 1 1 60%;
    padding: rem-calc(12 0 18 0);
    flex-wrap: wrap;
    justify-content: flex-end;
    &.field {
      margin-bottom: 0;
    }
    @include breakpoint(tablet) {
      padding: rem-calc(18);
    }

    div {
      margin: rem-calc(5 0);
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      .current .button__pill {
        background-color: $golden-medium;
        color: $white;

        &:hover {
          background-color: $golden-dark;
          border-color: $golden-dark;
        }

        &:focus {
          background-color: darken($golden-medium, 20%);
          border-color: darken($golden-medium, 20%);
        }
      }
      @include breakpoint(tablet) {
        text-align: right;
        flex-shrink: 0;
        flex-grow: 0;
        margin: rem-calc(0 5);
      }
    }
  }
}
