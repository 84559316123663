.appointment__fields {
  .react-datepicker {
    font-family: $global-font-family;
  }
}

.appointment__fields {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: $golden-medium;
    border-radius: 13px;
    height: rem-calc(24px);
    margin: rem-calc(4px);

    &:hover {
      background-color: $golden-dark;
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: $golden-medium;
  border-radius: 13px;

  &:hover {
    background-color: $golden-dark;
    border-radius: 13px;
  }
}

.react-datepicker__day {
  &:hover {
    border-radius: 13px;
  }

  &:focus {
    outline-color: $golden-dark;
  }
}

.react-datepicker__header {
  border-color: $charcoal-medium;
  background-color: $charcoal-medium;
  border-top-right-radius: 0;
  padding-right: 0;
  border-top-left-radius: 0;
}

.react-datepicker__navigation--previous {
  border-right-color: $charcoal-dark;
}

.react-datepicker__navigation--next {
  border-left-color: $charcoal-dark;
}
