// Heading sizes

$heading-1-font-size-desktop: 36px;
$heading-1-font-size-tablet: 30px;
$heading-1-font-size-mobile: 26px;

$heading-2-font-size-desktop: 32px;
$heading-2-font-size-tablet: 28px;
$heading-2-font-size-mobile: 24px;

$heading-3-font-size-desktop: 28px;
$heading-3-font-size-tablet: 24px;
$heading-3-font-size-mobile: 20px;

$heading-4-font-size-desktop: 20px;
$heading-4-font-size-tablet: 18px;
$heading-4-font-size-mobile: 16px;

$heading-5-font-size-desktop: 16px;
$heading-5-font-size-tablet: 14px;
$heading-5-font-size-mobile: 14px;

:export {
  heading1Desktop: $heading-1-font-size-desktop;
  heading1Tablet: $heading-1-font-size-tablet;
  heading1Mobile: $heading-1-font-size-mobile;

  heading2Desktop: $heading-2-font-size-desktop;
  heading2Tablet: $heading-2-font-size-tablet;
  heading2Mobile: $heading-2-font-size-mobile;

  heading3Desktop: $heading-3-font-size-desktop;
  heading3Tablet: $heading-3-font-size-tablet;
  heading3Mobile: $heading-3-font-size-mobile;

  heading4Desktop: $heading-4-font-size-desktop;
  heading4Tablet: $heading-4-font-size-tablet;
  heading4Mobile: $heading-4-font-size-mobile;

  heading5Desktop: $heading-5-font-size-desktop;
  heading5Tablet: $heading-5-font-size-tablet;
  heading5Mobile: $heading-5-font-size-mobile;
}
