.booking-confirmation-page {
  .booking-link:hover {
    color: $golden-dark;
    text-decoration: underline;
  }
  .booking--open {
    @include breakpoint(tablet) {
      width: 100%;
      max-width: rem-calc(600);
      display: block;
      margin: 2rem auto;
      border-radius: 13px;
      height: 90vh;
      overflow: auto;
      &.booking--small {
        height: 60vh;
      }
    }

    @include breakpoint(laptop) {
      margin: 1rem auto;
      left: 25%;
      right: 25%;
    }

    @include breakpoint(desktop) {
      height: 85vh;
    }

    .booking__title {
      padding: 1rem;

      h1 {
        font-size: rem-calc(24);
      }

      @include breakpoint(tablet) {
        padding: 1.5rem;
      }
    }
    .booking-height {
      height: auto;
      overflow: auto;
    }
    .confirmed {
      height: inherit;
      .button--fullwidth {
        width: 100%;
      }
    }
  }

  .booking__section {
    padding: 1rem;

    address {
      font-style: normal;
      ul {
        color: $charcoal-dark;
      }
    }

    @include breakpoint(tablet) {
      padding: 1.5rem;
    }

    h2 {
      font-family: $global-font-family;
      font-weight: 600;
      color: $charcoal-highlight;
    }

    .booking__price {
      font-weight: 600;

      span {
        font-weight: 600;
      }
    }
  }
}

.warning-column {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 8rem;
    margin-bottom: 1rem;
  }
}
