.form-section {
  padding-bottom: rem-calc(32);
  margin-bottom: rem-calc(32);
  border-bottom: 1px solid $light-gray;

  h2,
  h3,
  h4 {
    font-family: $global-font-family;
  }

  .medium {
    margin: 0;
    font-weight: 600;
  }
}
