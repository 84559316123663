.record-accordion {
  background-color: $white;
  border-radius: 13px;
  overflow: hidden;
  border-bottom: solid 1px $charcoal-medium;
  @include breakpoint(tablet) {
    &[open] &__header--open {
      padding-bottom: 0.25rem;
    }
  }
  &__icon {
    h3 {
      padding-left: 0.5rem;
    }

    &:before {
      content: '';
      background-size: rem-calc(18px);
      background-repeat: no-repeat;
      background-position: center;
      width: 1.5rem;
    }
    &--email {
      &:before {
        background-image: url('/static/img/icons/email.svg');
      }
    }

    &--protected {
      &:before {
        background-image: url('/static/img/icons/password.svg');
      }
    }
  }
  &__header {
    display: flex;
    position: relative;
    font-weight: 600;

    &:after {
      top: rem-calc(8);
      right: 0.25rem;
    }

    &--shadow {
      &::after {
        filter: drop-shadow(0 0 2px $charcoal-medium);
      }
    }

    &--spacing {
      &:after {
        top: rem-calc(-10);
        right: -0.5rem;
      }
    }
  }

  &__title {
    @include breakpoint(tablet) {
      font-size: 1.5rem;
    }
  }

  &__body {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &--tall {
      max-height: 100%;

      @include breakpoint(tablet) {
        max-height: 35rem;
      }
    }
  }

  &--tall {
    @include breakpoint(tablet) {
      height: initial;

      &[open] {
        height: calc(100% - 2rem);
      }

      .record-accordion__body {
        max-height: calc(100% - 4rem);
      }

      .field .client-notes__field {
        min-height: 22rem;
      }
    }
  }
}
