.toolbar {
  border-radius: 13px 13px 0 0;
  border: 1px solid $border-gray;
  border-bottom: none;
  background-color: $charcoal-highlight;
  padding: 0.75rem;

  &__button {
    &--selected {
      background-color: $green;
      border: solid 2px $green-middle;
    }

    &--flip {
      transform: scale(-1, 1);
    }
  }

  &__colour-picker {
    cursor: pointer;
    background-color: $charcoal-highlight;
    -webkit-appearance: none;
    border: none;
    width: 48px;
    height: 48px;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: 1px solid $border-gray;
      border-radius: 100%;
    }
  }

  &__output {
    padding-top: 0.25rem;
    color: $white;
  }
}
