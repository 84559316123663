.speech-bubble {
  width: 9rem;
  border: 1.5px solid $green;
  border-radius: 13px;
  padding: 0.75rem;
  background-color: $white;
  p {
    font-size: 1rem;
    line-height: 1;
    color: $green-highlight;
    font-weight: 600;
    margin-bottom: 0;
  }
  .tip {
    position: absolute;
    width: 1.5rem;
    right: rem-calc(-20px);
    top: 50%;
    box-shadow: inset 1px 0 $white;
    z-index: 2;
    border-left: 0.25rem solid $white;
  }
  @include breakpoint(tablet) {
    width: 11rem;
    p {
      font-size: 1.25rem;
    }
  }
  @include breakpoint(laptop) {
    width: 13rem;
    p {
      font-size: 1.5rem;
    }
  }
  @include breakpoint(desktop) {
    width: 15rem;
  }
}
