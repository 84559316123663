.newsletter-signup {
  background-color: $charcoal-dark;

  @include breakpoint(tablet) {
    border: 2px solid $white;
    margin: 1rem auto;
    border-radius: 13px;
  }

  &__text {
    color: $white;
    padding: 1rem 0;

    @include breakpoint(laptop) {
      padding: 2rem;
      text-align: inherit;
    }
  }

  @include breakpoint(laptop) {
    padding: 0;
    margin: 0 0.5rem;
  }

  p {
    color: $white;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 13px;

    .field {
      margin-bottom: 0.5rem;
    }

    @include breakpoint(tablet) {
      padding: 0 2rem;
    }

    @include breakpoint(laptop) {
      background: none;
      padding: 0;
      flex-direction: row;

      .field {
        margin-bottom: 0;

        &:last-child {
          grid-column: span 1;
        }
      }

      input {
        border: none;
        height: 100%;
        border: 1px solid $charcoal-medium;
        width: 22rem;
      }
    }

    @include breakpoint(desktop) {
      .field {
        input {
          width: 27.6rem;
        }
      }
    }
  }

  &__social {
    width: 100%;
    position: relative;
    padding: 0 1rem;

    &::before {
      content: none;
    }

    @include breakpoint(tablet) {
      width: auto;
      margin: auto;
      border-top: rem-calc(2) solid $white;
      padding-top: 1rem;
    }

    @include breakpoint(laptop) {
      border: none;
      text-align: right;
      padding: 3rem;
      display: flex;
      flex-direction: column-reverse;
      align-content: center;

      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $white;
        border: 2px solid $white;
        transform: skew(-26deg) translateX(-75%);
      }
    }
  }

  &__links {
    display: flex;
    width: 100%;

    div {
      flex-basis: 2rem;
      transition: 0.4s all;

      &:hover {
        filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg)
          saturate(500%) contrast(0.7);
      }

      @include breakpoint(tablet) {
        margin: rem-calc(0 6);
      }
    }

    @include breakpoint(tablet) {
      justify-content: center;
    }

    @include breakpoint(laptop) {
      justify-content: flex-end;
    }
  }
}

.gray {
  background-color: $light-gray;

  p,
  h3 {
    color: $charcoal-dark;
  }

  .newsletter-signup__text p,
  .newsletter-signup__social p {
    color: $charcoal-dark;
  }
}
