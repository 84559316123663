.section-banner {
  img {
    max-width: 100%;
    max-height: 20rem;
    object-fit: cover;
    @include breakpoint(tablet) {
      max-height: 30rem;
    }
  }
}
