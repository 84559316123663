.radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: rem-calc(16 0);

  & > input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;

    &:checked + label .radio__faux {
      background: $green url('/static/img/icons/check.svg') center / 50% auto
        no-repeat;
      border: 1px solid $green;
    }

    &:focus + label {
      outline: 1px solid $green;
    }
  }

  &__faux {
    display: inline-block;
    margin-right: rem-calc(8);
    margin-bottom: 0;
    vertical-align: middle;
    flex: none;
    width: rem-calc(32);
    height: rem-calc(32);
    border-radius: 100%;
    border: 1px solid $charcoal-medium;
    cursor: pointer;
    background-color: $white;
  }

  label {
    margin: 0;
    vertical-align: middle;
    color: $medium-gray;
    font-weight: 400;
  }
}

.radio-result {
  position: relative;
  width: 100%;

  &__wrapper {
    display: flex;
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;

    &:focus {
      & + label {
        outline: 1px dotted $golden-light;
        font-weight: 600;
        color: $golden-dark;
      }

      & + div {
        outline: 1px dotted $golden-light;
      }
    }
  }

  label {
    font-weight: 400;
    border-bottom: 1px solid $light-gray;
    padding: 0.5rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &:hover {
    label {
      font-weight: 600;
      color: $golden-dark;
      text-decoration: underline;
    }
  }
}
