.instagram-widget {
  .eapps-instagram-feed-title {
    font-family: $global-font-family;
    font-size: $heading-2-font-size-mobile;
    @include breakpoint(tablet) {
      font-size: $heading-2-font-size-tablet;
    }
    @include breakpoint(desktop) {
      font-size: $heading-2-font-size-desktop;
    }
  }
}
