.icon {
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;

  &--gold-light {
    background-color: $brand-gold-light;
  }

  &--charcoal {
    background-color: $charcoal-medium;
  }

  &--no-padding {
    padding: 0;
  }

  &--space {
    padding: 0.75rem;
  }

  &--auto {
    height: auto;
    width: auto;
  }

  &__image {
    width: 2rem;
    height: 2rem;
    &--small {
      aspect-ratio: 1 / 1;
      height: auto;
    }
  }
}
