.reporting-table {
  width: 100%;

  &__header {
    background-color: $brand-white;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;

    &--patients {
      grid-template-columns: 2fr 3fr 2fr 1.5fr 1.5fr;
    }

    &--bg {
      background-color: $white;
    }

    &--practitioners {
      grid-template-columns: 5fr 5fr 2fr;
    }

    &--members {
      grid-template-columns: 1fr;
      strong,
      a {
        word-break: break-all;
      }
      @include breakpoint(tablet) {
        grid-template-columns: minmax(40ch, 4fr) 3fr 3fr 4fr;
      }
      @include breakpoint(desktop) {
        grid-template-columns: 4fr 3fr 2fr 3fr;
      }
    }

    &--location {
      grid-template-columns: 4fr 6fr 2fr;
      column-gap: 0.5rem;
    }

    .responsive-table__heading {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        text-align: right;
      }
    }
  }

  &__body {
    @include breakpoint(tablet) {
      border: 1px solid $light-gray;
    }
    &--d {
      border: none;
      @include breakpoint(desktop) {
        border: 1px solid $light-gray;
      }
    }
  }

  &__row {
    display: grid;
    grid-gap: 0.5rem 1rem;
    grid-template: [row1-start] 'date amount' auto [row1-end] [row2-start] 'fees commission' auto [row2-end] [row3-start] 'description description' 1fr [row3-end] [row4-start] 'link link' 1fr [row4-end] / 1fr 1fr;
    margin-bottom: 0.5rem;
    border: 1px solid $light-gray;

    &:hover {
      background-color: $white;
    }

    @include breakpoint(tablet) {
      grid-template: [row1-start] 'date description amount fees commission link' 1fr [row1-end] / 1fr 3fr 1fr 1fr 1fr 1fr;
      margin: 0;
      border-radius: 0;
      border-bottom: none;
      grid-gap: 0.5rem;
    }

    @include breakpoint(laptop) {
      grid-template:
        [row1-start] 'date description amount fees commission link' 1fr [row1-end] / 1fr minmax(
          26rem,
          3fr
        )
        1fr 1fr 1fr 1fr;
    }

    &--patients {
      grid-gap: 1rem;
      grid-template: [row1-start] 'name status' auto [row1-end] [row2-start] 'email email' auto [row2-end] [row3-start] 'phone-number phone-number' auto [row3-end] [row4-start] 'patient-link patient-link' auto [row4-end] / 1fr 1fr;

      @include breakpoint(tablet) {
        grid-gap: 0.5rem 1rem;
        grid-template: [row1-start] 'name email phone-number status patient-link' 1fr [row1-end] / 2fr 3fr 2fr 1.5fr 1.5fr;
      }
    }

    &--name,
    &--email,
    &--phone-number,
    &--status,
    &--patient-link {
      a,
      span {
        text-align: left;
        text-transform: initial;
        font-size: rem-calc(16);
      }
    }

    &--prospective {
      span {
        color: $brand-white-dark;
      }

      a:not(.button) {
        color: $brand-gold-light;
      }
    }

    &--name {
      grid-area: name;

      span {
        padding: 0;
        font-size: rem-calc(18);

        @include breakpoint(tablet) {
          font-size: rem-calc(16);
        }
      }
    }

    &--email {
      grid-area: email;

      a {
        overflow-wrap: anywhere;
      }
    }

    &--phone-number {
      grid-area: phone-number;
    }

    &--status {
      grid-area: status;
    }

    &--patient-link {
      grid-area: patient-link;

      a {
        text-align: center;
      }
    }

    &--date {
      grid-row: row1-start/row1-end;
      grid-column: date-start/date-end;

      span {
        font-size: rem-calc(12);
      }

      @include breakpoint(tablet) {
        grid-row: auto;
        padding: 0;

        span {
          font-size: rem-calc(14);
          padding: 0;
          text-align: left;
        }
      }
    }

    &--description {
      grid-row: row3-start;
      grid-column: description-start/description-end;

      p {
        font-size: rem-calc(14);
        color: $charcoal-dark;
      }

      @include breakpoint(tablet) {
        grid-row: auto;
        padding: 0;

        p {
          margin-bottom: 0;
        }
      }
    }

    &--amount {
      grid-row: row1-start;
      grid-column: amount-start/amount-end;

      span {
        color: $green-highlight;
        text-align: right;
        font-size: rem-calc(16);
      }

      @include breakpoint(tablet) {
        grid-row: auto;

        span {
          font-size: rem-calc(14);
          color: $charcoal-dark;
          padding: 0;
        }
      }
    }

    &--fees {
      grid-row: row2-start;
      grid-column: fees-start/fees-end;

      span {
        color: $charcoal-dark;
        text-align: left;
        font-size: rem-calc(14);
      }

      @include breakpoint(tablet) {
        grid-row: auto;

        span {
          text-align: right;
          padding: 0;
        }
      }
    }

    &--commission {
      grid-row: row2-start;
      grid-column: commission-start/commission-end;

      span {
        color: $charcoal-dark;
        text-align: right;
        font-size: rem-calc(14);
      }

      @include breakpoint(tablet) {
        grid-row: auto;

        span {
          padding: 0;
        }
      }
    }

    .link-to-booking {
      grid-template-areas: 'link';
      grid-row: row4-start;
      grid-column: link-start/link-end;

      .button {
        width: 100%;
      }

      @include breakpoint(tablet) {
        grid-row: auto;

        a {
          font-size: rem-calc(12);

          &.button.button--outline.button--small {
            width: 100%;
            padding: 0;
            color: $golden-dark;
            font-weight: 600;
            text-decoration: underline;
            border: none;

            &:hover {
              text-decoration: none;
              background-color: $white;
            }
          }
        }
      }
    }
  }
}
