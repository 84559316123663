.dropdown {
  select {
    background-color: $golden-medium;
    border-radius: 13px 0 0 13px;
    color: $white;
    background-image: url(/static/img/icons/white/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    background-size: 1rem;
    font-weight: 600;
    font-size: rem-calc(14);
    display: block;
    width: 100%;
    appearance: none;
    border: none;
    height: 100%;
    padding: 0.5rem 1rem;
    line-height: 1.75rem;
    font-family: $global-font-family;
    &:focus {
      outline: 1px dotted $golden-light;
      outline-offset: rem-calc(4);
    }

    option {
      font-family: $global-font-family;
      font-weight: 600;
      background-color: $white;
      color: $golden-medium;
    }
  }
}
