body {
  counter-reset: step-definition-counter;
}

.step-definition {
  position: relative;
  counter-increment: step-definition-counter;
  padding: rem-calc(100) 0 0 rem-calc(50);

  &::before {
    color: $charcoal-dark;
    position: absolute;
    top: 0;
    left: 0;
    content: counter(step-definition-counter);
    font-family: $header-font-family;
    font-size: rem-calc(196);
    opacity: 0.2;
  }

  &--gold {
    position: relative;
    counter-increment: step-definition-counter;
    height: 10.6rem;

    &::before {
      color: $golden-medium;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: counter(step-definition-counter);
      font-family: $header-font-family;
      font-size: rem-calc(196);
    }
  }
  &.gray-step {
    &::before {
      color: $charcoal-medium;
    }
  }
}
