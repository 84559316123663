.record {
  background-color: $brand-white;
  padding: rem-calc(12);

  &--negative {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &--capped {
    max-height: 32rem;
    overflow-y: auto;
  }

  &--capped-full {
    max-height: 72vh;
    overflow-y: auto;
  }

  &--clipped {
    overflow-y: clip;
  }

  a.button--pill {
    color: $golden-medium;
  }

  &--set-height {
    height: 75vh;
    overflow-y: auto;
  }

  @include breakpoint(tablet) {
    padding: 0 2.5rem 2.5rem 2.5rem;
    min-height: 35rem;
    max-height: 79vh;

    &--no-min-height {
      min-height: auto;
    }

    &--max-height {
      max-height: 95vh;
      overflow-y: auto;
    }
  }

  @include breakpoint(tablet) {
    &--t-fullheight {
      height: 100vh;
      max-height: 100vh;
    }
  }

  &--padding {
    padding: rem-calc(12);

    @include breakpoint(tablet) {
      padding: 2.5rem;
    }
  }

  &--radius {
    border-radius: 13px;
  }

  &__section {
    background-color: $white;
    padding: rem-calc(14);
    border-radius: 13px;
    border-bottom: solid 1px $charcoal-medium;
    position: relative;

    @include breakpoint(tablet) {
      padding: 0.75rem;
    }

    &--grow {
      flex-grow: 1;
    }

    &--capped {
      max-height: 15rem;
      overflow-y: auto;
    }

    &--radiusless {
      border-radius: 0;
    }

    &--topless {
      padding-top: 0;
    }

    &--sideless {
      padding-right: 0;
      padding-left: 0;
    }

    &--paid {
      border: 1px solid $green;
      overflow: hidden;
    }

    &--label {
      overflow: hidden;
    }

    &--borderless {
      border-bottom: none;
    }

    &--horizontal {
      overflow-x: hidden;
    }
  }

  &__header {
    &--filled {
      padding: 1rem;
      border-radius: 13px 13px 0 0;
    }

    &--not-started {
      background-color: $white;
    }

    &--progress {
      background-color: $border-gray;
    }

    &--completed {
      background-color: $green;
    }

    &--grey {
      background-color: $brand-white;
      border-radius: rem-calc(36);
      overflow: hidden;
      padding: 0.25rem;
      max-width: 95vw;
      margin: 0 auto;
      @include breakpoint(tablet) {
        max-width: 99vw;
      }
    }
  }

  &__booking-reference {
    margin-left: auto;
  }

  &__treatments {
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;

    &--medium {
      max-height: 15rem;
    }

    &--small {
      max-height: 12rem;
    }

    &--long {
      max-height: 30rem;
    }

    &--full {
      max-height: 60vh;
    }
  }

  &__part {
    padding: rem-calc(14);
    overflow: hidden;

    @include breakpoint(tablet) {
      padding: 0.75rem;

      &--t {
        padding: 0.75rem;
      }
    }

    $partSides: top, left, bottom, right;

    @each $i in $partSides {
      &--#{$i} {
        padding-#{$i}: rem-calc(14);

        @include breakpoint(tablet) {
          padding-#{$i}: 0.75rem;
        }
      }
    }
    &--overflow {
      overflow: initial;
    }
  }

  &__row {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    .appointment-summary__comments {
      max-height: none;
      min-height: 2rem;
    }

    &--border {
      border-top: 1px solid $brand-silver;
      padding-top: 0.5rem;
      border-bottom: 1px solid $brand-silver;
    }

    &--bottom {
      border-bottom: 1px solid $brand-silver;
    }

    &--top {
      border-top: 1px solid $brand-silver;
      padding-top: 0.5rem;
    }

    &--margin {
      margin-bottom: 0.25rem;
    }
  }
  &__appointment {
    border: 1px solid $brand-white;
    border-radius: 13px;
    padding: rem-calc(12);
    font-size: rem-calc(16);

    .uppercase {
      font-size: rem-calc(16);
    }
  }
}

#main_content .testimonial {
  margin-bottom: 0;
}
