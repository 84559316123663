.diary {
  &__event {
    cursor: pointer;
    text-align: left;
    white-space: break-spaces;
    padding: 0.25rem;

    &--thirtyMinutes {
      -webkit-line-clamp: 1 !important;
    }

    &--hourlong {
      -webkit-line-clamp: 3 !important;
    }

    &--small {
      padding: 0 0.25rem;
    }

    strong {
      text-transform: capitalize;

      overflow: hidden;
      text-overflow: ellipsis;

      display: inline-block;
      word-wrap: break-word;
      white-space: normal;
      font-size: 80%;
    }

    p,
    .appointment-slot-description p {
      text-transform: uppercase;
      font-size: rem-calc(10);
      margin: 0;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;
      color: inherit;
    }

    @include breakpoint(tablet) {
      strong {
        display: block;
        white-space: pre;
      }

      small {
        font-size: 70%;
        display: block;
      }
    }

    @include breakpoint(laptop) {
      small {
        font-size: 80%;
      }
    }
  }
}

.fc-row.fc-week.fc-widget-content {
  .diary__event {
    small {
      display: none;
    }

    p {
      display: none;
    }
  }

  @include breakpoint(tablet) {
    .diary__event {
      small {
        display: inherit;
      }

      p {
        display: block;
      }
    }
  }
}

.fc-event {
  .diary__event {
    .appointment-slot-description[title] {
      text-decoration: none;
    }
  }
  &--glowday {
    --fc-event-border-color: $brand-gold-light;
    .diary__event,
    abbr {
      color: $charcoal-light !important;
    }
    background-color: $brand-gold-light !important;
    border-left: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
  }

  &--non-glowday {
    --fc-event-border-color: $ivory-medium;
    .diary__event,
    abbr {
      color: $charcoal-light !important;
    }
    background-color: $ivory-medium !important;
    border-left: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
  }

  &--completed {
    --fc-event-border-color: $ivory-medium;
    .diary__event,
    abbr {
      color: $white !important;
    }
    background-color: $charcoal-dark !important;
    border-left: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
  }

  &--other {
    --fc-event-border-color: $brand-white;
    .diary__event,
    abbr {
      color: $charcoal-dark !important;
    }
    background-color: $brand-white !important;
    border-left: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
  }

  &--pending {
    --fc-event-border-color: $rose-medium;
    .diary__event,
    abbr {
      color: $charcoal-light !important;
    }
    background-color: $rose-medium !important;
    border-left: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
  }
}

.diary-link {
  position: relative;
  width: 100%;
  text-align: center;
  display: none;
  @extend .button, .button--fullwidth;

  a {
    color: $golden-dark;
    font-weight: 400;
    text-decoration: none;
  }

  @include breakpoint(tablet) {
    background-color: transparent;
    text-align: right;
    padding-left: 0;
    display: block;

    &::before {
      content: none;
    }

    a::after {
      content: '';
      position: absolute;
      background: url('/static/img/icons/golden-dark/chevron-right.svg') center /
        cover;
      right: 0.5rem;
      top: rem-calc(12);
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      a {
        text-decoration: underline;
      }
    }
  }
}

.fc-time-grid .fc-event {
  padding: 0 !important;
}
