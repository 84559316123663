.payment-card {
  display: inline-block;
  margin-bottom: rem-calc(16);
  max-width: 23.5rem;
  flex-basis: 49%;
  flex-shrink: 1;
  border: 1px solid $border-gray;
  padding: rem-calc(8 16);
  cursor: pointer;
  height: 9rem;
  text-align: left;
  border-radius: 13px;
  margin-bottom: rem-calc(32);
  padding: 1rem;
  width: 100%;
  &--row {
    flex-basis: 95%;
    margin-right: 5%;
    height: 11rem;
    @include breakpoint(tablet) {
      flex-basis: 48%;
      margin-right: 0;
      &:nth-child(odd) {
        margin-right: 2%;
      }
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-wrapper {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @include breakpoint(tablet) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      margin: 0;
    }
    @include breakpoint(laptop) {
      flex-direction: column;
      width: inherit;
      align-items: center;
    }

    &--column {
      flex-direction: column;
    }
  }
  &__selected,
  &__expired {
    -webkit-appearance: none;
    outline: none;
    border: none;
    width: rem-calc(32);
    height: rem-calc(32);
    background: url('/static/img/icons/select-item.svg') center / cover
      no-repeat;
  }

  &__type {
    width: rem-calc(32);
    transform: scale(2);
    margin-right: 0.5rem;
    filter: brightness(0);
  }

  &__number {
    display: block;
    letter-spacing: 0.1em;
    margin: rem-calc(8 0);
    font-size: rem-calc(16);
    color: $medium-gray;
  }

  &__expiry {
    margin-bottom: rem-calc(16);

    span {
      display: block;
      font-size: rem-calc(11);
      &:first-child {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: rem-calc(10);
        color: $medium-gray;
        margin-bottom: rem-calc(4);
      }
    }
  }

  &__name {
    font-weight: 500;
    font-size: rem-calc(14);
  }

  &--selected {
    border-color: $brand-gold;
    background-color: $brand-gold;
    color: $white;
    * {
      color: $white;
    }
    .payment-card__selected {
      background: url('/static/img/icons/approve.svg') center / cover no-repeat;
    }
    .payment-card__expiry {
      span:first-child {
        color: $white;
      }
    }
  }
  &--expired {
    border-color: $red;

    .payment-card__expired {
      background: url('/static/img/icons/warning.svg') center / cover no-repeat;
    }
  }
  &--full-width {
    max-width: 100%;
  }
}
.StripeElement {
  margin: 1rem 0;
  border: 1px solid $brand-white-dark;
  font-size: 1rem;
  border-radius: 13px;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
}
