.float-button {
  position: fixed;
  z-index: 2000;

  right: 0px;
  bottom: 0px;
  overflow: hidden;
  align-items: center;
  display: flex;

  .back-to-top {
    margin: 0 2rem 0 auto;
    width: 4rem;
    position: relative;

    p {
      color: $rose-medium;
      font-size: 0.75rem;
      text-align: center;
      margin-top: 0.25rem;
    }

    img {
      width: 2rem;
      height: 2rem;
      border: 1px solid $rose-medium;
      display: block;
      margin: auto;
      border-radius: 50%;
      padding: rem-calc(5);

      &:hover,
      &:focus {
        background: rgba($rose-medium, 0.2);
      }
    }
  }
}
