.receipt {
  h5 {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  h6 {
    font-size: rem-calc(12);
    color: $charcoal-dark;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  p {
    color: $charcoal-dark;
    font-size: rem-calc(12);
  }

  .field label,
  .field input {
    font-size: rem-calc(12);
  }

  .field select,
  .field input {
    font-size: rem-calc(12);
    height: 2.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  &--item {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid $green;

    &__row {
      display: flex;
      margin-bottom: 0.25rem;

      span,
      strong {
        font-size: rem-calc(11);
      }
    }

    &__big {
      span,
      strong {
        font-size: rem-calc(14);
        font-weight: 600;
        display: block;
      }
    }
  }

  &__border {
    &--top {
      border-top: 1px solid $charcoal-medium;
    }

    &--bottom {
      border-bottom: 1px solid $charcoal-medium;
    }
  }

  &__no-treatment {
    .no-treatment-agreed-label__wrapper {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @include breakpoint(tablet) {
    border-radius: 13px;
    min-height: 13rem;
    &:last-of-type {
      min-height: auto;
      height: auto;
    }
    &__border--top,
    &__border--both {
      border-color: $light-gray;
    }
    &__border-none {
      border: none;
    }

    &--item {
      padding: 0.25rem 0;

      &__row {
        span,
        strong {
          font-size: rem-calc(14);
        }
      }

      &__big {
        span,
        strong {
          font-size: rem-calc(20);
        }

        &--green {
          strong {
            color: $green-highlight;
          }
        }
      }
    }

    &__consultation {
      background-color: $light-gray;
      border: 1px dashed $charcoal-medium;
      height: auto;

      .treatment-plan__row {
        padding-bottom: rem-calc(4);
        border-bottom: 1px dashed $charcoal-medium;
      }

      .field select {
        border-color: $charcoal-medium;

        &:hover {
          border-color: $charcoal-dark;
        }
      }

      &--item {
        border-top: 1px dashed $charcoal-medium;
      }
    }

    &__no-treatment {
      border: 1px dashed $charcoal-medium;
      overflow: hidden;
      height: 25rem;

      .treatment-plan__row {
        padding-bottom: rem-calc(4);
        border-bottom: 1px dashed $charcoal-medium;
      }

      &--item {
        border-top: 1px dashed $charcoal-medium;
      }
    }

    &__agreed {
      border: 1px solid $green;
      height: auto;

      .treatment-plan__row {
        padding-bottom: rem-calc(8);
        border-bottom: 1px solid $green;

        span {
          font-size: rem-calc(12);
        }
      }

      h6 {
        font-size: rem-calc(14);
      }

      h5 {
        font-size: 1rem;
      }

      &--item {
        border-top: 1px dashed $green;
        margin-right: 0.5rem;
      }

      .treatment__wrapper {
        height: auto;
        max-height: 10rem;
      }
    }

    &__paid {
      height: auto;
      max-height: 35rem;
      border: 1px solid $green;

      .treatment-plan__row {
        padding-bottom: rem-calc(8);
        border-bottom: 1px solid $green;

        span {
          font-size: rem-calc(12);
        }
      }

      h6 {
        font-size: rem-calc(14);
      }

      h5 {
        font-size: 1rem;
      }

      &--item {
        border-top: 1px solid $green;
        margin-right: 0.5rem;
      }

      .treatment__wrapper {
        height: auto;
        max-height: 10rem;
      }
    }
  }

  .edit {
    .button {
      padding: rem-calc(4 8);
      font-size: rem-calc(8);
    }
  }
}

.receipt__paid {
  position: relative;
  overflow: hidden;
}
