.glance-card {
  position: relative;
  z-index: 2;
  overflow: hidden;
  height: 12rem;
  width: 100%;
  border: 1px solid $white;
  border-radius: 13px;
  margin-bottom: rem-calc(48);

  &__title {
    font-family: $global-font-family;
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    font-size: rem-calc(12);
    line-height: 1.5;
    margin-bottom: 0;

    @include breakpoint(tablet) {
      font-size: rem-calc(14);
    }
  }

  &__icon {
    margin-bottom: 2em;
    width: 5em;
    height: 5em;

    @include breakpoint(tablet) {
      position: absolute;
      width: rem-calc(48);
      height: rem-calc(48);
      bottom: rem-calc(0);
      left: rem-calc(16);
    }
  }

  @include breakpoint(tablet) {
    height: rem-calc(180);
    background: $white;
    border-radius: 13px;
    margin-bottom: rem-calc(32);
    border: none;
    color: $charcoal-dark;
    margin-left: 0;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 235%;
      height: 100%;
      background: $bronze-light;
      transform: skew(-26deg) translateX(30%);
      transition: 0.4s all;
    }

    &:hover {
      &::before {
        transform: skew(-26deg) translateX(-25%);
      }
    }

    &__title {
      color: $charcoal-dark;
    }
  }

  &__block {
    padding: rem-calc(16);
    width: rem-calc(200);
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem-calc(16);

    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    font-family: $global-font-family;
    font-size: rem-calc(16);
    font-weight: 400;
    margin-bottom: 0;

    &.wrapper {
      height: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .tooltip {
    position: absolute;
    bottom: rem-calc(16);
    right: rem-calc(16);
  }
}
