.sidebar-article {
  display: flex;
  align-items: center;
  &__title {
    flex: 0 0 60%;

    h4 {
      font-size: 0.75rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @include breakpoint(laptop) {
        font-size: 1rem;
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  &__image {
    flex: 0 0 40%;
  }
  img {
    height: 100%;
    aspect-ratio: 16 / 9;
  }
}
