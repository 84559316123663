@use 'sass:math';

$global-font-size: 16px;

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8, $direction: to bottom) {
  background-color: $to;
  background-image: linear-gradient($direction, $from, $to);
}

@mixin css-shadow($color: $charcoal-dark, $opacity: 0.2) {
  box-shadow: 1px 2px 8px 0 rgba($color, $opacity);
}

// shamelessly ripped from Foundation
@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $global-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: math.div($base, 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
  // Pixel and unitless values are converted to rems
  @if unit($value) == 'px' or unitless($value) {
    $value: rem-calc($value, $base: 16px);
  }

  // Then the value is converted to ems
  @return strip-unit($value) * 1em;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}

// breakpoints

@function break($key) {
  @return map-get($breakpoints, $key);
}

@mixin breakpoint($point, $rule: 'min') {
  $keys: map-keys($breakpoints);
  $valid-point: false;
  $valid-rule: false;
  // validate
  @each $key in $keys {
    @if (str-index($key, $point)) {
      $valid-point: true;
    }
    @if (str-index($key, $rule)) or $rule == 'only' {
      $valid-rule: true;
    }
  }
  // feed errors back
  @if $point == 'desktop' and $rule == 'only' {
    @error "You supplied 'desktop' and 'only' - there is no 'desktop-max' breakpoint.";
  }

  @if not $valid-rule or not $valid-point {
    $breakpoint-key: #{$point}-#{$rule};
    @error "#{$breakpoint-key} wasn't found. Please check $breakpoints map in settings.scss.";
  }

  // the media query
  @if $rule == 'only' {
    @media (min-width: break(#{$point}-min)) and (max-width: break(#{$point}-max)) {
      @content;
    }
  } @else {
    @media (#{$rule}-width: break(#{$point}-#{$rule})) {
      @content;
    }
  }
}

// font sizes

@mixin font-size($fs-map) {
  $keys: map-keys($breakpoints);

  @each $key, $fs-font-size in $fs-map {
    @if $key == null {
      font-size: $fs-font-size;
      line-height: $header-line-height;
    } @else {
      @media screen and (min-width: break(#{$key}-min)) {
        line-height: $header-line-height;
        font-size: rem-calc($fs-font-size);
      }
    }
  }
}
