.search {
  &__navigation {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0.5, 1) 0.5s;
    &--gold {
      background-color: $brand-gold-light;
      transition: background-color 0.2s ease-in-out;
    }
    &--gray {
      background-color: $light-gray;
      transition: background-color 0.2s ease-in-out;
    }

    &.animation {
      max-height: rem-calc(500);
      overflow: inherit;
    }
  }

  &-widget {
    &__navigation {
      &--sticky {
        display: flex;
        justify-content: flex-start;

        @include breakpoint(laptop) {
          justify-content: space-evenly;
        }

        div {
          text-align: center;
          margin-right: 0.25rem;
          @include breakpoint(tablet) {
            align-self: center;
            margin-right: 0;
          }
        }
        .button--modal__close {
          top: 0;
          width: 2.25rem;
          height: 2.25rem;
        }
        .button--pill {
          padding: rem-calc(8 14);
          font-weight: 400;

          &:hover {
            background: $charcoal-medium;
            color: $golden-dark;
            border-color: $golden-dark;
          }
        }

        .current .button--pill:hover {
          color: $white;
        }
      }
    }

    &__form {
      align-items: center;
      display: flex;
      width: auto;
      flex-direction: column;

      & > div {
        margin: 0;
        min-width: 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 100%;
      }

      @include breakpoint(tablet) {
        flex-direction: row;
      }

      & > div {
        padding: 0.5rem 0.25rem;
        position: relative;

        input {
          border: 1px solid $charcoal-medium;
          padding-left: rem-calc(40);

          &:focus {
            border-color: $golden-dark;

            ~ .field--search__items {
              border-color: $golden-dark;
            }
          }

          &.search {
            background: $white url('/static/img/icons/golden-dark/search.svg')
              rem-calc(12) 50% / auto 16px no-repeat;
          }

          &.location {
            background: $white url('/static/img/icons/golden-dark/location.svg')
              rem-calc(12) 50% / auto 16px no-repeat;
          }
        }
      }

      .field,
      input {
        margin: 0;
      }

      .field {
        &:hover {
          input {
            border-color: $charcoal-dark;
          }

          .field--search__items {
            border-color: $charcoal-dark;
          }
        }
      }

      .button {
        padding: rem-calc(13);
      }

      .field--search__items--open {
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: rem-calc(-10);
        border: 1px solid $charcoal-medium;
        border-top: none;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;

        @include breakpoint(tablet) {
          margin-top: 0;
          position: absolute;
          z-index: 10;
          top: 75%;
        }
      }
      .search-widget__results {
        justify-content: flex-start;
        .logo--container {
          width: inherit;
          margin-right: 1rem;
        }
      }
    }
  }
}

.fake-input {
  background: $white url('/static/img/icons/golden-dark/search.svg')
    rem-calc(12) 50% / auto 16px no-repeat;
  border: 1px solid $charcoal-medium;
  border-radius: 13px;
  font-family: $global-font-family;
  line-height: rem-calc(24);
  font-size: rem-calc(16);
  padding: rem-calc(8 12);

  span {
    color: $charcoal-medium;
  }
}

.cluster {
  width: rem-calc(20px);
  height: rem-calc(20px);
  color: $white;
  background: #f28a25;
  border-radius: 20px;
}

.appear-on-scroll {
  .search__navigation {
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0, 1, 0.5, 1) 0.25s,
      max-height 0.5s cubic-bezier(0, 1, 0.5, 1),
      background-color 0.2s ease-in-out;
    overflow: hidden;
    &.animation {
      opacity: 1;
      overflow: inherit;
    }
  }
}
