.confirmation-page {
  margin-top: -2rem;

  &__hero {
    height: rem-calc(750);
    background-color: $charcoal-dark;
    position: relative;

    h1,
    h2,
    h3,
    p,
    ul,
    li {
      color: $white;
    }

    h2 {
      font-size: $heading-2-font-size-mobile;
      font-family: $header-font-family;
      font-weight: 400;
    }

    &--image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      clip-path: polygon(74% 0, 100% 0%, 100% 100%, 50% 100%);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
    }
  }

  .clinic-icon-wrapper {
    display: flex;
    background-color: $white;
    border-radius: 100%;
    align-items: center;
    width: 7em;
    height: 7em;
    overflow: hidden;
    border: 1px solid $light-gray;
  }

  .profile-photo-wrapper .clinic-icon-wrapper {
    border: 1px solid $charcoal-medium;
    margin: -6rem auto 1rem auto;

    img {
      margin: 0;
      height: auto;
      border: none;
    }

    @include breakpoint(tablet) {
      margin: -8rem auto 1rem auto;
      width: 12rem;
      height: 12rem;

      img {
        width: 12rem;
      }
    }
  }
}

.practitioner-profile {
  .profile-photo-wrapper {
    height: auto;

    img {
      margin: 1rem auto;
    }
  }
}

.profile {
  .builder-section {
    h3 {
      color: $medium-gray;
      margin-top: rem-calc(8);
    }
  }

  &-photo-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;

    img {
      margin: -6rem auto 1rem auto;
      border: 1px solid $charcoal-medium;
    }

    @include breakpoint(tablet) {
      img {
        margin: -8rem auto 1rem auto;
        width: 12rem;
        height: 12rem;
      }
    }
  }

  &-photo {
    display: flex;
    align-items: flex-end;
    padding: 1rem 1rem 0 1rem;
    border-radius: 13px 13px 0 0;
    background-color: $charcoal-dark;
    padding-top: 62.5%;
    height: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint(tablet) {
      height: 20rem;
    }
  }
}
