.location {
  .clinic-details-container {
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    @include breakpoint(laptop) {
      flex-direction: row;
    }

    .clinic-logo {
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        border: 4px solid $white;

        @include breakpoint(tablet) {
          width: rem-calc(104);
          @supports not (aspect-ratio: 1/1) {
            height: rem-calc(104);
          }
        }
      }

      @supports not (aspect-ratio: 1/1) {
        position: relative;
        padding-top: 50%;
        height: 0px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 10rem;
          max-height: 10rem;
        }
      }

      @supports (aspect-ratio: 1/1) {
        img {
          aspect-ratio: 1 / 1;
        }
      }
    }

    .clinic-details {
      address {
        font-size: 1rem;
        line-height: 1.5;
        font-style: normal;
      }
    }
  }

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    // needed for tablet/mobile view as the map's info elements appear ontop of the booking footer
    z-index: 0;
  }
}
