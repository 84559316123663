.booking {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: rem-calc(82);
  transition: 0.4s all;
  pointer-events: none;
  overflow: auto;

  &__treatment {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted $brand-white;

    &:last-child {
      border-bottom: 0;
    }
  }

  @include breakpoint(laptop) {
    position: sticky;
    top: 7rem;
    left: auto;
    bottom: auto;
    right: auto;
    max-width: 20rem;
    min-height: auto;
    pointer-events: initial;

    &--wide {
      max-width: 23rem;

      footer.booking__footer::before {
        background-color: $brand-white;
        border-left: 1px solid $brand-white;
        border-right: 1px solid $brand-white;
      }

      @include breakpoint(laptop) {
        &.booking .confirmed {
          height: 46rem;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 4;

    @include breakpoint(laptop) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    visibility: hidden;

    @include breakpoint(laptop) {
      height: auto;
      visibility: visible;
    }
  }

  &__date--overflow {
    .datepicker header {
      padding: 0.5rem;
    }

    .datepicker__slots {
      max-height: 4.5rem;
      overflow-y: auto;

      @media only screen and (min-height: $grid-break-point-mobile-medium-height - 2.5) and (max-width: $grid-break-point-tablet) {
        max-height: 12rem;
        height: auto;
      }

      @include breakpoint(tablet) {
        max-height: 12rem;
      }
    }
  }

  &__main {
    position: absolute;
    width: calc(100% - 0.5rem);
    transform: translateY(200%);
    transition: 0.4s all;
    visibility: hidden;
    opacity: 0;
    background: $white;
    height: 0;

    @include breakpoint(laptop) {
      position: relative;
      transform: none;
      top: 0;
      left: 0.25rem;
      right: 0.25rem;
      bottom: 0;
      visibility: visible;
      height: auto;
      opacity: 1;
      border-left: 1px solid $brand-white;
      border-right: 1px solid $brand-white;
      border-top: 1px solid $brand-white;
      border-radius: 13px 13px 0 0;
    }
  }

  &__process {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    color: $medium-gray;
    height: 3rem;
    border-bottom: 1px solid $brand-white;

    @include breakpoint(laptop) {
      padding: rem-calc(24 16);
    }

    li {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: rem-calc(10);
      text-align: center;
      font-weight: 700;
      position: relative;
      display: table-cell;
      text-align: center;

      &::before {
        margin: 0 auto rem-calc(4);
        content: '';
        display: block;
        background: $charcoal-medium;
        border: 2px solid $charcoal-medium;
        color: $charcoal-medium;
        width: rem-calc(16);
        height: rem-calc(16);
        text-align: center;
        line-height: 1.9rem;
        border-radius: 100%;
        position: relative;
        z-index: 1;
        font-weight: 700;
        font-size: 1rem;
      }

      &::after {
        content: '';
        height: rem-calc(1);
        position: absolute;
        display: block;
        background: $charcoal-medium;
        width: 100%;
        top: rem-calc(8);
        left: 50%;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.active {
        color: $green-highlight;

        &::before {
          border-color: $green;
          background-color: $white;
        }
      }

      &.complete {
        color: $green-highlight;

        &::before {
          border-color: $green;
          background-color: $green;
        }
      }
    }
  }

  &__or {
    display: block;
    color: $brand-charcoal-light;
    height: rem-calc(24);
    font-size: rem-calc(14);
    line-height: rem-calc(24);
    margin: rem-calc(16) auto;
    font-weight: 700;
    text-align: center;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    &:before {
      content: '';
      margin: 0 auto;
      position: absolute;
      border-top: 1px solid $brand-silver;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1;
    }
    &:after {
      content: '';
      margin: 0 auto;
      position: absolute;
      background-color: $white;
      width: 5ch;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  &__stripe {
    position: relative;
    display: block;
    border-radius: 13px;
    transition: border-color 0.3s;
    font-family: $global-font-family;
    width: 100%;
    color: $golden-dark;
    background-color: $white;
    border: 1px solid $charcoal-medium;
    font-size: rem-calc(16);
    padding: rem-calc(8 12);
    margin-bottom: rem-calc(10);
    height: rem-calc(44);
    line-height: rem-calc(24);
    -webkit-appearance: none;

    &:hover {
      border-color: $charcoal-dark;
    }

    &:focus {
      border-color: $golden-dark;
      outline: none;
    }
  }

  &__section {
    padding: rem-calc(10 24);

    &--marketing {
      .field label {
        font-size: rem-calc(12);
      }
    }

    &--customer-comments {
      .field label {
        font-size: rem-calc(12);
      }
      .field textarea {
        font-size: rem-calc(14);
      }
    }

    @media only screen and (min-height: $grid-break-point-mobile-medium-height) {
      padding: rem-calc(16 32);
    }

    @include breakpoint(tablet) {
      max-width: 75%;
      margin: 0 auto;
    }

    @include breakpoint(laptop) {
      padding: rem-calc(16);
      max-width: 100%;

      &--marketing {
        padding-bottom: 0;

        .radio {
          margin-top: 0.5rem;
        }
      }
    }

    .field {
      margin: 0;

      &__stripe {
        input {
          position: relative;
          display: block;
          border-radius: 13px;
          transition: border-color 0.3s;
          font-family: $global-font-family;
          width: 100%;
          color: $golden-dark;
          background-color: $white;
          border: 1px solid $charcoal-medium;
          font-size: rem-calc(16);
          padding: rem-calc(8 12);
          margin-bottom: rem-calc(10);
          height: rem-calc(44);
          line-height: rem-calc(24);
          -webkit-appearance: none;

          &:hover {
            border-color: $charcoal-dark;
          }

          &::placeholder {
            color: $stripe-placeholder-color;
          }

          &:focus {
            border-color: $golden-dark;
            outline: none;
          }
        }
      }
    }

    .button {
      width: 100%;
      margin-bottom: rem-calc(8);
    }

    .social-login {
      .button {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $charcoal-highlight;

        &.google {
          border-color: #ea4335;
        }

        &.facebook {
          border-color: #3a589c;
        }
      }

      img {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }

    .policy {
      &__content {
        p {
          margin: 0;
          text-align: justify;
          font-weight: bolder;
          font-size: rem-calc(12);
        }
      }
    }

    p {
      text-align: center;
      font-size: rem-calc(14);
      margin-top: rem-calc(32);
      font-weight: 500;

      &.error--card {
        margin-top: 0;
      }
    }
  }

  &__border {
    border-bottom: 1px solid $brand-white;
  }

  .confirmed {
    padding: rem-calc(8 16);
    height: 30rem;

    .booking__section {
      border-bottom: 1px solid $brand-white;
      padding: 0.5rem 1rem;
    }

    .booking__fill {
      padding: 0;
    }

    .booking__summary {
      padding: 0;
    }
  }

  &__total {
    margin: 0 auto;
    background: $brand-white;
    padding: rem-calc(8 16);
    border-radius: 13px;
    font-weight: 300;
    font-size: rem-calc(24);
    letter-spacing: 0.05em;
    display: inline-block;

    @include breakpoint(laptop) {
      background: $white;
    }
  }

  &__stage {
    display: none;

    &--active {
      display: block;

      @include breakpoint(tablet) {
        height: calc(100% + 4rem);
      }

      @include breakpoint(laptop) {
        min-height: 21rem;
      }
    }
  }

  footer {
    pointer-events: all;
    background: $brand-white;
    padding: rem-calc(16);
    text-align: center;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;

    @include breakpoint(laptop) {
      position: relative;
      bottom: auto;
      margin: 0 0.25rem;
      border-radius: 0 0 13px 13px;
      width: calc(100% - 0.5rem);

      &::before {
        content: '';
        position: absolute;
        height: rem-calc(12px);
        background-color: $brand-white;
        width: 100%;
        left: 0rem;
        top: -0.5rem;
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      width: auto;
    }
  }

  &__time {
    display: block;
    margin-bottom: rem-calc(8);
    font-size: rem-calc(14);

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: rem-calc(8);
      width: rem-calc(16);
      height: rem-calc(16);
      background: url('/static/img/icons/time.svg') center / contain no-repeat;
    }
  }

  &__duration {
    display: inline-block;
    background: $brand-gold;
    color: $white;
    padding: rem-calc(4 8);
    border-radius: 999px;
    font-weight: 500;
    font-size: rem-calc(12);
    vertical-align: middle;
  }

  &__heading {
    display: block;
    color: $charcoal-dark;
    font-size: rem-calc(14);
    margin-bottom: rem-calc(4);
  }

  &__description {
    display: block;
    color: $medium-gray;
    font-size: rem-calc(14);
  }

  &__categories {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: rem-calc(14);
        margin-bottom: 0.25rem;
      }

      ul {
        font-size: rem-calc(12);
        margin-bottom: 1rem;
        color: $charcoal-dark;

        li {
          margin: 0.5rem 0;
          border-bottom: 1px dotted $border-gray;

          .treatments-container {
            display: flex;
            width: 100%;

            .treatment-name {
              font-size: 0.875rem;
            }

            .treatment-price {
              margin-left: auto;
              font-size: 0.875rem;
            }
          }
        }
      }
    }

    .booking__price {
      flex-direction: row;

      span {
        padding: 0;
        margin: 0;
      }

      .total-cost {
        letter-spacing: normal;
      }

      .pricing {
        margin-left: auto;
      }
    }
  }

  &__price {
    font-size: rem-calc(24);
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1;
    text-align: center;

    span {
      margin: rem-calc(7);
      vertical-align: top;
      font-weight: 500;
    }

    @include breakpoint(laptop) {
      background-color: $brand-white;
    }

    &--background {
      background-color: $white;
    }

    &--mobile {
      width: 90%;
      margin: 0 auto;
      border-radius: 13px;
      background-color: $brand-white;

      &--total {
        padding: 0.5rem 1rem;
        display: inline-block;
        font-weight: 600;
      }

      @include breakpoint(tablet) {
        margin-bottom: 0.25rem;
        width: 60%;
      }

      @include breakpoint(laptop) {
        width: 100%;
        margin-bottom: 0;
        border-radius: 0;
      }
    }
  }

  &__practitioner {
    display: inline-block;
    width: rem-calc(48);
    margin-right: rem-calc(8);
    vertical-align: middle;
    border-radius: 100%;
  }

  &__date {
    display: block;
    font-size: rem-calc(14);
    color: $golden-dark;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: rem-calc(8);
      width: rem-calc(16);
      height: rem-calc(16);
      background: url('/static/img/icons/date.svg') center / contain no-repeat;
    }
  }

  &__confirmed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: unset;

    .button {
      width: auto;
    }
  }

  &__fill {
    text-align: center;
    padding: rem-calc(32);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      display: inline-block;
      width: rem-calc(48);
    }

    &--bookingRef {
      font-size: rem-calc(32);
      color: $charcoal-dark;
    }

    span {
      font-weight: 700;
      margin-bottom: rem-calc(16);
    }
  }

  &__footer {
    &:last-child() {
      margin-top: 0;
    }

    &--active {
      display: block;
    }
  }

  .field--inline {
    margin: 0;
    justify-content: start;

    .checkbox {
      margin: 0;
    }

    .radio {
      margin-left: 0;
      margin-right: 2rem;
    }
  }

  &--open {
    pointer-events: all;
    background: $white;
    z-index: 11;
    overflow: auto;

    @include breakpoint(laptop) {
      min-height: 0;
      border-radius: 13px;
      position: fixed;
      overflow: hidden;
      width: 20rem;
      padding-right: 0;
      right: 2rem;

      &__complete {
        .confirmed {
          height: 28rem;

          & > .booking__summary {
            padding: 1rem;
          }
        }
      }
    }

    @include breakpoint(large) {
      right: 30rem;
    }

    .booking {
      &__title {
        visibility: visible;
        padding: 1.25rem 2rem 0 2rem;

        @include breakpoint(tablet) {
          text-align: center;
        }

        @include breakpoint(laptop) {
          text-align: left;
        }
      }

      &__main {
        transform: none;
        visibility: visible;
        opacity: 1;
        border: 0;
        box-shadow: none;
        height: 46rem;

        @include breakpoint(tablet) {
          height: 29rem;
        }

        @include breakpoint(laptop) {
          height: auto;
        }
      }
    }

    footer {
      border-radius: 0;

      @include breakpoint(tablet) {
        height: 6rem;
        overflow-y: auto;
        padding-top: 1.5rem;
        box-shadow: none;
      }

      @include breakpoint(laptop) {
        height: auto;
        padding: 1rem;
        overflow-y: initial;
        box-shadow: none;
      }
    }

    .disclaimer {
      border-radius: 0 0 13px 13px;
      background-color: $brand-white;
    }
  }
}

@include breakpoint(mobile) {
  .exit-booking {
    bottom: 0;
    position: fixed;
    padding: 1rem;
    background-color: $brand-white;
    width: 100%;
  }
}

@include breakpoint(laptop) {
  .exit-booking {
    position: static;
    width: calc(100% - 0.5rem);
    margin: 0 0.25rem;
    box-shadow: 1px 2px 8px 0 rgba($charcoal-dark, 0.2);
    border-radius: 0 0 13px 13px;
    overflow: hidden;

    .text-center {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: rem-calc(9px);
        background-color: $brand-white;
        width: 20rem;
        left: -1rem;
        top: -1rem;
      }

      button {
        border: none;
        background-color: transparent;
        font-weight: 600;
        text-decoration: underline;
        color: $golden-dark;
      }
    }
  }
}

.booking-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($charcoal-dark, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: 6;
  transition: 0.4s all;

  &--active {
    visibility: visible;
    opacity: 1;
  }
}

.cancellation {
  display: block;
  text-align: center;
  margin-top: rem-calc(32);
  font-weight: 500;
  color: $medium-gray;
  text-decoration: none;
}

.disclaimer {
  padding: rem-calc(10 0 20 0);
  text-align: center;
  display: block;
}

.payment-expired {
  padding: rem-calc(10 0 20 0);
}

.payment-disclaimer {
  font-size: rem-calc(13);
  margin: 0 auto;

  p {
    line-height: 1.25;
    font-size: rem-calc(13);
    text-align: left;
    margin-top: rem-calc(8);
  }
}
