.practitioner-select {
  border: 1px solid $charcoal-medium;
  border-radius: rem-calc(13);
  height: rem-calc(40);
  overflow: hidden;
  position: relative;
  &:focus {
    outline: 1px dotted $golden-medium;
    outline-offset: rem-calc(4);
  }
  &:hover {
    border-color: $charcoal-dark;
    p {
      color: $charcoal-dark;
    }
    &.closed::after {
      content: '';
      background: url('/static/img/icons/chevron-right.svg') center / cover;
    }
  }

  &.closed {
    position: relative;
    &::after {
      content: '';
      top: 0.75rem;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 1rem;
      z-index: 1;
      background: url('/static/img/icons/charcoal-medium/chevron-right.svg')
        center / cover;
      transition: all 0.4s;
    }
  }
  &.open {
    position: relative;
    &::after {
      content: '';
      top: 0.75rem;
      position: absolute;
      width: 1rem;
      height: 1em;
      right: 1rem;
      z-index: 1;
      background: url('/static/img/icons/charcoal-medium/chevron-right.svg')
        center / cover;
      transform: rotate(-90deg);
      transition: all 0.4s;
    }
  }
  p {
    color: $charcoal-medium;
  }

  &__new-appointment {
    background-color: $white;
    p {
      color: $charcoal-dark;
    }
  }

  &__open {
    position: absolute;
    max-width: 100%;
    z-index: 2;
    background: $white;
    border-radius: 0 0 13px 13px;
    padding: 0.5rem;
    left: 1rem;
    right: 1rem;

    &--wrapper {
      max-height: 15rem;
      padding: 0.25rem;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__default {
    position: relative;
    & > img,
    & > span {
      position: absolute;
      left: rem-calc(4);
      top: rem-calc(3);
      width: rem-calc(32);
      height: rem-calc(32);
    }
    & > span {
      font-size: 1rem;
      line-height: 2rem;
    }
    p {
      line-height: 2.5rem;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    border-bottom-color: $charcoal-medium;
    padding: 0.25rem 0.5rem;
    color: $golden-medium;
    &:focus {
      outline: 1px dotted $golden-medium;
    }
    p {
      color: $charcoal-medium;
      transition: all 0.4s;
    }

    img,
    span {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.25rem;
      line-height: 2.5rem;
    }
    &:hover {
      p {
        transition: all 0.4s;
        color: $golden-dark;
      }
    }
    &.selected {
      p {
        color: $golden-dark;
      }
      border-color: $golden-dark;
    }
  }
}
