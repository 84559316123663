.spinner {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;

  &--centered {
    display: block;
    margin: 0 auto;
  }

  div {
    animation: spinner 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 1.5rem 1.5rem;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: rem-calc(4px);
      height: rem-calc(4px);
      border-radius: 50%;
      background: $golden-dark;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: rem-calc(36px);
      left: rem-calc(32px);
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: rem-calc(40px);
      left: rem-calc(26px);
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: rem-calc(40px);
      left: rem-calc(18px);
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: rem-calc(36px);
      left: rem-calc(12px);
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
