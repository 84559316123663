/* "primary" coloring */
.fc-button-primary {
  color: $brand-gold !important;
  background-color: $white !important;
  border: none !important;
  transition: 0.4s all;

  &:hover {
    background-color: $light-gray !important;
  }

  &:disabled {
    color: $white !important;
    background-color: $brand-gold-light !important;
  }

  @include breakpoint(tablet) {
    border: 1px solid $brand-gold !important;
  }
}

.fc-today {
  background-color: $white !important;
  & > span {
    font-weight: 900;
  }
}

#main_content .fc-toolbar h2 {
  font-size: 1.2rem;
  display: inline;
  font-family: $global-font-family;
  font-weight: 600;

  @include breakpoint(tablet) {
    font-size: 1.4rem;
  }
}
.fc-customPrev-button,
.fc-customNext-button,
.fc-prev-button,
.fc-next-button {
  border: none !important;
  font-size: 1rem !important;
  color: $brand-gold;
}

.fc-customNext-button.fc-button.fc-button-primary {
  @include breakpoint(tablet) {
    margin-left: 0.5rem !important;
  }
}

.fresnel-lessThan-md {
  .fc-button {
    padding: rem-calc(7px);
  }

  .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
}

// OWN STYLES

.fc-widget-header {
  height: 2rem !important;
  font-weight: 400 !important;
}

.fc-customMonthView-button {
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
}

.fc-customTodayView-button {
  border-top-right-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}

.fc th {
  vertical-align: middle !important;
}

.fc-time-grid .fc-slats td {
  height: 2.5rem !important;
}

.fc-dayGridMonth-view .fc-dayGrid-view {
  p {
    display: none;
  }

  small {
    display: none;
  }

  strong {
    display: none;

    @include breakpoint(tablet) {
      font-size: rem-calc(10);
    }
  }
}

.fc .fc-row .fc-content-skeleton tbody td {
  height: 2rem;

  @include breakpoint(tablet) {
    min-height: 4rem;
  }
}

.fc-timeGridWeek-view {
  @include breakpoint(tablet) {
    .diary--event {
      strong {
        text-overflow: inherit;
      }
    }
  }
}

.fresnel-greaterThanOrEqual-md {
  table tbody tr td:last-child {
    border-radius: 0;
  }

  .fc-ltr .fc-time-grid .fc-event-container {
    margin-right: 25%;
  }
}
.fresnel-lessThan-md {
  .fc-ltr .fc-time-grid .fc-event-container {
    margin-right: 25%;
  }
}

.fresnel-lessThan-md {
  .column--relative {
    .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column-reverse;
    }

    button.fc-button.fc-button-active {
      background-color: $light-gray;
      text-decoration: underline;
    }
  }
}

.fc-time-grid .fc-event {
  padding: rem-calc(2);
  overflow: hidden;
}

.fc-left > div {
  display: flex;
  align-items: center;
}

.fc-timegrid-slot {
  height: 3rem !important;
}

.fc-col-header-cell-cushion {
  color: $charcoal-dark;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
}
.fc-day-today {
  background-color: $white !important;
  .fc-col-header-cell-cushion {
    font-weight: 600;
  }
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}

.fc-event {
  padding: 0.25rem;
  text-align: left;
  font-weight: 400;
  &:not(.fc-bg-event) {
    width: 80% !important;
    min-width: calc(100% - 2.5em) !important;
  }
  border-radius: rem-calc(4) !important;
  &.fc-bg-event {
    border-radius: 0 !important;
  }
}
.fc-daygrid-day-number {
  text-decoration: none;
  color: $charcoal-dark;
}
.fresnel-container.fresnel-lessThan-md .fc-timeGridDay-view > {
  .fc-event {
    width: inherit !important;
    padding: 0.01;
    font-size: rem-calc(9);
  }
}
