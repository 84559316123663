.treatment-category {
  border-radius: 13px;
  background-color: $light-gray;
  border: 1px solid $light-gray;
  font-size: rem-calc(12);
  color: $charcoal-highlight;

  &__heading {
    height: 3.25rem;
    display: flex;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  &__body {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  &[open] {
    .treatment-category__heading {
      background-color: $brand-gold;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      h3,
      .price {
        color: $white;
      }
    }
  }

  h3 {
    color: $charcoal-dark;
    font-size: rem-calc(14);
  }

  &__name-btn {
    padding: 0;
    text-align: left;
    border: none;
    font-size: 0.75rem;
    color: $charcoal-highlight;
    font-weight: bolder;
    font-family: $global-font-family;
    cursor: pointer;
    background-color: transparent;
    &--disabled {
      cursor: not-allowed;
    }

    &:focus {
      outline: 1px dashed $brand-gold;
      outline-offset: 0.125rem;
    }
  }

  summary {
    &::after {
      top: 0.5rem;
      right: 0.75rem;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 0;

    .grid {
      display: flex;
    }

    .gap {
      margin-right: 0.5rem;
      align-self: center;
    }

    small.uppercase {
      font-size: rem-calc(10);
    }

    .price.uppercase {
      font-size: rem-calc(10);
    }

    // the grid space available minus the width of the chevron icon
    .spacing-right-chevron {
      flex-basis: calc(50% - 3rem);

      @include breakpoint(tablet) {
        flex-basis: calc(100% / 12 * 5 - 3rem);
      }
    }

    .checkbox {
      margin: 0 0 0 auto;

      &__faux {
        background-color: $white;
        margin-right: 0;
      }
    }
  }

  &__tooltip {
    display: inline-flex;
    .accessible-tooltip {
      width: 1.5rem;
      height: 1.5rem;

      @include breakpoint(laptop) {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
