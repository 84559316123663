.session-row {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px dotted $charcoal-medium;
  }

  &__button {
    text-align: left;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    color: $charcoal-dark;
  }
}
