.templates-bar {
  border-radius: 0 0 13px 13px;
  border: 1px solid $border-gray;
  border-top: none;
  background-color: $charcoal-highlight;
  padding: 0.75rem;

  &__hint {
    margin-top: 0.5rem;
    color: $white;
  }

  &__image {
    padding: 0.25rem;
    background-color: $brand-white;
    border: 1px solid $border-gray;

    &:hover {
      background-color: $brand-white-dark;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
    }

    &--selected {
      background-color: $green;
      border: 1px solid $green-highlight;

      &:hover {
        background-color: $green-middle;
      }
    }
  }
}
