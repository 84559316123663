.additional-treatment-item {
  padding: 0;
  @include breakpoint(tablet) {
    padding: 1.25rem 2rem;
  }
  @include breakpoint(laptop) {
    padding: 0;
  }
  input:disabled {
    color: $charcoal-dark;
    cursor: not-allowed;
    &:hover {
      border-color: $light-gray;
    }
  }
  .pound {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    font-size: 1rem;
    line-height: 2.75rem;
    color: $charcoal-medium;
  }
  .cost-field {
    input {
      padding-left: 2rem;
    }

    &:hover {
      .pound {
        transition: 0.2s all;
        color: $charcoal-dark;
        &.disabled {
          color: $charcoal-dark;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;

    div {
      flex: 1 1 auto;
      .button {
        font-size: rem-calc(12);
      }
    }
  }

  .remove-button {
    .round-button {
      display: block;
      margin-left: auto;
    }
    @include breakpoint(tablet) {
      padding: rem-calc(4);
      .round-button {
        width: 1.75rem;
        height: 1.75rem;
        position: relative;
        margin-left: inherit;
        img {
          width: 0.95rem;
        }
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }
}
