.data-row {
  border-radius: 13px;
  padding: 1rem;

  span:not(.label) {
    padding: 0.25rem 0;
    margin: 0.25rem 0;
    display: block;
    text-align: left;
    text-transform: capitalize;
    font-size: rem-calc(11);

    @include breakpoint(tablet) {
      border: none;
      text-align: center;
      padding: 0 1rem;
      margin: 0;
    }

    @include breakpoint(laptop) {
      font-size: rem-calc(14);
    }
  }

  &__bold {
    font-weight: 600;

    span {
      text-align: center;
    }
  }

  .text-center span {
    text-align: center;
  }

  &__treatment {
    padding: 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(tablet) {
      padding: 0 1rem;
      margin: 0;
    }

    &:hover,
    &:focus {
      overflow: auto;
      text-overflow: unset;
    }
  }

  .type {
    @include breakpoint(tablet) {
      margin-bottom: rem-calc(-4);
    }
    @include breakpoint(laptop) {
      margin-bottom: rem-calc(-10);
    }
  }
}

.search-item-glowday {
  background-color: $golden-medium;
  color: $white;

  &:hover {
    background-color: darken($golden-light, 10);
  }
}

.search-item-non-glowday {
  background-color: $ivory-medium;
  color: $white;

  &:hover {
    background-color: darken($ivory-medium, 10);
  }
}

.search-item-pending {
  background-color: $bronze-medium;
  color: $white;
  &:hover {
    background-color: darken($bronze-medium, $amount: 10);
  }
}
.search-item-other {
  background-color: $charcoal-medium;
  color: $charcoal-dark;

  &:hover {
    background-color: darken($charcoal-medium, 10);
  }
}
