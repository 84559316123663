.call-action {
  position: relative;
  z-index: 1;

  background: $light-gray;

  &__background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include breakpoint(laptop) {
      content: '';
      background: $light-gray;
      transform: skew(-26deg) translateX(-80%);
    }
  }

  @include breakpoint(laptop) {
    height: 25rem;
  }

  &__text {
    margin-top: 1rem;

    h2 {
      color: $charcoal-dark;
    }

    p {
      font-size: rem-calc(18);
      color: $charcoal-dark;

      @include breakpoint(laptop) {
        font-size: rem-calc(20);
      }
    }

    @include breakpoint(laptop) {
      bottom: 0;
      background-color: transparent;
      margin-top: 0;
      display: inline-block;
      align-self: center;
    }
  }

  &__background {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &__image {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: -2;
      height: 100%;
      object-fit: cover;
      right: 0;
    }

    img {
      width: 100%;
    }
  }
}

.charcoal-with-white {
  background-color: $charcoal-dark;

  h2 {
    color: $white;
  }

  p {
    color: $white;
  }

  .call-action__background {
    &:before {
      background-color: $charcoal-dark;
    }
  }
}
