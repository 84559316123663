.treatment-guide-article {
  display: flex;

  &__image {
    flex: 0 0 40%;

    img {
      border-radius: 13px;
      display: block;
    }

    @supports (aspect-ratio: 1/1) {
      img {
        aspect-ratio: 1 /1;
      }
    }

    @supports not (aspect-ratio: 1/1) {
      padding-top: 40%;
      height: 0px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__content {
    flex: 0 0 60%;
    align-self: center;

    span {
      color: $charcoal-highlight;
    }

    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
