.biography {
  article {
    margin-bottom: 0;
    overflow: hidden;

    &.clamp-lines {
      height: 6.4rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &.full-height {
      -webkit-line-clamp: unset;
      height: auto;
    }

    h2 {
      font-family: $global-font-family;
      font-weight: 600;
      font-size: rem-calc(22);
    }
  }
}
