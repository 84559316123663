.accordion--treatment {
  position: relative;
  transition: all 0.4s ease-in-out;
  outline: none;

  @include breakpoint(tablet) {
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &[open] {
    border-radius: 13px;

    .accordion__header {
      border-radius: 13px 13px 0 0;
    }
    .accordion__body {
      border-left: 1px solid $brand-charcoal-light;
      border-right: 1px solid $brand-charcoal-light;
      border-bottom: 1px solid $brand-charcoal-light;
      border-radius: 0 0 13px 13px;
    }
  }

  .accordion {
    &__header {
      padding: 1rem;
      position: relative;
      outline: $brand-charcoal-light;
      background-color: $brand-charcoal-light;
      border-radius: 13px;
      text-transform: uppercase;

      span {
        font-size: 65%;
        font-weight: 600;
        color: $white;
        width: 95%;

        &.text--normal {
          font-weight: 400;
        }

        @include breakpoint(tablet) {
          padding-left: 3rem;
        }

        &.accordion__warning {
          width: calc(95% - 2rem);
          margin-left: 0.5rem;
        }
      }

      &--no-padding {
        span {
          @include breakpoint(tablet) {
            padding-left: 0;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 1.25rem;
        width: 1rem;
        height: 1rem;
        right: 1rem;
        z-index: 1;
        background: url('/static/img/icons/white/arrow-down.svg') center / cover;
        transition: all 0.4s ease-in-out;
      }
    }

    &__body {
      border: none;

      &.open {
        border-left: 1px solid $brand-charcoal-light;
        border-right: 1px solid $brand-charcoal-light;
        border-bottom: 1px solid $brand-charcoal-light;
        border-radius: 0 0 13px 13px;
      }
    }

    &__footer {
      background-color: $brand-white;
      margin: 0;
      padding: 0;
      border-radius: 0 0 13px 13px;
    }
  }

  &.accordion__animation--open {
    .accordion__header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
