.thumbnail {
  background-color: $charcoal-pattern;
  width: 100%;

  @supports (aspect-ratio: 2/1) {
    aspect-ratio: 2 / 1;
  }

  @supports not (aspect-ratio: 2/1) {
    position: relative;
    padding-top: 50%;
    height: 0px;
  }

  &__image {
    display: block;
    width: 100%;

    @supports (aspect-ratio: 2/1) {
      aspect-ratio: 2 / 1;
    }

    @supports not (aspect-ratio: 2/1) {
      position: absolute;
      top: 0;
    }
  }

  &--background {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  $markupTemplates: muscles, woman-a, woman-b, full-body-mapping, hands,
    side-profile, man-mark-up-side, man-mark-up-face, lips-mark-up, feet;

  @each $i in $markupTemplates {
    &--#{$i} {
      background-image: url('/static/img/facial-markup/#{$i}--small.jpg');
    }
  }
}
