.location {
  padding: 0.75rem;
  border: 1px solid $brand-white;
  border-radius: 13px;

  &__wrapper {
    margin-bottom: 0.75rem;
    h4 {
      margin-bottom: 0.5rem;
    }
  }
  &__address {
    color: $charcoal-dark;
    font-style: normal;
  }
  &__accordion {
    padding: 1rem;
    border-radius: 13px;
    background-color: $brand-white;
    &--light {
      background-color: $charcoal-pattern;
    }
    &[open],
    &--open {
      background-color: $brand-gold;
      color: $white;
    }
    summary::after {
      top: -0.5rem;
      right: -0.5rem;
    }

    &--practitioners {
      padding: 0;
      border: 1px solid $brand-white;
      summary {
        border-radius: 13px;
        background-color: $brand-white;
        padding: 1rem;
        &::after {
          top: 0.5rem;
          right: 0.5rem;
        }
      }
      &[open],
      &--open {
        background-color: $white;
        color: $charcoal-dark;
        summary {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &--title {
      h4 {
        font-size: rem-calc(16);
      }
    }
  }

  &--active {
    border-color: $green;
    background-color: rgba($green, 0.1);
  }

  &--inactive {
    .location__address,
    .location__title {
      color: $brand-white-dark;
      background-color: rgba($brand-white, 0.1);
    }
  }
  &--warning {
    border-color: $rose-highlight;
  }
}
