#seo-search-results {
  min-height: rem-calc(600);
  #search-results-container {
    height: 35rem;
    overflow: auto;
    @include breakpoint(tablet) {
      height: 45rem;
    }
    @include breakpoint(laptop) {
      height: rem-calc(750);
    }
  }
}
