.modal__appointment {
  padding: 0;
}

.appointment {
  padding: 0;
  box-shadow: 0 15px 35px 0 rgba(244, 244, 244, 0.1),
    0 5px 15px 0 rgba(40, 39, 39, 0.05);
  border-radius: 13px;
  position: relative;
  font-size: rem-calc(14);
  max-width: 30rem;
  transition: all 0.4s;
  align-self: baseline;
  border: 1px solid $white;

  &.modal__dialog {
    max-width: 35.5rem;
    padding: 0;
    margin: 1rem auto;
  }

  &__treatment {
    &.modal__dialog {
      background-color: $brand-gold-dark;
    }

    button {
      &:focus {
        outline: 1px dotted $golden-light;
        outline-offset: rem-calc(4);
      }
    }
  }
  &__disclaimer {
    color: $white;
  }

  &__other {
    &.modal__dialog {
      background-color: $brand-charcoal-light;
    }

    button:focus {
      outline: 1px dotted $light-gray;
      outline-offset: rem-calc(4);
    }
  }

  &__header {
    display: flex;

    &--treatment {
      background-color: $brand-gold-dark;
      border-top-left-radius: 13px;

      button {
        background-color: $brand-gold-dark;

        &:disabled {
          cursor: not-allowed;
        }
      }

      &.active {
        border-top-right-radius: 13px;

        &::after {
          content: '';
          position: absolute;
          background: $brand-gold-dark;
          height: 100%;
          width: 1rem;
          top: 0;
          border-top-right-radius: 13px;
          z-index: 1;
          right: -0.75rem;
          transition: all 0.4s;
        }
      }

      &:not(.active):hover {
        background-color: darken($brand-gold-dark, 10);

        &::after {
          background-color: darken($brand-gold-dark, 10);
        }

        button {
          background-color: darken($brand-gold-dark, 10);
        }
      }

      &:hover .appointment--treatment {
        background-color: darken($brand-gold-dark, 10);
      }
    }

    &--other {
      background-color: $brand-charcoal-light;
      border-radius: 0 13px 0 0;

      button {
        background-color: $brand-charcoal-light;
      }

      &.active {
        border-top-right-radius: 13px;

        &::before {
          content: '';
          position: absolute;
          background: $brand-charcoal-light;
          height: 100%;
          width: 1rem;
          top: 0;
          border-top-left-radius: 13px;
          z-index: 1;
          left: -1rem;
          transition: all 0.4s;
        }
      }

      &:not(.active):hover {
        background-color: darken($brand-charcoal-light, 10);

        &::before {
          background-color: darken($brand-charcoal-light, 10);
        }

        button,
        .appointment--other {
          background-color: darken($brand-charcoal-light, 10);
        }
      }
    }

    &--item {
      flex: 1;
      padding: rem-calc(6);
      position: relative;
      transition: 0.4s all;

      button {
        padding: rem-calc(15 24);
        color: $white;
        border: none;
        width: 80%;
        font-weight: 600;
        text-align: left;
        transition: 0.4s all;
      }
    }
  }

  &__body {
    padding-left: rem-calc(24);
    padding-right: rem-calc(24);

    h5,
    h6 {
      color: $white;
      font-family: $global-font-family;
    }

    .selected-treatments-container {
      background-color: $white;
      border-radius: 13px;

      h6 {
        color: $charcoal-dark;
        font-size: rem-calc(14);
        margin-bottom: rem-calc(8);
      }

      .category-item {
        padding-bottom: rem-calc(12);

        &:last-child {
          .selected-treament-item:last-child {
            border-bottom: none;
          }
        }

        .selected-treament-item {
          align-items: center;
          padding-bottom: rem-calc(4);
          margin-bottom: rem-calc(4);
          border-bottom: 1px dotted $charcoal-medium;
          font-size: rem-calc(14);

          .treatment-name {
            padding-right: 0.25rem;
          }

          .treatment-remove-wrapper .button--utility {
            margin-left: auto;
          }
        }
      }
    }

    .add-additional-container {
      .selectable:disabled {
        background-color: rgba($white, 0.5);
      }
      font-size: rem-calc(16);
      margin-bottom: 1rem;
      input {
        height: 2rem;
        margin-bottom: 0.25rem;
      }
    }

    .field label,
    .field--label {
      color: $white;
      margin-bottom: 0.5rem;
      display: block;
    }

    .field--date {
      input,
      label {
        display: none;
      }
    }

    .datepicker {
      bottom: 0;
      border-radius: 13px;

      header {
        padding: 0.5rem 0.25rem;
      }
    }

    .datepicker__slots {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 0.5rem;

      @include breakpoint(tablet) {
        grid-template-columns: repeat(5, 1fr);
      }

      @include breakpoint(laptop) {
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 0.3rem;
      }
    }

    .field--inline {
      justify-content: flex-start;

      .radio {
        margin-left: 0;
        margin-right: 1.5rem;
      }
    }
  }

  .react-datepicker {
    width: 20.5rem;
    left: -2rem;

    @include breakpoint(laptop) {
      left: 0;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  &__fields {
    @include breakpoint(tablet) {
      /**
      * target the last div if its not also a first div
      */
      & > div:first-of-type:not(:first-of-type) {
        padding-right: 0.5rem;
      }

      & > div:last-of-type:not(:first-of-type) {
        padding-left: 0.5rem;
      }
    }
  }
}
