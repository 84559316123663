.field .client-notes {
  &__field {
    resize: none;
    min-height: 7rem;

    &--tall {
      min-height: 25rem;
    }
  }
}
