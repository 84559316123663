.navigation {
  margin: rem-calc(16 0 32);
  overflow: auto;
  padding: 0;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  li {
    list-style-type: none;
    display: inline-block;

    &.active {
      a {
        border-bottom: 1px solid $golden-dark;
        color: $golden-dark;
      }
    }

    a {
      display: block;
      font-size: rem-calc(12);
      letter-spacing: 0.4px;
      text-transform: uppercase;
      padding: rem-calc(4);
      color: $medium-gray;
      font-weight: bold;
      text-decoration: none;

      &:hover,
      &:focus {
        cursor: pointer;
        color: $golden-dark;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
