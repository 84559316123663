.hero {
  position: relative;
  z-index: 1;
  background-color: $charcoal-dark;

  @include breakpoint(tablet) {
    @supports (aspect-ratio: 3 /3) {
      aspect-ratio: 4/3;
      &__seo {
        aspect-ratio: 3/2;
      }
    }
    @supports not (aspect-ratio: 4/3) {
      height: 0;
      padding-bottom: 75%;
      &__seo {
        padding-bottom: 64%;
      }
    }
  }

  @include breakpoint(laptop) {
    height: inherit;
    padding-bottom: inherit;
    aspect-ratio: inherit;

    &__seo .hero__content h1 {
      font-size: rem-calc(36);
    }
  }

  h1,
  h2,
  p {
    color: $white;
    line-height: 0.9;
  }

  &__image {
    width: 100%;
    background-color: $white;
    max-width: 100vw;
    z-index: 1;

    @include breakpoint(tablet) {
      background-color: $charcoal-dark;
    }

    img {
      height: 100%;
      width: 100%;
      aspect-ratio: 32 / 25;
      display: block;

      @include breakpoint(tablet) {
        aspect-ratio: 12 / 5;
      }

      @include breakpoint(laptop) {
        aspect-ratio: 36 / 13;
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    max-height: calc(100% - 4px);
    flex-direction: column;
    justify-content: flex-end;
    h1 {
      font-size: clamp(26px, 8vw, 60px);
    }

    @include breakpoint(tablet) {
      h1 {
        font-size: rem-calc(46);
        line-height: 0.9;
      }
    }

    @include breakpoint(laptop) {
      top: 0;
      h1 {
        font-size: rem-calc(56);
      }
    }
  }
}

.mobile-hero {
  @supports not (height: clamp(380px, 25vh, 500px)) {
    height: 30vh;
    min-height: 24.25rem;
    max-height: 37.5rem;
  }
  @supports (height: clamp(380px, 25vh, 500px)) {
    height: clamp(380px, 25vh, 500px);
  }

  background-repeat: no-repeat;
  background-size: contain;
  .container--bottom {
    margin-bottom: 0;
    > .corner-padding {
      margin-bottom: 0;
      .column {
        background-color: $white;
        padding-top: 0.5rem;
      }
    }
  }
  @media screen and (min-width: rem-calc(481)) {
    @supports (height: clamp(380px, 41vh, 500px)) {
      height: clamp(380px, 41vh, 500px);
    }

    background-size: cover;
    .column h2 {
      margin-bottom: 0;
    }
  }
}
