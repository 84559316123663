.richTextEditor {
  .field--error &__text-area {
    border-color: #f62459;
  }
  &__text-area {
    overflow: auto;
    resize: both;
    border: 1px solid $charcoal-medium;
    border-radius: 13px;
    padding: 1rem 0.75rem;
    min-height: 10rem;
    &--capped {
      max-height: 15rem;
      resize: none;
    }
  }
  .__placeholder {
    :first-child {
      width: auto !important;
      white-space: normal !important;
    }
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    & > span[contenteditable='false'] {
      visibility: hidden !important;
    }
    &::before {
      position: absolute;
      left: 0;
      width: 100%;
      color: $charcoal-medium;
    }
    &:focus {
      &::before {
        content: none;
      }
    }
  }
  input,
  textarea {
    font-family: sans-serif;
    line-height: 1.4;
    background: $charcoal-pattern;
  }
  p {
    margin: 0;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  pre {
    padding: 10px;
    background-color: $charcoal-pattern;
    white-space: pre-wrap;
  }

  :not(pre) > code {
    font-family: monospace;
    background-color: $charcoal-pattern;
    padding: 3px;
  }

  img {
    max-width: 100%;
    max-height: 20em;
  }

  blockquote {
    border-left: 2px solid $charcoal-medium;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid $charcoal-medium;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    border: 2px solid $charcoal-medium;
  }

  input {
    box-sizing: border-box;
    font-size: 0.85em;
    width: 100%;
    padding: 0.5em;
    border: 2px solid $charcoal-medium;
    background: $white;
  }

  input:focus {
    outline: 0;
    border-color: blue;
  }

  [data-slate-editor] > * + * {
    margin-top: 1em;
  }
}

.preview {
  .text {
    &__bold {
      font-weight: bold;
    }
    &__italic {
      font-style: italic;
    }
    &__underline {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  blockquote {
    border-left: 2px solid $charcoal-medium;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
    p {
      color: #aaa;
    }
  }

  blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid $charcoal-medium;
  }
}
.clinic-form .practitioner-form .richTextEditor__text-area {
  min-height: rem-calc(900);
  position: relative;
  overflow: auto;

  .__placeholder::before {
    content: 'Your Bio is your opportunity to really highlight the incredible work you do, your qualifications and experience and your personality. I’m Claire, the owner of Belle Aesthetics, not far from Clifton. I graduated as a general nurse in 2003. I then spent 7 years working full time as an A&E nurse, becoming a nurse prescriber in 2008, with the view to becoming an aesthetic nurse. My aesthetics career began after attending a basic anti wrinkle and dermal filler course in 2008. I joined a local clinic, working part time around my NHS role. I took the plunge in 2011 and set up Belle Aesthetics and haven’t looked back! I have completed a range of advanced aesthetic qualifications since 2008. Most recently, I completed a Level 7 Certificate in Injectables with the Harley Academy.  I’m a firm believer in never stopping learning and continue to learn and perfect the art of aesthetics. I have built a loyal following of amazing clients. I only use the best equipment and industry-approved products. My aim is to inject confidence, enabling you to feel like the best version of yourself. Naturally and subtly enhanced.';
  }
  @include breakpoint(tablet) {
    min-height: rem-calc(600);
  }
  @include breakpoint(tablet) {
    min-height: rem-calc(360);
  }
}
.clinic-form .richTextEditor__text-area {
  min-height: rem-calc(400);
  position: relative;
  overflow: auto;
  .__placeholder::before {
    content: "A great description can make all the difference! This is your opportunity to showcase how amazing your clinic is. e.g. At Belle Aesthetics you can expect a warm welcome and an amazing treatment from our team of medically qualified practitioners, in a pristine clinical setting. With over 25 years of experience between us, you're in the hands of real experts who take pride in delivering confidence-boosting treatments.";
  }
  @include breakpoint(tablet) {
    min-height: rem-calc(200);
  }
}
