.clinic-form {
  .iconimage-uploader {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 1rem;
    width: 100%;
    margin-bottom: 2rem;
    flex-direction: column;
    text-align: center;
    @include breakpoint(tablet) {
      flex-direction: row;
      text-align: left;
    }
    &__icon {
      width: 12rem;
      margin: 1rem auto;
      height: 12rem;
      position: relative;
      img {
        width: 100%;
      }
      @include breakpoint(tablet) {
        max-width: 7rem;
        max-height: 7rem;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }
  p {
    margin: 0;
  }
  small {
    color: $charcoal-medium;
  }
}
