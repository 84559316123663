.before-after-upload {
  position: relative;

  &__info {
    text-align: left;
  }

  &__direction.field select {
    border-color: $bronze-highlight;
    background-color: $ivory-light;
    color: $bronze-highlight;
  }

  .image-uploader {
    width: 100%;
    height: 0px;
    position: relative;
  }

  .horizontal {
    margin-left: 0;
    .image-uploader {
      padding-top: 50%;

      &:first-child {
        border-bottom: 1px solid $white;
      }

      &:last-child {
        border-top: 1px solid $white;
      }
    }
  }

  .vertical {
    .image-uploader {
      padding-top: 200%;

      &:first-child {
        border-right: 1px solid $white;
      }

      &:last-child {
        border-left: 1px solid $white;
      }
    }
  }

  &__treatments {
    margin: 0.25rem 0;
    padding-bottom: 0.25rem;
    border-bottom: 1px dotted $charcoal-medium;

    &--name {
      font-size: rem-calc(12);
    }
  }

  .checkbox__faux {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.before-after-grid {
  > div:first-child,
  > div:nth-child(2) {
    border-bottom: 2px solid $light-gray;
    margin-bottom: 2rem;
  }
}
